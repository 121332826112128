import React from 'react';
import styles from "./Form.module.css";
import Card from "@material-ui/core/Card";
import HiddenInput from "../HiddenInput";
import MovableTable from './MovableTable';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineRounded from '@material-ui/icons/AddCircleOutlineRounded';
import ListItem from "./ListItem"
import { TextValidator } from 'react-material-ui-form-validator';
import {
    GRAY
   ,HIDE
} from "../../../redux/constants";
import {
    onChangeTable
   ,onAddRow
   ,onDeleteRow
   ,onDeleteAll
   ,onOrderChange
} from "../../../redux/actions/table";
import {
    setDocStatePropValue
} from '../../../redux/actions/doc';
import {
    gLang
} from '../../../redux/actions/lang';


const captions = {
    "ru": {
        add: "Добавить строку"
       ,emptyListWarning: "Добавьте список колонок в Админке"
    }
    ,"en": {
        add: "Add row"
       ,emptyListWarning: "Add columns list on Admin page"
    }
};


const Table = ( props ) => {

    if( !props.visible && props.visible !== undefined ) return;
    
    const lang = gLang;
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    const dispatch = props.dispatch;
    //const required = props.required === false ? false : true;
    const value = props.value;
    const name = props.name;
    const list = props.list ?? [];
    const size = Array.isArray( list ) ? list.length : 0;

    // one empty row with columns by default
    if( !Array.isArray( value )
     || !Array.isArray( value[0] ) ) {
        // new Array( size ) is empty and map() does not work,
        // Array.from( new Array( size ) ) or [ ...new Array( size ) ] is array of undefined values
        const emptyTable = [[ ...new Array( size ) ]];
        dispatch( setDocStatePropValue( name, emptyTable ) );
        return;
    }
    
    // number of columns had been changed, reallocate table
    if( Array.isArray( value )
     && Array.isArray( value[0] )
     && size !== value[0].length ) {
        const oldSize = value[0].length;
        const tail = ( size - oldSize ) > 0 ? ( size - oldSize ) : 0;
        const newTable = value.map( row =>
            [ ...row.slice( 0, oldSize < size ? oldSize : size ) // don't know deleted columns position, just size
            , ...new Array( tail ) ]
        );
        dispatch( setDocStatePropValue( name, newTable ) );
        return;
    }
    
    const isExternal = props.isExternal;
    const protect = props.protect;
    const hidden = isExternal && protect === HIDE;
    const readonly = props.readonly || ( isExternal && protect === GRAY );


    // protect для внешней формы
    if( hidden )
    return (
        <HiddenInput
            name = { name }
            value = { value }
        />
    );


    const DynForm = value.map( ( row, idx ) => ListItem( {
        item: row
       ,name: name + '$' + idx
       ,onChange: ( e, col ) => onChangeTable( e, dispatch, name, idx, col ) // для компонентов с event-ами
       ,onDelete: () => onDeleteRow( dispatch, name, idx )
       ,dispatch
       ,readonly
       ,validators: props.validators
       ,errorMessages: props.errorMessages
    } ) );


    const content = size === 0 ? <div style = {{ padding: '7px' }} >{ caption.emptyListWarning }</div> :
        <MovableTable
            name = { name }
            header = { list }
            items = { DynForm }
            value = { value }
            readonly = { readonly }
            onOrderChange = { readonly ? f=>f : ( items ) => onOrderChange( dispatch, name, items ) }
            onDeleteAll = { () => onDeleteAll( dispatch, name ) }
        />;

    const addButton = readonly ? <div style = {{ padding: '5px' }} ></div> :
        <IconButton
            color = "primary"
            onClick = { () => onAddRow( dispatch, name ) }
            style = {{ padding: '7px' }}
        >
            <Tooltip title = { caption.add } arrow placement = "bottom" >
                <AddCircleOutlineRounded
                    className={ styles.xxIcon }
                    fontSize="large"
                />
            </Tooltip>
        </IconButton>;

    // поле для контроля обязательности заполнения таблицы,
    // если только одна строка и она пустая - ругаемся
    // TODO можно было использовать <ValidatorMessage/>
    const validator =
        <TextValidator
            style = {{ margin: "auto 0 auto 3px" }}
            key  = { name + '$validator' }
            name = { name + '$validator' }
            value = { value.length === 1 ? value[0].join('') : 'ok' }
            size = "small"
            placeholder = ""
            inputProps = {{ style: { display: "none" } }}
            InputProps = {{ disableUnderline: true }}
            validators = { props.validators }
            errorMessages = { props.errorMessages }
        />;

        
    return (
        <Card className={ styles.xxComponentCard }>
            <div className={ styles.xxComponentCardLabel }>{ props.label }</div>
            <div className={ styles.xxComponentCardContent } style = {{ padding: '9px 3px 0px 3px' }} >
            
                { content }
            
            </div>
            <div style = {{ display: "flex", flexDirection: "row" }} >
            { addButton }
            { validator }
            </div>
        </Card>
    );
};

export default Table;
