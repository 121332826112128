import React from 'react';
import styles from "./Form.module.css"
import Button from './Button';
import Input from "./Input";
import Card from "@material-ui/core/Card";
import HiddenInput from "./HiddenInput";
import {
    GRAY
   ,HIDE
} from "../../redux/constants";

const captions = {
    "ru": {
        document: "Документ"
       ,docPdfNopass: "PDF без пароля"
       ,docRtfNopass: "Документ без пароля"
       ,docTrackerComment: "Комментарий для Трекера"
       ,docSaveInTracker: "Сохранить документ в существующий тикет"
       ,docHelperText1: "Введите номер тикета в формате QUEUE-1234 и нажмите кнопку PDF или Документ. Документ и комментарий будут добавлены в указанный тикет. "
                      + "Тикет должен принадлежать очереди "
       ,docHelperText2: "Документ может быть защищён от редактирования паролем. "
       ,docHelperText3: 'Если нужны документы без пароля, нажмите кнопку "PDF без пароля" или "Документ без пароля".'
    }
    ,"en": {
        document: "Document"
       ,docPdfNopass: "PDF without password"
       ,docRtfNopass: "Document without password"
       ,docTrackerComment: "Comment for Tracker"
       ,docSaveInTracker: "Save document in existing ticket"
       ,docHelperText1: "Enter the ticket number like QUEUE-1234 and click PDF or Document button. The document and comment will be added to the specified ticket. "
                      + "Ticket must belong to the queue "
       ,docHelperText2: "The document may be protected from editing with a password. "
       ,docHelperText3: 'If you need documents without a password, click "PDF without password" or "Document without password" button.'
    }
};

const StartrekAttachDoc = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;

    // protect для внешней формы
    const hidden = props.isExternal && props.protect === HIDE;
    if( hidden ) return;
    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    
    // в условиях учтены значения по умолчанию - true для кнопок с паролем, false для дополнительных, без пароля
    const isNopass = props.isDocPdfNopass === true || props.isDocRtfNopass;

    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    let helperText = caption.docHelperText1 + props.startrekQueueAttach + ". ";

    // TODO наличие пароля можно проверить по флагу isProtected в SaveAndTicket
    helperText += caption.docHelperText2
    helperText += isNopass ? caption.docHelperText3 : '';

    const startrekQueueAttach =
        <HiddenInput
            name = "startrekQueueAttach"
            value = { props.startrekQueueAttach }
        />;

    const docPdf = props.isDocPdf === false ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onStartrekAttachDoc( 'pdf' ) }
            label = "PDF"
        />;
        
    const docRtf = props.isDocRtf === false ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onStartrekAttachDoc( 'rtf' ) }
            label = { caption.document }
        />;

    const docPdfNopass = props.isDocPdfNopass !== true ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onStartrekAttachDoc( 'pdf', true ) }
            label = { caption.docPdfNopass }
        />;
        
    const docRtfNopass = props.isDocRtfNopass !== true ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onStartrekAttachDoc( 'rtf', true ) }
            label = { caption.docRtfNopass }
        />;
        
    return (
        <Card className={ styles.xxComponentCard }>
        <div className={ styles.xxComponentCardLabel }>{ caption.docSaveInTracker }</div>
        <div className={ styles.xxComponentCardContent } style={{ paddingBottom:"20px" }} >

        { startrekQueueAttach }

        <div className='row'>
            <Input
                className={ styles.xxTextField }
                label={ props.label }
                name={ props.name }
                value={ props.value === undefined || props.value === null ? '' : props.value }
                onChange={ props.onChange }
                validators={ props.validators }
                errorMessages={ props.errorMessages }
                autoFocus={ props.autoFocus }
                helperText={ helperText }
                error={ props.error }
                readonly={ readonly }
            />
        </div>
        
        <div>
            <Input
                className={ styles.xxTextField }
                label = { caption.docTrackerComment }
                name="startrekComment"
                value={ props.startrekComment === undefined || props.startrekComment === null ? '' : props.startrekComment }
                onChange={ props.onChange }
                validators={ props.validators }
                errorMessages={ props.errorMessages }
                autoFocus={ props.autoFocus }
                error={ props.error }
                multiline
                variant="outlined"
                readonly={ readonly }
            />
        </div>

        <div className='table__row'>
            { docPdf }
            { docRtf }
        </div>
        
        <div className='table__row'>
            { docPdfNopass }
            { docRtfNopass }
        </div>
        
        </div>
        </Card>
    );
};

export default StartrekAttachDoc;
