import {
   // properties
    APP_URL
   // actions
   ,ADD
   ,DELETE
   ,EDIT
   ,SET_ID
   ,SET_PROPS
   // reducer names
   ,GROUP
   ,GROUP_EDITOR
} from '../constants'
import {
    post_common
} from '../actions/utils'
import {
    caption
} from './lang';


export const close = () => (
    {
        type: GROUP_EDITOR + SET_PROPS,
        payload: {
            value: { open: false }
        }
    }
);

export const addGroup = ( name ) => (
  {
    type: GROUP + ADD,
    payload: {
        name
    }
  }
);

export const editGroup = ( id, name ) => (
  {
    type: GROUP + EDIT,
    payload: {
        id
       ,name
    }
  }
);

export const deleteGroup = () => (
  {
    type: GROUP + DELETE
  }
);

export const setGroupId = ( id ) => (
  {
    type: GROUP + SET_ID,
    payload: {
        id
    }
  }
);

export function addGroupDB( dispatch, name ) {

    const formData = new FormData();
    formData.append('name', name);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/group/add'
       ,formData
       ,config
       ,dispatch
       ,caption.addGroupError + ': '
       ,data => {
            const groupId = data;
            if( !Number.isInteger( groupId ) ) return;
            dispatch( setGroupId( groupId ) ); // заменить временный id на постоянный из базы
        }
    );
}

export function deleteGroupDB( dispatch, groupId ) {

    if( !Number.isInteger( groupId ) ) return;
    if( groupId < 1 ) { dispatch( deleteGroup() ); return; }
    const formData = new FormData();
    formData.append('id', groupId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/group/delete'
       ,formData
       ,config
       ,dispatch
       ,caption.deleteGroupError + ': '
       ,data => {
            if( data !== 'OK' ) return;
            dispatch( deleteGroup() );
        }
    );
    
}

export function editGroupDB( dispatch, groupId, name ) {

    if( !Number.isInteger( groupId ) || groupId < 1 ) return;
    const formData = new FormData();
    formData.append('id', groupId);
    formData.append('name', name);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/group/edit'
       ,formData
       ,config
       ,dispatch
       ,caption.saveGroupError + ': '
       ,data => {}
    );
}

