import React from 'react'
import './DocForm.css'
import styles from "./Form.module.css"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DocFormContent from "./DocFormContent.jsx"

const DocForm = ( props ) => {
 
    return (
        <div className='DocForm'>

        <Card className={ styles.xxGridActionPanel }>
        <CardContent className='Card'>
        
        <DocFormContent {...props} />
            
        </CardContent>
        </Card>
        </div>
    );
}

export default DocForm
