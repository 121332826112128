import React from 'react';
import './LayoutMain.css'

// BUG movable не скроллится внутри контейнера, скроллится только window,
// поэтому чтобы в Админке работал скролл при перемещении карточки,
// пришлось сделать Layout с вертикальным скроллом для window на всю высоту экрана,
// версия со скроллом от шапки и вниз лежит в файлах LayoutMain2

function LayoutMain( { props } ) {

    const header = !props.header ? '' :
        <div className="header">
            { props.header }
        </div>;

    const main = !props.main ? '' :
        <div className="main">
            { props.main }
        </div>;
        
    const footer = !props.footer ? '' :
        <div className="footer">
            { props.footer }
        </div>;
        
    return (
        <div className="LayoutMain">
            { header }
            { main }
            { footer }
        </div>
    );
}

export default LayoutMain;
