import { 
    SET_IS_LOADING
} from './../constants'

const initialState = {
    isLoading: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
     
        case SET_IS_LOADING:
            return {
                isLoading: action.payload ? state.isLoading + 1 : state.isLoading ? state.isLoading - 1 : 0
            }
            
        default:
            return state
    }
}
