import {
    VENDOR_DATA
   ,SET_STATE_PROP_VALUE
} from './../constants'

const initialState = {
}

export default ( state = initialState, action ) => {
 
    switch (action.type) {

        case VENDOR_DATA + SET_STATE_PROP_VALUE:
            const name = action.payload.name
            const value = action.payload.value
            return {
                ...state.vendorStates,
                [name]: value
            }
            
        default:
            return state.vendorStates
    }
}
