import {
    DOC
   ,SET_STATE_PROP_VALUE
   ,SET_STATE_PROP_MAP
} from './../constants'

const initialState = {
}

export default ( state = initialState, action ) => {
 
    switch (action.type) {

        case DOC + SET_STATE_PROP_VALUE:
            const name = action.payload.name
            const value = action.payload.value
            return {
                ...state,
                [name]: value
            }
            
        case DOC + SET_STATE_PROP_MAP:
            const map = action.payload.map
            return {
                ...state,
                ...map
            }
            
        default:
            return state
    }
}
