import { connect } from 'react-redux'
import React, { Component } from 'react'
import { arrayMove } from 'react-movable';
import { default as VListEditor } from '../components/AdminParts/ListEditor'
import {
    FORM_TEMPLATE
   ,LIST_EDITOR
   ,MODAL
} from '../redux/constants'
import {
    close
   ,addLi
   ,addLiAll
   ,deleteLi
   ,deleteLiAll
} from '../redux/actions/listEditor'
import {
    setProps
   ,setPropValue
   ,setKeyPropValue
} from '../redux/actions/utils'
import { setBankDetails } from "../redux/actions/bankListsEditor";
import {
    caption
   ,isLangEn
} from '../redux/actions/lang';

class ListEditor extends Component {
 
    render() {
        return (
            <VListEditor
                { ...this.props }
            />
        )
    }
}


const mapStateToProps = ( { listEditor, doc } ) => {
    return {
        ...listEditor
       ,docTemplate: doc.docTemplates[ doc.docId ]
       ,yaOrgs: doc.yaOrgs
    };
}


const mapDispatchToProps = dispatch => ({
    onChangeLi: ( e, lang ) => {
        let name = "list";
        if( lang !== undefined ) name += '_' + lang;
        
        const target = e.target;
        const key = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        dispatch( setKeyPropValue( LIST_EDITOR, key, name, value ) );
    }
   ,onAddLi: () => {
        let name = "list";
        dispatch( addLi( name ) );
        
        if( isLangEn ) {
            name += "_en";
            dispatch( addLi( name ) );
        }
    }
   ,onAddLiAll: () => {
        dispatch( setProps( MODAL, {
            msg: caption.listAddAll // Добавить все возможные значения?
           ,onOk: () => dispatch( addLiAll() )
        } ) )
    }
   ,onDeleteLi: ( key ) => {
        /*if( value !== '' ) {
            dispatch( setProps( MODAL, {
                msg: caption.listDelete // Удалить значение?
               ,onOk: () => dispatch( deleteLi( key ) )
            } ) )
        } else {
            dispatch( deleteLi( key ) )
        }*/
        
        let name = "list";
        dispatch( deleteLi( name, key ) );
        
        if( isLangEn ) {
            name += "_en";
            dispatch( deleteLi( name, key ) );
        }
    }
   ,onDeleteLiAll: () => {
        dispatch( setProps( MODAL, {
            msg: caption.listDeleteAll // Удалить все значения?
           ,onOk: () => {
                let name = "list";
                dispatch( deleteLiAll( name ) );
                
                if( isLangEn ) {
                    name += "_en";
                    dispatch( deleteLiAll( name ) );
                }
            }
        } ) )
    }
   ,onChangeOrder: ( props, items, oldIndex, newIndex ) => {
        let name = 'list';
        dispatch( setPropValue( LIST_EDITOR, name, items ) );
        
        if( isLangEn ) {
            name += "_en";
            const list = props[ name ];
            if( !Array.isArray( list ) ) return;
            items = arrayMove( list, oldIndex, newIndex );
            dispatch( setPropValue( LIST_EDITOR, name, items ) );
        }
    }
   ,onOk: ( key, props ) => {
    
        // lang - добавляем перевод
        let name = 'list';
        /* OLD VERSION
        const lang = gLang ?? "ru";
        if( lang !== 'ru' ) name += '_' + lang; */
        
        // !optionsList - списки в сложных компонентах пока не переводим
        if( isLangEn && !Array.isArray( props.optionsList ) ) {
            const nameTrans = name + "_en";
            dispatch( setKeyPropValue( FORM_TEMPLATE, key, nameTrans, props[ nameTrans ] ) );
        }
        
        dispatch( setKeyPropValue( FORM_TEMPLATE, key, name, props[ name ] ) );
    }
   ,onCancel: () => dispatch( close() )
   ,onBankDetailsList: ( itemKey, key, item, yaOrgs, docTemplate, suffix ) => {
        setBankDetails( dispatch, key, item, docTemplate, itemKey );
    }
})

export default connect( mapStateToProps, mapDispatchToProps )( ListEditor )
