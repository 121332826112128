import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Spin = ( props ) => {
    return (
        props.isLoading ? <CircularProgress size={ 25 } style={{ position: "fixed", top: 12, right: 80, zIndex: 1300 }} /> : ''
    );
};

export default Spin;
