import { combineReducers } from 'redux';

import bankListsEditor from "./bankListsEditor";
import cdocProperties from './cdocProperties';
import doc from './doc';
import editDialog from './editDialog';
import extEditor from './extEditor';
import history from './history';
import itemEditor from './itemEditor';
import listEditor from './listEditor';
import modal from './modal';
import navBar from './navBar';
import role from './role';
import session from "./session";
import spin from './spin';
import user from './user';
import uploads from './uploads';

export default combineReducers({
    bankListsEditor
   ,cdocProperties
   ,doc
   ,editDialog
   ,extEditor
   ,history
   ,itemEditor
   ,listEditor
   ,modal
   ,navBar
   ,role
   ,session
   ,spin
   ,user
   ,uploads
});
