import React from 'react'
import styles from "./Form.module.css"
import classNames from 'classnames';
import { ValidatorComponent } from 'react-material-ui-form-validator';

class ValidatorMessage extends ValidatorComponent {
    render() {
        const { isValid } = this.state;
        if( isValid ) return '';
        return (
            <div className={ classNames( styles.xxHelperText, styles.error ) }>
                { this.getErrorMessage() }
            </div>
        );
    }
}

export default ValidatorMessage;
