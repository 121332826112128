import {
// actions
    SET_PROPS
   ,SET_PROP_VALUE
// reducer names
   ,EDIT_DIALOG
} from './../constants';

// диалоговое окно редактора одного поля

const initialState = {
    open: false
};


export default( state = initialState, action ) => {
    switch( action.type ) {

        case EDIT_DIALOG + SET_PROPS:
            return action.payload.value;
            
        case EDIT_DIALOG + SET_PROP_VALUE:
            return {
                ...state
               ,[ action.payload.name ]: action.payload.value
            };
            
        default:
            return state;
    }
};
