import React from 'react';

const User = ({ user }) => {
    const login = user.login ? user.login.toLowerCase() : '';

    if( !login || user.isExternal ) return '';
    
    return (
        <a
            href={ "https://staff.yandex-team.ru/" + login }
            target="_blank"
            rel="noreferrer noopener">
            
            <img
                width={49}
                height={49}
                alt=''
                src={ "https://center.yandex-team.ru/api/v1/user/" + login + "/avatar/100.jpg" } />
        </a>
    );
};

export default User;
