import React from 'react'
import styles from "./Form.module.css"
import Card from "@material-ui/core/Card";
import HiddenInput from "./HiddenInput";
import InputWithSelect from "./InputWithSelect";
import Multiline from "./Multiline";
import Text from "./Text";
import { DateInput, onChangeDate } from "./DateInput";
import {
    emptyRecord
   ,getHrPersonsDB
   ,getHrOnePersonDB
   ,setHrPersonsFields
   ,captions
} from '../../redux/actions/hrPersons';
import {
    GRAY
   ,HIDE
} from "../../redux/constants";


// особый случай, subpoena_receipt_date "Дата получения повестки" - это select, а не date,
// добавим части _dd, _mm, _yyyy
function subpoenaDateParts( name, value ) {
    const [ dd, mm, yyyy ] = value ? value.split(".") : [ "", "", "" ];
    return <>
        <HiddenInput
            name = { name + "_dd" }
            value = { dd }
        />
        <HiddenInput
            name = { name + "_mm" }
            value = { mm }
        />
        <HiddenInput
            name = { name + "_yyyy" }
            value = { yyyy }
        />
    </>;
}


function makeSelect( name, props, selectedState ) {
 
    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    // protect для внешней формы
    const protect = props.protect;
    const isExternal = props.isExternal;
    const dispatch = props.dispatch;

    const suffix = props.suffix === undefined || props.suffix === null ? '' : props.suffix;
    const listSuffix = "_list";
    
    const tag = name + suffix;
    const list = props.state[ name + listSuffix + suffix ];
    
    if( Array.isArray( list ) && list.length > 1 )
    return <InputWithSelect
        className = { props.className }
        key = { tag }
        name = { tag }
        label = { caption[ name ] }
        value = { props.state[ tag ] }
        validators = { props.validators }
        errorMessages = { props.errorMessages }
        list = { list }
        onNameValueChange = { props.onNameValueChange }
        onInputChange = { props.onInputChange } // undefined, не используется
        onSelectChange = {
            value => setHrPersonsFields(
                dispatch
               ,selectedState( value )
               ,suffix
            )
        }
        protect = { protect }
        isExternal = { isExternal }
    />;
    
    return undefined;
}


const HrPersons = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;

    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    // protect для внешней формы
    const hidden = props.isExternal && props.protect === HIDE;
    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const protect = props.protect;
    const isExternal = props.isExternal;
    const dispatch = props.dispatch;

    const suffix = props.suffix === undefined || props.suffix === null ? '' : props.suffix;
    const listSuffix = "_list";

    const specialFields = {};
    
    // Выпадающий список с полем ввода для Логина
    let tag = 'hr_login' + suffix;
    let list = props.selectList;
    specialFields.hr_login =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            name = { tag }
            label = { caption.hr_login }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            list = { list }
            onNameValueChange = { props.onNameValueChange }
            onInputChange = { value => getHrPersonsDB( dispatch, value ) } // чтение справочника из базы
            onSelectChange = {
                value => {
                 
                    setHrPersonsFields(
                        dispatch
                       ,{
                            [ 'hr_login' + suffix ]: value
                        }
                       ,suffix
                    );
                    
                    if( !value ) return;
                    getHrOnePersonDB( dispatch, value, props.limitToBusinessGroups, suffix ); // чтение всех данных по одному лицу
                }
            } // зависимые поля заполняются значениями из справочника
            // readonly = { readonly } // сюда не передаём, логин можно выбирать
            protect = { protect }
            isExternal = { isExternal }
        />;

        
    // Выпадающий список с полем ввода для Бизнес-группы
    specialFields.hr_business_group_name =
        makeSelect( 'hr_business_group_name', props, value => ({
            [ 'hr_login' + suffix ]: props.state[ 'hr_login' + suffix ]
           ,[ 'hr_business_group_name' + suffix ]: value
        }));

        
    // Выпадающий список с полем ввода для Номера назначения
    specialFields.hr_assignment_number =
        makeSelect( 'hr_assignment_number', props, value => ({
            ...props.state
           ,[ 'hr_assignment_number' + suffix ]: value
        }));
        

    specialFields.hr_loan_number =
        makeSelect( 'hr_loan_number', props, value => ({
            ...props.state
           ,[ 'hr_loan_number' + suffix ]: value
        }));
        

    tag = 'subpoena_receipt_date' + suffix;
    list = props.state[ 'subpoena_receipt_date' + listSuffix + suffix ];
    // поскольку поле Дата заменяется на список, части дат нужно добавить отдельно
    let dateParts = '';
    if( Array.isArray( list ) && list.length > 1 ) {
        dateParts = subpoenaDateParts( tag, props.state[ tag ] );
        specialFields.subpoena_receipt_date =
            <InputWithSelect
                className = { props.className }
                key = { tag }
                name = { tag }
                label = { caption.subpoena_receipt_date }
                value = { props.state[ tag ] }
                validators = { props.validators }
                errorMessages = { props.errorMessages }
                list = { list }
                onNameValueChange = { ( name, value ) => ( value ) => onChangeDate( tag, props.onNameValueChange ) } // было props.onNameValueChange
                onInputChange = { props.onInputChange }
                onSelectChange = {
                    value => setHrPersonsFields(
                        dispatch
                       ,{
                            ...props.state
                           ,[ 'subpoena_receipt_date' + suffix ]: value
                        }
                       ,suffix
                    )
                }
                protect = { protect }
                isExternal = { isExternal }
            />;
    }
    
/*
    specialFields.hr_contact_type =
        makeSelect( 'hr_contact_type', props, value => ({
            ...props.state
           ,[ 'hr_contact_type' + suffix ]: value
           ,[ 'hr_contact_full_name' + suffix ]: undefined
        }));
*/

    specialFields.hr_contact_full_name =
        makeSelect( 'hr_contact_full_name', props, value => ({
            ...props.state
           ,[ 'hr_contact_type' + suffix ]: undefined
           ,[ 'hr_contact_full_name' + suffix ]: value
        }));


    specialFields.hr_account_bank_name =
        makeSelect( 'hr_account_bank_name', props, value => ({
            ...props.state
           ,[ 'hr_account_bank_name' + suffix ]: value
           ,[ 'hr_account_bic'  + suffix ]: undefined
           ,[ 'hr_account_corr' + suffix ]: undefined
           ,[ 'hr_account_bank' + suffix ]: undefined
           ,[ 'hr_account_pers' + suffix ]: undefined
        }));

/*
    specialFields.hr_account_bic =
        makeSelect( 'hr_account_bic', props, value => ({
            ...props.state
           ,[ 'hr_account_bank_name' + suffix ]: undefined
           ,[ 'hr_account_bic'  + suffix ]: value
           ,[ 'hr_account_corr' + suffix ]: undefined
           ,[ 'hr_account_bank' + suffix ]: undefined
           ,[ 'hr_account_pers' + suffix ]: undefined
        }));

    specialFields.hr_account_corr =
        makeSelect( 'hr_account_corr', props, value => ({
            ...props.state
           ,[ 'hr_account_bank_name' + suffix ]: undefined
           ,[ 'hr_account_bic'  + suffix ]: undefined
           ,[ 'hr_account_corr' + suffix ]: value
           ,[ 'hr_account_bank' + suffix ]: undefined
           ,[ 'hr_account_pers' + suffix ]: undefined
        }));
        
        
    specialFields.hr_account_bank =
        makeSelect( 'hr_account_bank', props, value => ({
            ...props.state
           ,[ 'hr_account_bank_name' + suffix ]: undefined
           ,[ 'hr_account_bic'  + suffix ]: undefined
           ,[ 'hr_account_corr' + suffix ]: undefined
           ,[ 'hr_account_bank' + suffix ]: value
           ,[ 'hr_account_pers' + suffix ]: undefined
        }));
        
        
    specialFields.hr_account_pers =
        makeSelect( 'hr_account_pers', props, value => ({
            ...props.state
           ,[ 'hr_account_bank_name' + suffix ]: undefined
           ,[ 'hr_account_bic'  + suffix ]: undefined
           ,[ 'hr_account_corr' + suffix ]: undefined
           ,[ 'hr_account_bank' + suffix ]: undefined
           ,[ 'hr_account_pers' + suffix ]: value
        }));
*/

    specialFields.hr_qualification_name =
        makeSelect( 'hr_qualification_name', props, value => ({
            ...props.state
           ,[ 'hr_qualification_name' + suffix ]: value
           ,[ 'hr_qualification_establishment' + suffix ]: undefined
           ,[ 'hr_qualification_doc' + suffix ]: undefined
        }));
        

    specialFields.hr_qualification_establishment =
        makeSelect( 'hr_qualification_establishment', props, value => ({
            ...props.state
           ,[ 'hr_qualification_name' + suffix ]: undefined
           ,[ 'hr_qualification_establishment' + suffix ]: value
           ,[ 'hr_qualification_doc' + suffix ]: undefined
        }));
        

    specialFields.hr_qualification_doc =
        makeSelect( 'hr_qualification_doc', props, value => ({
            ...props.state
           ,[ 'hr_qualification_name' + suffix ]: undefined
           ,[ 'hr_qualification_establishment' + suffix ]: undefined
           ,[ 'hr_qualification_doc' + suffix ]: value
        }));
        
/*
    specialFields.hr_military_doc =
        makeSelect( 'hr_military_doc', props, value => ({
            ...props.state
           ,[ 'hr_military_doc' + suffix ]: value
        }));
*/
        
    const fieldsList = Array.isArray( props.fieldsList ) ? props.fieldsList : [];

    const content = Object.keys( emptyRecord ).map( id =>
        {
            const tag = id + suffix;
            let item = undefined;
            
            // поля с особым поведением
            item = specialFields[ id ];
            
            if( item !== undefined )
                if( fieldsList.includes( id ) )
                    return item;
                else
                    return <HiddenInput key = { tag } name = { tag } value = { props.state[ tag ] } />;
            
            // остальные поля
            if( fieldsList.includes( id ) )
                return id.includes( "date" ) && ( id !== 'subpoena_appearence_date_time' ) ? // subpoena_appearence_date_time содержит дату и время, "13.10.2022 19:00:00"
                <DateInput
                    key = { tag }
                    className = { props.className }
                    label = { caption[ id ] }
                    name = { tag }
                    value = { props.state[ tag ] }
                    value_dd = { props.state[ tag + "_dd" ] }
                    value_mm = { props.state[ tag + "_mm" ] }
                    value_yyyy = { props.state[ tag + "_yyyy" ] }
                    onChange = { onChangeDate( tag, props.onNameValueChange ) }
                    validators = { props.validators }
                    errorMessages = { props.errorMessages }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                    isDefaultDate = { false } // не устанавливать текущую дату по умолчанию
                />
                :
                id === 'hr_tk_status_name' ?
                <Text
                    key = { tag }
                    className = { props.className }
                    name = { tag }
                    label = { caption[ id ] + ": " + ( props.state[ tag ] ?? '' ) }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                />
                :
                <Multiline
                    key = { tag }
                    className = { props.className }
                    label = { caption[ id ] }
                    name = { tag }
                    value = { props.state[ tag ] }
                    onChange = { props.onChange }
                    validators = { props.validators }
                    errorMessages = { props.errorMessages }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                />;
            else
                // невидимые поля должны попасть в form submit
                return <HiddenInput key = { tag } name = { tag } value = { props.state[ tag ] } />;
        }
    );
    
    if( hidden ) return content;
    
    
    return (
        <Card className={ styles.xxComponentCard }>
        <div className={ styles.xxComponentCardLabel }>{ props.label }</div>
        <div className={ styles.xxComponentCardContent }>
            { content }
            { dateParts }
        </div>
        </Card>
    );
}

export default HrPersons
