import React from 'react'
import styles from "./Form.module.css"

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddRounded from '@material-ui/icons/AddRounded';
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';
import ClearRounded from '@material-ui/icons/ClearRounded';
import EditRounded from '@material-ui/icons/EditRounded';
import ListRounded from '@material-ui/icons/ListRounded';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ShareRounded from '@material-ui/icons/ShareRounded';
import {
    yandexOrgsByBgList
} from '../../redux/actions/yandex';
import {
    getDocItemListByOptionsList
} from '../../redux/actions/optionsList';
import {
    isLangEn
   ,caption
} from '../../redux/actions/lang';
import {
    transition
} from './Transition';

// редактор атрибутов раздела admin из DocParts.js


function modifyItem( item, props ) {
 
    // props.item - компонент
    // item - поле компонента
    const component = props.item;
    const globalLists = props.globalLists;
 
    // костылим список defaultValue в компоненте Yandex
    if( component.type === "Yandex" && item.name === "defaultValue" ) {
        if( component.optionsList === "yaBGs" ) item.list = yandexOrgsByBgList( component.list, globalLists );
        else item.list = getDocItemListByOptionsList( globalLists.yaOrgs, component.list, "ya_name", "ya_name" );
    }
    else if( item.useListFromTemplate ) item.list = component.list;

    // lang - добавляем перевод
    // для списка "Значение по умолчанию",
    /* lang old version
    if( lang !== 'ru' && item.useListFromTemplate ) {
        const trans = component[ "list_" + lang ];
        if( trans !== undefined ) newItem.list = trans;
    }*/
    
    // Список полей составного компонента.
    // Если нужно несколько компонентов в шаблоне, теги полей должны отличаться на suffix.
    if( item.name === "fieldsList" ) item.suffix = component.suffix;
}

// build - отрисовать поля Админки,
// группировать поля с признаком isRowGroup в одну строку
function buildDynForm( template, docParts, props ) {

    const res = [];
    let idx = 0;

    template.forEach( ( item, templateIdx ) => {
     
        if( !docParts[ item.type ] ) return;

        const newItem = { ...item };

        modifyItem( newItem, props );
        
        const formField = docParts[ newItem.type ].build( // параметры build( props, state, extra, styles )
            props
           ,props.item
           ,newItem
           ,styles
        );

        // Подсказка для поля
        const tooltip = '';

        // React требует keys.
        // Скрытые поля не оборачиваем в div.
        
        const formKeyField = formField ? <div key={ templateIdx } style={{ width: "100%" }} >{ tooltip }{ formField }</div> : '';
        
        const prevItem = templateIdx > 0 ? template[ templateIdx - 1 ] : null;
        if( prevItem && prevItem.isRowGroup && idx > 0 ) res[ idx - 1 ].push( formKeyField );
        else { res.push( [ formKeyField ] ); ++idx; }

    });

    return res;
}


const Item = ( props ) => {

    //const lang = props.lang ?? "ru";
    
    let DynForm = null;
    if( props.docParts && props.docPart && props.docPart.admin && props.item ) {
     
        const admin = [];
        
        // lang - добавляем перевод для "Название поля" и "Комментарий для Трекера" (компонента "Сохранить документ в существующий тикет") в Админке,
        // если перевод не задан - в Админке будет пустое значение,
        // а на форме Документ - значение для ru
        props.docPart.admin.forEach( i => {
            
            // добавить поле с переводом
            if( isLangEn && [ "label", "startrekCommentTemplate" ].includes( i.name ) ) {
                let item = { ...i };
                item.name += '_en';
                item.label += ' [En]';
                admin.push( { ...i, isRowGroup: true } );
                admin.push( item );
            } else admin.push( i );
            
        });
        /* lang OLD VERSION
        props.docPart.admin.forEach( i => {
            let item = i;
            
            if( lang !== 'ru' && [ "label", "defaultValue", "startrekCommentTemplate" ].includes( i.name ) ) {
                item = { ...i };
                item.name += '_' + lang;
            }
            
            admin.push( item );
        });*/
        
        DynForm = buildDynForm( admin, props.docParts, props );
    }
    
    // вставить скопированные поля
    const buttonInsert = props.copyLength ?
        <Tooltip title={ caption.paste } arrow>
            <IconButton
                color="primary"
                style={{ position: "absolute", top: -35, left: -50 }}
                onClick={ props.onInsert }
            >
                <ArrowRightAltRounded fontSize="large" className={ styles.xxAdd } />
            </IconButton>
        </Tooltip> : '';
        
    const buttonAddField =
        <Tooltip title={ caption.addFieldAbove } arrow>
            <IconButton
                color="primary"
                style={{ position: "absolute", top: -10, left: -10 }}
                onClick={ props.onAddField }
            >
                <AddRounded fontSize="large" className={ styles.xxAdd } />
            </IconButton>
        </Tooltip>;
        
    const buttonCopyField =
        <Tooltip title={ caption.copyField } arrow>
            <IconButton
                color="primary"
                style={{ position: "absolute", top: -10, left: 55 }}
                onClick={ e => transition( e, props.onCopyField ) }
            >
                <PlaylistAddIcon fontSize="large" className={ styles.xxAdd } />
            </IconButton>
        </Tooltip>;
        
    const buttonEditExt = ( !props.isExtShareable || !props.docPart || !props.docPart.ext ) ? '' :
        <Tooltip title={ caption.extVisibility } arrow>
        <IconButton
            color="primary"
            style={{ position: "absolute", top: -6, right: 170 }}
            onClick={ props.onEditExt }
        >
            <ShareRounded style={{ fontSize:"1.6rem" }} className={ styles.xxAdd } />
        </IconButton>
        </Tooltip>;
    
    const buttonEditList = ( !props.docPart || !props.docPart.isList ) ? '' :
        <Tooltip title={ caption.editList } arrow>
        <IconButton
            color="primary"
            style={{ position: "absolute", top: -11, right: 105 }}
            onClick={ props.onEditList }
        >
            <ListRounded fontSize="large" className={ styles.xxAdd } />
        </IconButton>
        </Tooltip>;
        
    const buttonEditField = ( !props.docPart || !props.docPart.edit ) ? '' :
        <Tooltip title={ caption.editField } arrow>
        <IconButton
            color="primary"
            style={{ position: "absolute", top: -5, right: 50 }}
            onClick={ props.onEditItem }
        >
            <EditRounded fontSize="default" className={ styles.xxAdd } />
        </IconButton>
        </Tooltip>;
        
    const buttonDeleteField =
        <Tooltip title={ caption.deleteField } arrow>
            <IconButton
                color="primary"
                style={{ position: "absolute", top: -10, right: -10 }}
                onClick={ props.onDeleteField }
            >
                <ClearRounded fontSize="large" className={ styles.xxDelete } />
            </IconButton>
        </Tooltip>;
    
    const renderItem = (item, key) => <div key={key} className='table__row' >{ item }</div>;
    
    return (
        <>
        
        { buttonInsert }
        { buttonAddField }
        { buttonCopyField }
        { buttonEditExt }
        { buttonEditList }
        { buttonEditField }
        { buttonDeleteField }

        <div className='padding'>

            {/* поля админки */}
            { DynForm ? DynForm.map( renderItem ) : '' }

        </div>
        </>
    )
}

export default Item
