import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Multiline from '../DocParts/Multiline';
import styles from "../AdminParts/Form.module.css"
import Grow from '@material-ui/core/Grow';
import {
    caption
} from '../../redux/actions/lang';


const Transition = React.forwardRef( function Transition( props, ref ) {
    return <Grow ref = { ref } { ...props } />;
} );


// диалоговое окно редактора одного поля

const EditDialog = ( props ) => {
 
    if( !props.open ) return '';

    const value = props.value;
    const onOk = props.onOk === undefined ? props.onCancel : () => { props.onCancel(); props.onOk( value ); }
    
    return (
        <Dialog
            open = { true }
            TransitionComponent = { Transition }
            onBackdropClick = { props.onCancel }
            onEscapeKeyDown = { props.onCancel }
        >
        
            <ValidatorForm
                id = "editDialog"
                onSubmit = { onOk }
                autoComplete = "off"
                className = { styles.xxForm }
            >

                <DialogContent>
                    
                    <Multiline
                        className = { styles.xxTextField }
                        label = { props.label }
                        name = "value"
                        value = { value }
                        onChange = { props.onChange }
                        autoFocus
                    />

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick = { onOk }
                        color = "secondary"
                        variant = "outlined"
                        size = "small"
                    > { caption.ok } </Button>
                    { props.onOk === undefined ? '' :
                        <Button onClick = { props.onCancel } color = "secondary" variant = "outlined" size = "small" > { caption.cancel } </Button>
                    }
                </DialogActions>
            
            </ValidatorForm>
            
        </Dialog>
    );
};

export default EditDialog;
