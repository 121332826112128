import {
// actions
    ADD_FIELD
   ,COPY_FIELD
   ,INSERT_FIELDS
   ,DELETE_FIELD
   ,DELETE_KEY_EMPTY_PROPS
   ,SET_ITEM
   ,SET_KEY_PROP_VALUE
// reducer names
   ,FORM_TEMPLATE
} from './../constants'

const initialState = {
}

export default (state = initialState, action) => {
    const key = action.payload.key;
    switch (action.type) {

        case FORM_TEMPLATE + SET_ITEM:
            return [ 
                ...state.map( (item, idx) => {
                    if( idx !== key ) return item;
                    return action.payload.value;
                })
            ];
            
        case FORM_TEMPLATE + SET_KEY_PROP_VALUE: // сохранить атрибуты в шаблон
            const propName = action.payload.name
            const propValue = action.payload.value
            return [ 
                ...state.map( (item, idx) => {
                    if( idx !== key ) return item;
                    
                    return {
                        ...item
                       ,[propName]: propValue
                    };
                })
            ];
            
        case FORM_TEMPLATE + DELETE_KEY_EMPTY_PROPS: // удалить пустые атрибуты
            return [ 
                ...state.map( (item, idx) => {
                    if( idx === key ) {
                        Object.keys( item ).forEach( i => ( item[i] === '' || item[i] === null || item[i] === undefined ) && delete item[i] );
                    }
                    return item;
                })
            ];
            
        case FORM_TEMPLATE + ADD_FIELD:
            if( !Array.isArray( state ) ) return state;
            const value = action.payload.value;
            // добавить поле перед текущим, по нажатию на +
            let newState = state.reduce(  (res, item, idx) => {
                if( idx === key ) res.push( value );
                res.push( item );
                return res;
            }, [] );
            // добавить поле в конец шаблона
            if( key === null ) newState.push( value );
            return newState;
            
        // больше не используется, INSERT_FIELDS вместо него
        case FORM_TEMPLATE + COPY_FIELD:
            if( !Array.isArray( state ) || key === null ) return state;
            // сделать копию текущего поля, добавить перед текущим полем
            return state.reduce(  (res, item, idx) => {
                if( idx === key ) {
                    let newItem = {...item};
                    if( item.label !== undefined ) newItem.label = item.label + " 2"
                    if( item.name !== undefined ) newItem.name = item.name + "_2"
                    res.push( newItem );
                }
                res.push( item );
                return res;
            }, [] );
            
        case FORM_TEMPLATE + INSERT_FIELDS:
            if( !Array.isArray( state ) ) return state;
            const start = key === null ? state.length : key;
            const fields = action.payload.fields;
            // вставить скопированные поля перед текущим полем
            state.splice( start, 0, ...fields );
            return state;
            
        case FORM_TEMPLATE + DELETE_FIELD:
            return state.filter( (item, idx) => idx !== key );
            
        default:
            return state
    }
}
