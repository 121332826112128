import React, { Component } from 'react';
import { connect } from 'react-redux';
import VSpin from './../components/Spin/Spin';

class Spin extends Component {

    render() {
        return (
            <VSpin
                {...this.props}
            />
        );
    };
};

const mapStateToProps = ({spin}) => {
    return spin;
};

export default connect( mapStateToProps, null )( Spin );
