import axios from "axios";
import {
    post_common
   ,setPropValue
} from '../actions/utils';
import {
    getBarcodes
   ,getSubtemplateFiles
} from '../actions/doc';
import {
    caption
} from './lang';
import {
    // properties
    APP_URL
    // reducer names
   ,DOC
} from '../constants';


// получает все обновления docState,
// и отправляет данные в Preview

// приложенные файлы Subtemplate - DocForm.js onChange()

// TODO можно ускорить: let gPreviewFrame = устанавливать при открытии / закрытии preview
export function getPreviewFrame() {
    return document.getElementById( "xxcdocPreviewFrame" );
}


export function postTableNames( tableNames ) {
    const iframe = getPreviewFrame();
    if( !iframe ) return;
    iframe.contentWindow.postMessage( { type: "tableNames", tableNames }, "*" );
}


export function postTableRowsNames( tableRowsNames ) {
    const iframe = getPreviewFrame();
    if( !iframe ) return;
    iframe.contentWindow.postMessage( { type: "tableRowsNames", tableRowsNames }, "*" );
}


export function postPreview( html, ctx, scrollTop, offsetHeight ) {
    const iframe = getPreviewFrame();
    if( !iframe ) return;
    iframe.contentWindow.postMessage( { type: "preview", html, ctx, scrollTop, offsetHeight }, "*" );
}


function setPreviewWidth( width ) {
    const iframe = getPreviewFrame();
    if( !iframe ) return;
    iframe.style.width = width + 'px';
}


// Preview, скролл на редактируемое поле
export function previewLastField( name ) {
    const iframe = getPreviewFrame();
    if( !iframe ) return;
    iframe.contentWindow.postMessage( { type: "lastField", name }, "*" );
}


function getShowPreview( dispatch ) {
    const props = dispatch( ( dispatch, getState ) => getState() );
    return props.doc.showPreview;
}


// Preview Template - загрузка шаблона вместе с вложенными файлами Subtemplate
export function previewTemplate( dispatch ) {
 
    const showPreview = getShowPreview( dispatch );
    if( !showPreview ) return;
    
    const formData = new FormData();
    getSubtemplateFiles( dispatch, formData );
    getBarcodes( dispatch, formData );

    getHtmlTemplate( dispatch, formData );
}


let cancelRequest;
function getHtmlTemplate( dispatch, formData ) {

    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    const config = {
        cancelToken: cancelRequest.token
       ,headers: {'content-type': 'multipart/form-data'}
    };

    const props = dispatch( ( dispatch, getState ) => getState() );
    const extUserId = props.user ? props.user.extUserId : '';
    const docId = props.doc.docId;
    if( !docId ) return;
    
    // для Preview таблиц
    // передаём теги компонентов "Таблица" и заголовки столбцов
    const tableNames = props.doc.docTemplates[ docId ].filter( i => i.type === "Table" && i.drawHeader !== false ).map( i => (
        { name: i.name, list: i.list }
    ));
    
    // для Preview таблиц
    // передаём теги компонентов "Таблица" без заголовков
    const tableRowsNames = props.doc.docTemplates[ docId ].filter( i => i.type === "Table" && i.drawHeader === false ).map( i => i.name );
    
    if( !formData ) formData = new FormData();
    formData.append( 'docId', docId );
    formData.append( 'extUserId', extUserId );
    
    post_common(
        APP_URL + '/api/doc/html'
       ,formData
       ,config
       ,dispatch
       ,caption.templateLoadError + ': '
       ,data => {
            if( data === 'EXPIRED' ) {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
            } else {
                setPreviewWidth( data.width );
                postTableNames( tableNames );
                postTableRowsNames( tableRowsNames );
                postPreview( data.html, '' );
            }
        }
    );
}
