import {
    CDOC_PROP
   ,SET_PROP_VALUE
} from '../constants';

export const initialState = {
    createFlVendor: null
   ,createNoneRezVendor: null
   ,createVendor: null
   ,startrekUrl: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case CDOC_PROP + SET_PROP_VALUE:
            const name = action.payload.name
            const value = action.payload.value
            return {
                ...state,
                [name]: value
            }

        default:
            return state;
    }
}
