import React from 'react';
import styles from "./HistoryTable.module.css"
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import DescriptionRounded from '@material-ui/icons/DescriptionRounded';
import ListAltRounded from '@material-ui/icons/ListAltRounded';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import {
    gLang
} from '../../redux/actions/lang';


const useStyles = makeStyles({
    div: {
        padding: "20px"
       ,fontSize: "16px"
    }
});

export default function SimplePopover() {
    const classes = useStyles();
    const [ anchorEl, setAnchorEl ] = React.useState( null );

    const handleClick = (event) => {
        setAnchorEl( event.currentTarget );
    };

    const handleClose = () => {
        setAnchorEl( null );
    };

    const open = Boolean( anchorEl );
    const id = open ? 'history-help' : undefined;

    const legend_ru = <>
        <div className={ classes.div }>
        <table>
        <tbody>
        <tr>
        <td><DescriptionRounded fontSize="default" className={ styles.doc_icon } /></td>
        <td>шаблон, созданный в MS Word</td>
        </tr>
        <tr style={{ backgroundColor: "white" }} >
        <td><ListAltRounded fontSize="default" className={ styles.doc_icon } /></td>
        <td>форма с полями, создаётся в Админке, выгружается в формате JSON</td>
        </tr>
        <tr>
        <td><KeyboardArrowUp fontSize="default" className={ styles.doc_icon } /></td>
        <td>указывает, что данная версия шаблона находится выше</td>
        </tr>
        </tbody>
        </table>
        </div>
        <div className={ classes.div }>Чтобы исключить дублирование, версии шаблонов сохраняются по мере изменения.
        <br/>Есть активная версия на текущий момент, и предыдущие версии, сохранённые на дату изменения.
        <br/>Поле "<b>Дата</b>" - это дата изменения, то есть "дата по" для данной версии.</div>
        <div className={ classes.div }>История старше 2-х лет удаляется.</div></>;
        
    const legend_en = <>
        <div className={ classes.div }>
        <table>
        <tbody>
        <tr>
        <td><DescriptionRounded fontSize="default" className={ styles.doc_icon } /></td>
        <td>template created in MS Word</td>
        </tr>
        <tr style={{ backgroundColor: "white" }} >
        <td><ListAltRounded fontSize="default" className={ styles.doc_icon } /></td>
        <td>form with fields, created on Admin page, downloaded in JSON format</td>
        </tr>
        <tr>
        <td><KeyboardArrowUp fontSize="default" className={ styles.doc_icon } /></td>
        <td>indicates that required version of the template is located higher</td>
        </tr>
        </tbody>
        </table>
        </div>
        <div className={ classes.div }>To avoid duplication, template versions are saved as they are changed.
        <br/>There is an active version at the current moment, and previous versions saved to the date of modification.
        <br/>The "<b>Date</b>" field is the modification date, i.e. the "date to" for this version.</div>
        <div className={ classes.div }>History older than 2 years is deleted.</div></>;

    return (
      <>
        <IconButton
            color="primary"
            style={{ position: "absolute", top: -5, right: -5 }}
            onClick={ handleClick }
        >
            <HelpOutlineRounded fontSize="large" className={ styles.xxHelp } />
        </IconButton>

        <Popover
            id={ id }
            open={ open }
            anchorEl={ anchorEl }
            onClose={ handleClose }
            anchorOrigin={{
                vertical: 'bottom'
               ,horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top'
               ,horizontal: 'center'
            }}
        >
        { gLang === 'en' ? legend_en : legend_ru }
        </Popover>
      </>
    );
}
