import {
// actions
    SET_FIELD
// properties
   ,APP_URL
// reducer names
   ,HISTORY
} from '../constants';
import {
    post_common
} from '../actions/utils';


export const setField = ( prefix, prop, docId, histId, field, value ) => (
    {
        type: prefix + SET_FIELD
       ,payload: {
            prop
           ,docId
           ,histId
           ,field
           ,value
        }
    }
);


export function saveCommentDB( dispatch, hist_id, template_id, hist_end_date, tzOffset, comment ) {

    if( comment === null || comment === undefined ) comment = '';
    
    // сразу записать значение в таблицу
    dispatch(
        setField( HISTORY, hist_id ? "past" : "present", template_id, hist_id, "comment", comment )
    );

    // отправить в БД
    
    const formData = new FormData();
    formData.append( 'hist_id', hist_id );
    formData.append( 'template_id', template_id );
    formData.append( 'hist_end_date', hist_end_date );
    formData.append( 'tzOffset', tzOffset );
    formData.append( 'comment', comment );

    const config = { headers: { 'content-type': 'multipart/form-data' } };

    post_common(
        APP_URL + '/api/history/comment'
       ,formData
       ,config
       ,dispatch
       ,''
       ,data => {}
    );
}
