import { 
// actions
    SET_PROPS
   ,SET_PROP_VALUE
// reducer names
   ,EXT_EDITOR
} from './../constants'


const initialState = {
    open: false
}

export default (state = initialState, action) => {
    switch (action.type) {
     
        case EXT_EDITOR + SET_PROPS:
            return action.payload.value
            
        case EXT_EDITOR + SET_PROP_VALUE:
            return {
                ...state
               ,[ action.payload.name ]: action.payload.value
            }
            
        default:
            return state
    }
}
