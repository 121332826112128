import React from 'react';
import styles from "./Form.module.css";
import { styled } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearRounded from '@material-ui/icons/ClearRounded';
import Tooltip from "@material-ui/core/Tooltip";
import {
    gLang
} from '../../../redux/actions/lang';


const captions = {
    "ru": {
        delete: "Удалить"
    }
    ,"en": {
        delete: "Delete"
    }
};


const CustomIcon = styled( IconButton )({
    "& .MuiTouchRipple-root": {
        top: "4px"
       ,left: "4px"
    }
});


const DeleteButton = ( props ) => {

    const lang = gLang;
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];
    
    const deleteButton = props.readonly ? <div style={{ padding: '4px' }} ></div> :
        <Tooltip title = { props.caption ? props.caption : caption.delete } arrow>
        <CustomIcon
            color = "primary"
            onClick = { props.onDelete }
            style = {{ padding: '4px 0px 0px 4px' }}
        >
            <ClearRounded
                fontSize = "default"
                className = { styles.xxIcon }
            />
        </CustomIcon>
        </Tooltip>;
        
    return deleteButton;
};

export default DeleteButton;
