export const iprocStyles = [
 { name: "Счёт", isDefault: "1" }
,{ name: "Прогноз", isDefault: "1" }
,{ name: "Разовый договор", isDefault: "1" }
,{ name: "Рамочный договор (без денег)", isDefault: "1" }
,{ name: "Рамочный договор и спецификация", isDefault: "1" }
,{ name: "Доп. соглашение", isDefault: "1" }
,{ name: "Счёт-оферта", isDefault: "1" }
];

// TODO нужно будет перевести на английский после перехода на id и исправление шаблонов, в которых используются значения
export const iprocStylesEn = [
 { name: "Account", isDefault: "1" }
,{ name: "Forecast", isDefault: "1" }
,{ name: "One-time contract", isDefault: "1" }
,{ name: "Framework contract (without money)", isDefault: "1" }
,{ name: "Framework contract and specification", isDefault: "1" }
,{ name: "Additional agreement", isDefault: "1" }
,{ name: "Invoice offer", isDefault: "1" }
];

export const iprocSubtypes = [ "STANDARD_AGR", "TERMINATION", "PROLONGATION", "UMBRELLA" ];
