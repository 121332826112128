import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {withStyles} from '@material-ui/core/styles';
import { COLOR_MAIN, COLOR_ARROW } from './../../constants';
//import AirlineSeatReclineNormal from '@material-ui/icons/AirlineSeatReclineNormal';
import logo from '../../assets/icon.svg';
import yaLogo from '../../assets/yandex_icon_cyrillic_black+white.svg';

const classes = {
    xxBox: {
        backgroundColor: COLOR_MAIN,
        boxSizing: 'border-box',
        outline: 0,
        fontSize: '18px',
        textAlign: 'center',
        //cursor: 'pointer',
        userSelect: 'none',
        width: 120,
        lineHeight: '49px',
        height: '49px',
    },
    xxArrow: {
        fontSize: '30px',
        color: COLOR_ARROW,
        position: "absolute",
        top: 8,
        left: -27
    },
    xxImg: {
        position: "absolute",
        opacity: 0.6,
        top: -8.5,
        left: 10
    },
    xxText: {
        //color: COLOR_ARROW_TEXT,
        color: "rgba(0, 0, 0, 0.6)",
        position: "absolute",
        left: 65,
        top: 0,
        //fontFamily: "Tahoma"
    },
    xxTriangle: {
        content: '',
        display: 'inline-block',
        padding: '0px',
        position: 'relative',
        boxSizing: 'border-box',
        border: '24.5px solid transparent',
        borderLeft: '24px solid ' + COLOR_MAIN,
    },
    xxAngle: {
        content: '',
        display: 'inline-block',
        padding: '0px',
        position: 'relative',
        boxSizing: 'border-box',
        border: '49px solid transparent',
        borderLeft: '30px solid ' + COLOR_MAIN,
        borderTop: '24px'
    }
};

const ArrowComponent = ({ classes, props }) => {
 
    const picture = props.isExternal ? logo : yaLogo;
    const style = props.isExternal ? classes.xxAngle : classes.xxTriangle;
    
    return (
        <React.Fragment>
            <Grid container wrap={'nowrap'}>
                <Grid item >
                    <Box component="div" className={classes.xxBox}>
                        {/*<AirlineSeatReclineNormal className={classes.xxArrow} fontSize="large" />*/}
                        <img alt="" className={classes.xxImg} width="65" height="65" src={ picture } />
                        <span className={classes.xxText} >
                            Cdoc
                        </span>
                    </Box>
                </Grid>
                <Grid item>
                    <span className={ style } />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const ArrowStyled = withStyles( classes )( ArrowComponent );

const  Arrow = ( props ) => {
    return <ArrowStyled props = { props } />;
};

export default Arrow;
