import React from 'react'
import Button from '@material-ui/core/Button';
import Select from "./Select";
import HiddenInput from "./HiddenInput";
import Text from "./Text";
import { IPROC_SUBTYPE_PROP } from "../../redux/constants";

const captions = {
    "ru": {
        iprocText: 'Компонент "Завести в Я.Покупку" доступен только пользователям с ролью "Я.Инициатор". Запросить роль можно через IDM.'
       ,iprocStyle: "Стиль ЗП"
       ,iprocSubtype: "Подтип ЗП"
       ,iprocProcess: "Завести в Я.Покупку"
    }
    ,"en": {
        iprocText: '"Import to Yandex Procure" component is available only to users with the "Y.Initiator" role. You can request the role in IDM.'
       ,iprocStyle: "Purchase request style"
       ,iprocSubtype: "Subtype of purchase request"
       ,iprocProcess: "Import to Yandex Procure"
    }
};


const IprocIntegration = (props) => {
 
    if( !props.visible && props.visible !== undefined ) return;
    if( props.isExternal ) return;

    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];
    
    const format = props.format ? props.format : 'pdf';
    return ( !props.isInitiator ?
        Text({
            label: caption.iprocText
        })
        :
        <>
            <HiddenInput name="isTemplate" value={props.isTemplate}/>
            <HiddenInput name="startrekQueueIproc" value={props.startrekQueueIproc}/>
            
            {/* эту строку нужно удалить, иначе получается два xxya_cdoc_iproc,
                один пустой, второй с Y, и в бэке мы берем iproc[0] - там пусто, поэтому коммент не формируется
            <HiddenInput name={IPROC} value={props.iproc}/> */}

            {/* TODO удалить, когда перейдём на ya_org_id, он будет прописан в Yandex.jsx? или оставить */}
            <HiddenInput name="orgId" value={props.orgId}/>

            {(props.hideIprocStyle) ?
                <HiddenInput name={props.name} value={props.iprocStyleValue}/>
                :
                <Select
                    label = { caption.iprocStyle }
                    name={props.name}
                    className={props.className}
                    value={props.iprocStyleValue}
                    list={props.list}
                    onChange={props.onChange}
                />}
            {(props.iprocStyleValue === IPROC_SUBTYPE_PROP) ?
                (props.hideIprocStyle) ?
                    <HiddenInput name="iprocSubtype" value={props.iprocSubtypeValue}/>
                    :
                    <Select
                        label = { caption.iprocSubtype }
                        name="iprocSubtype"
                        className={props.className}
                        value={props.iprocSubtypeValue}
                        list={props.subtype}
                        onChange={props.onChange}
                    /> : ''}
                    
            <div className='padding' style={{ display: "flex", flexDirection: "row" }}>
                <div className={props.className}>{ props.label }</div>
                <Button type="submit" variant="contained" color="primary" style={{ minWidth: '190px' }}
                        onClick={ () => props.onCreateTicket( format ) }>
                    { caption.iprocProcess }
                </Button>
            </div>
        </>
    );
};

export default IprocIntegration;
