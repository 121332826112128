import React from 'react';
import './LayoutIframe.css'

function LayoutIframe( { props } ) {

    const main = !props.main ? '' :
        <div className="main">
            { props.main }
        </div>;
        
    return main;
}

export default LayoutIframe;
