import {
// actions
    ADD
   ,DELETE
   ,SET_ID
// reducer names
   ,ROLE
} from './../constants'

const initialState = []

export default (state = initialState, action) => {
    if( !Array.isArray( state ) ) return state;
    switch (action.type) {

        case ROLE + ADD:
            const value = action.payload.value;
            // без повторов, можно так [ ...new Set( [ value, ...state ] ) ]
            if( state.findIndex( i => i.userId === value.userId && i.userType === value.userType ) > -1 ) return state;
            // добавить в начало
            return [ value, ...state ];
            
        case ROLE + DELETE:
            return state.filter( i => i.roleId !== action.payload.roleId );

        // когда пришёл roleId из базы,
        // заменить временный id
        case ROLE + SET_ID:
            return state.map( i => i.roleId === action.payload.oldId ? { ...i, roleId: action.payload.newId } : i );

        default:
            return state
    }
}
