import {
   // properties
    APP_URL
   // actions
   ,SET_PROPS
   ,SET_DOC_ID
   ,ADD
   ,COPY
   ,DELETE
   ,EDIT
   // reducer names
   ,FORM_TEMPLATE
   ,TEMPLATE_EDITOR
} from '../constants'
import {
    saveTemplateDB
} from './admin'
import {
    post_common
} from '../actions/utils'
import { newTemplate } from './../reducers/newTemplate';
import {
    caption
} from './lang';


export const close = () => (
    {
        type: TEMPLATE_EDITOR + SET_PROPS,
        payload: {
            value: { open: false }
        }
    }
);

export const addTemplate = ( name, code, groupId ) => (
  {
    type: FORM_TEMPLATE + ADD,
    payload: {
        name
       ,code
       ,groupId
    }
  }
);

export const copyTemplate = ( id, name, code, groupId ) => (
  {
    type: FORM_TEMPLATE + COPY,
    payload: {
        id
       ,name
       ,code
       ,groupId
    }
  }
);

export const editTemplate = ( id, name, code, groupId ) => (
  {
    type: FORM_TEMPLATE + EDIT,
    payload: {
        id
       ,name
       ,code
       ,groupId
    }
  }
);

export const deleteTemplate = () => (
  {
    type: FORM_TEMPLATE + DELETE
  }
);

export const setTemplateId = ( id ) => (
  {
    type: FORM_TEMPLATE + SET_DOC_ID,
    payload: {
        id
    }
  }
);

export function addTemplateDB( dispatch, name, code, groupId ) {

    const formData = new FormData();
    formData.append('name', name);
    formData.append('code', code);
    formData.append('group_id', groupId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/template/add'
       ,formData
       ,config
       ,dispatch
       ,caption.addTemplateError + ': '
       ,data => {
            const docId = data;
            if( !Number.isInteger( docId ) ) return;
            dispatch( setTemplateId( docId ) ); // заменить временный id на постоянный из базы
            saveTemplateDB( dispatch, docId, newTemplate ); // добавить в шаблон компоненты по умолчанию, и сохранить в базу
        }
    );
}

export function copyTemplateDB( dispatch, srcDocId, name, code, groupId ) {

    const formData = new FormData();
    formData.append('name', name);
    formData.append('code', code);
    formData.append('id', srcDocId);
    formData.append('group_id', groupId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/template/copy'
       ,formData
       ,config
       ,dispatch
       ,caption.copyTemplateError + ': '
       ,data => {
            const docId = data;
            if( !Number.isInteger( docId ) ) return;
            dispatch( setTemplateId( docId ) ); // заменить временный id на постоянный из базы
            // шаблон копируется на фронте (doc.js FORM_TEMPLATE + COPY) и беке независимо, не сохраняю в базу
            // saveTemplateDB( dispatch, docId, template );
        }
    );
}

export function deleteTemplateDB( dispatch, docId, groupId ) {

    if( !Number.isInteger( docId ) ) return;
    if( docId < 1 ) { dispatch( deleteTemplate() ); return; }
    const formData = new FormData();
    formData.append('id', docId);
    formData.append('group_id', groupId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/template/delete'
       ,formData
       ,config
       ,dispatch
       ,caption.delTemplateError + ': '
       ,data => {
            if( data !== 'OK' ) return;
            dispatch( deleteTemplate() );
        }
    );
    
    // setDocIdToAddressBar() вызывается в NavBar.js shouldComponentUpdate()
}

export function editTemplateDB( dispatch, docId, name, code, groupId ) {

    if( !Number.isInteger( docId ) || docId < 1 ) return;
    const formData = new FormData();
    formData.append('id', docId);
    formData.append('name', name);
    formData.append('code', code);
    formData.append('group_id', groupId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/template/edit'
       ,formData
       ,config
       ,dispatch
       ,caption.saveTemplateError + ': '
       ,data => {
        }
    );
}
