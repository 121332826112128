import React from 'react';
import './LayoutPreview.css'

function LayoutPreview( { props } ) {

    const header = !props.header ? '' :
        <div className="header">
            { props.header }
        </div>;

    const main = !props.main ? '' :
        <div className="main">
            { props.main }
        </div>;
        
    const preview = !props.preview ? '' :
        <div id="previewContainer" className="preview">
            { props.preview }
        </div>;
        
    const footer = !props.footer ? '' :
        <div className="footer">
            { props.footer }
        </div>;
        
        
    return (
        <div className="LayoutPreview">

            { header }

            <div className="panel">

                { main }                
                { preview }

            </div>
            
            { footer }

        </div>
    );
}

export default LayoutPreview;
