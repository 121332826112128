import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { COLOR_GREY, COLOR_GREY_BACKGROUND } from './../../constants';

const classes = {
    xxFooter: {
        height: '50px',
        borderTop: '1px solid ' + COLOR_GREY,
        borderBottom: '1px solid ' + COLOR_GREY,
        backgroundColor: COLOR_GREY_BACKGROUND,
        display: "flex",
        color: '#808080',
        fontSize: '13px',
    }
   ,xxLeft: {
        margin: '16px auto 16px 55px',
    }
   ,xxRight: {
        margin: '16px 55px 16px auto',
    }
   ,xxA: {
        color: '#808080'
   }
};

const captions = {
    "ru": {
        yandex: 'ООО "ЯНДЕКС"'
       ,confidential: "Политика конфиденциальности"
       ,manual: "Руководство"
       ,bug: "Поддержка"
    }
   ,"en": {
        yandex: "YANDEX LLC"
       ,confidential: "Privacy Policy"
       ,manual: "Guide"
       ,bug: "Support"
    }
};

const FooterComponent = ( { classes, props } ) => {
 
    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];
    
    return (
        <div className = { classes.xxFooter } >
        
        { props.isExternal ? '' :
        <>
        <div className = { classes.xxLeft } >
        <a className = { classes.xxA } href={ "https://cdoc.daas.yandex-team.ru/index.html" } target="_blank" rel="noreferrer noopener" >
            { caption.manual }</a>
            
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        
        <a className = { classes.xxA } href={ "https://forms.yandex-team.ru/surveys/85609" } target="_blank" rel="noreferrer noopener" >
            { caption.bug }</a>
        </div>

        <div className = { classes.xxRight } >
        © { caption.yandex }, 2024, <a className = { classes.xxA } href={ "https://yandex.ru/legal/confidential/?lang=" + lang } target="_blank" rel="noreferrer noopener" >
            { caption.confidential }</a>
        </div>
        </>
        }
        
        </div>
    );
};

const FooterStyled = withStyles( classes )( FooterComponent );

const Footer = ( props ) => {
    return <FooterStyled props = { props } />;
};

export default Footer;
