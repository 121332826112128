import { connect } from 'react-redux'
import React, { Component } from 'react';
import { default as VEditDialog } from '../components/Edit/EditDialog';
import {
    EDIT_DIALOG
} from '../redux/constants';
import {
    close
} from '../redux/actions/editDialog';
import {
    setPropValue
} from '../redux/actions/utils';


class EditDialog extends Component {
 
    render() {
        return (
            <VEditDialog
                { ...this.props }
            />
        )
    }
}


const mapStateToProps = ( props ) => {
    return props.editDialog;
};


const mapDispatchToProps = dispatch => ({
    onChange: ( e ) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked :
                      target.value;
        dispatch( setPropValue( EDIT_DIALOG, name, value ) );
    }
   //,onOk: передаётся параметром при открытии редактора
   ,onCancel: () => dispatch( close() )
});


export default connect( mapStateToProps, mapDispatchToProps )( EditDialog );
