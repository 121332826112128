import amber from "@material-ui/core/colors/amber";

//Colors
export const COLOR_MAIN = amber[500];
export const COLOR_SECONDARY = '#000000';
export const COLOR_ARROW = '#c88800';
export const COLOR_ARROW_TEXT = '#b87800';
export const COLOR_WHITE = 'white';
export const COLOR_GREY = '#e8e8e8';
export const COLOR_GREY_BACKGROUND = '#f8f8f8';
export const COLOR_ACTIVE = 'black';
export const COLOR_NORMAL = 'rgb(94, 91, 91)';
export const COLOR_BLUE = '#3e98c7';
export const COLOR_LIGHT_GREY = '#c0c0c0';
export const COLOR_YELLOW = '#ffcc33';
export const COLOR_GREEN = '#669933';
export const COLOR_RED = '#cc3300';
