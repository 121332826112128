import {
// actions
    SET_FIELD
   ,SET_KEY_PROP_VALUE
   ,SET_PROP_VALUE
// reducer names
   ,HISTORY
} from './../constants';
import * as moment from "moment";

const tzOffset = new Date().getTimezoneOffset();

const initialState = {
    present: {} // { docId: { строка из XXYA.CDOC_TEMPLATES } }
   ,past: {}    // { docId: [ массив строк из HIST.CDOC_HIST_TEMPLATES ] }
   ,dateFrom: moment( new Date() ).subtract(1, 'months').format('DD.MM.YYYY')
   ,dateTo:   moment( new Date() ).format('DD.MM.YYYY')
   ,limit: 50
   ,tzOffset // смещение timezone в минутах, -180 для Москвы
}

export default ( state = initialState, action ) => {
 
    switch (action.type) {

        case HISTORY + SET_FIELD:
            const prop = action.payload.prop;
            const docId = action.payload.docId;
            const histId = action.payload.histId;
            const field = action.payload.field;
            const val = action.payload.value;
            return {
                ...state,
                [ prop ]: {
                    ...state[ prop ]
                   ,[ docId ]: histId === 0 ? // present - templates table
                        {
                            ...state[ prop ][ docId ]
                           ,[ field ]: val
                        }
                        : // past - history table
                        state[ prop ][ docId ].map( i => i.hist_id === histId ? { ...i, [ field ]: val } : { ...i } )
                }
            };

        case HISTORY + SET_PROP_VALUE:
            const payload = action.payload;
            return {
                ...state,
                [ payload.name ]: payload.value
            };

        case HISTORY + SET_KEY_PROP_VALUE:
            const key = action.payload.key;
            const name = action.payload.name;
            const value = action.payload.value;
            return {
                ...state,
                [ name ]: {
                    ...state[ name ]
                   ,[ key ]: value
                }
            };

        default:
            return state;
    }
}
