import { 
// actions
    ADD_LI
   ,ADD_LI_ALL
   ,DELETE_LI
   ,DELETE_LI_ALL
   ,SET_PROPS
   ,SET_PROP_VALUE
   ,SET_KEY_PROP_VALUE
// reducer names
   ,LIST_EDITOR
} from './../constants'


const initialState = {
    open: false
}

export default (state = initialState, action) => {
    switch (action.type) {
     
        case LIST_EDITOR + SET_PROPS:
            return action.payload.value
            
        case LIST_EDITOR + SET_PROP_VALUE:
            return {
                ...state
               ,[ action.payload.name ]: action.payload.value
            }
            
        case LIST_EDITOR + SET_KEY_PROP_VALUE:
            const key = action.payload.key;
            const name = action.payload.name;
            const value = action.payload.value;
            const list = state[ name ];
            if( !Array.isArray( list ) ) return state;
            list[ key ] = value;
            return {
                ...state
               ,[ name ]: [ ...list ]
            }

        case LIST_EDITOR + ADD_LI:
            const listName = action.payload.name;
            const aList = state[ listName ];
            if( !Array.isArray( aList ) ) return state;
            return {
                ...state
               ,[ listName ]: [ ...aList, '' ]
            }

        case LIST_EDITOR + ADD_LI_ALL:
            const optionsList = state.optionsList;
            if( !Array.isArray( optionsList ) ) return state;
            return {
                ...state
               ,list: [ ...optionsList ]
            }

        case LIST_EDITOR + DELETE_LI:
            const liKey = action.payload.key;
            const liName = action.payload.name;
            const liArray = state[ liName ];
            const array = !Array.isArray( liArray ) ? [] : liArray.filter( (item, key) => key !== liKey );
            return {
                ...state
               ,[ liName ]: !array.length ? [''] : array
            }
            
        case LIST_EDITOR + DELETE_LI_ALL:
            return {
                ...state
               ,[ action.payload.name ]: ['']
            }
            
        default:
            return state;
    }
}
