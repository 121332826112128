import {
    YANDEX
   ,SET_STATE_PROP_VALUE
} from './../constants'

const initialState = {
}

export default ( state = initialState, action ) => {
 
    switch (action.type) {

        case YANDEX + SET_STATE_PROP_VALUE:
            const data = action.payload.data;
            if( !Array.isArray( data ) ) return state.yaOrgs;
            const ids = data.map( i => i.id );
            const newData =  [
                ...state.yaOrgs.filter( i => !ids.includes( i.id ) )
               ,...data
            ]
            return newData.sort( (a,b) => a.ya_name.localeCompare( b.ya_name ) );
            
        default:
            return state.yaOrgs
    }
}
