import React from 'react';
import './App.css';
import IframeHeight from '../../IframeHeight';
import AdminForm from '../../containers/AdminForm';
import DocForm from '../../containers/DocForm';
import RoleForm from '../../containers/RoleForm';
import HistoryForm from '../../containers/HistoryForm';
import UploadsForm from "../../containers/UploadsForm";
import Modal from '../Modal/Modal';
import { Switch, Route } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from './../Footer/Footer';
import LayoutMain from './../Layout/LayoutMain';
import LayoutPreview from './../Layout/LayoutPreview';
import LayoutIframe from './../Layout/LayoutIframe';
import Preview from '../../containers/Preview';
import DebugPanel from "../DebugPanel/DebugPanel";
import {
    APP_URL
} from '../../redux/constants';


// TODO set isIframe property in reducer?
function setIframe( props ) {
    if( props.user ) props.user.isIframe = true;
}

function setPassiveIframe( props ) {
    setIframe( props );
    if( props.user ) props.user.isPassiveIframe = true;
}

// monitor and send iframe hiegth to parent window
let objIframeHeight;

function App( props ) {

    let main;
    if( props.user.maxAccessType === 'ADMIN' || props.user.isSuper )
        main =
            <Switch>
                <Route path={ APP_URL + '/iframe' } component={ () => { setIframe( props ); return <DocForm/>; } } />
                <Route path={ APP_URL + '/pframe' } component={ () => { setPassiveIframe( props ); return <DocForm/>; } } />
                <Route path={ APP_URL + '/uploads' } component={ () => <UploadsForm/> } />
                <Route path={ APP_URL + '/history' } component={ () => <HistoryForm/> } />
                <Route path={ APP_URL + '/role' } component={ () => <RoleForm/> } />
                <Route path={ APP_URL + '/admin' } component={ () => <AdminForm/> } />
                <Route path={ APP_URL + '/' } component={ () => <DocForm/> } />
                <Route path={ '/' } component={ () => <DocForm/> } />
            </Switch>;
    else if( props.user.login !== null && !props.user.isExternal )
        main =
            <Switch>
                <Route path={ APP_URL + '/iframe' } component={ () => { setIframe( props ); return <DocForm/>; } } />
                <Route path={ APP_URL + '/pframe' } component={ () => { setPassiveIframe( props ); return <DocForm/>; } } />
                <Route path={ APP_URL + '/uploads' } component={ () => <UploadsForm/> } />
                <Route path={ APP_URL + '/' } component={ () => <DocForm/> } />
                <Route path={ '/' } component={ () => <DocForm/> } />
            </Switch>;
    else
        main =
            <Switch>
                <Route path={ APP_URL + '/iframe' } component={ () => { setIframe( props ); return <DocForm/>; } } />
                <Route path={ APP_URL + '/pframe' } component={ () => { setPassiveIframe( props ); return <DocForm/>; } } />
                <Route path={ APP_URL + '/' } component={ () => <DocForm/> } />
                <Route path={ '/' } component={ () => <DocForm/> } />
            </Switch>;

    const dispatch = props.dispatch;
    const isIframe = props.user && props.user.isIframe;
    const isExternal = props.user && props.user.isExternal;
    const showPreview = props.showPreview /*&& !isExternal*/ && !isIframe;

    const header = isIframe ? null : <Header lang = { props.lang } isExternal = { isExternal } dispatch = { dispatch } />;
    const footer = isIframe || ( showPreview && isExternal ) ? null : <Footer lang = { props.lang } isExternal = { isExternal } />;
    const preview = showPreview ? <Preview /> : '';
    
    const layout =
        isIframe ?
            <LayoutIframe props = {{ main }} /> :
        showPreview ?
            <LayoutPreview props = {{ header, main, preview, footer }} /> :
            <LayoutMain props = {{ header, main, footer }} />;
    
    // если cdoc встроен в iframe,
    // следить за высотой и отправлять сообщения родителю,
    // чтобы установить 100% высоту iframe по содержимому,
    // и не было скролла внутри iframe
    if( isIframe && !objIframeHeight ) {
        objIframeHeight = new IframeHeight();
        objIframeHeight.init();
    }

    return (
        <>
            { layout }
            <Modal { ...props.modal } onCancel={ props.onCancel } />
            <DebugPanel isExternal = { isExternal } />
        </>
    );
}

export default App;
