import axios from 'axios';
import {
    setDocStatePropMap
   ,setDocStatePropValue
} from './doc';
import {
    getOptionsListRecords
   ,getOptionsRecordProp
   ,copyProps
   ,dateParts
} from './optionsList';
import {
   // properties
    APP_URL
   // reducer names
   ,DOC
} from '../constants';
import {
    get_common
   ,makeUnique
   ,setPropValue
} from './utils';
import {
    caption
} from './lang';


// id полей используются в шаблонах Word, менять нельзя
export const captions = {
    "ru": {
        hr_login: "Логин"
       ,hr_business_group_name: "Бизнес-группа"
       ,hr_last_name: "Фамилия"
       ,hr_first_name: "Имя"
       ,hr_middle_names: "Отчество"
       ,hr_fio: "ФИО"
       ,hr_familyio: "Фамилия И.О."
       ,hr_iofamily: "И.О. Фамилия"
       ,hr_birth_date: "Дата рождения"
       ,hr_job_name: "Должность"
       ,hr_inn: "ИНН"
       ,hr_snils: "СНИЛС"
       ,hr_citizenship: "Гражданство"
       ,hr_address: "Адрес личный"
       ,hr_address_alt: "Адрес альтернативный"
       ,hr_email: "Email личный"
       ,hr_phone: "Телефон"
       ,id_doc_type: "Вид документа"
       ,id_doc_series: "Серия"
       ,id_doc_number: "Номер"
       ,id_doc_date_from: "Дата выдачи"
       ,id_doc_issued_by: "Кем выдан"
       ,id_doc_date_to: "Действителен до"
       ,id_doc_dep_code: "Код подразделения"
       ,id_doc_full: "Паспортные данные"
       ,hr_name_en: "ФИО латиницей"
       ,hr_fio_gen: "ФИО в родительном падеже"
       ,hr_fio_dat: "ФИО в дательном падеже"
       ,hr_fio_acc: "ФИО в винительном падеже"
       ,hr_fio_inst: "ФИО в творительном падеже"
       ,hr_fio_prepos: "ФИО в предложном падеже"
       ,hr_address_r: "Адрес регистрации"
       ,hr_address_c: "Адрес проживания"
       ,hr_assignment_number: "Номер назначения"
       ,hr_contract_number: "Номер трудового договора"
       ,hr_contract_date: "Дата трудового договора"
       ,hr_employee_number: "Табельный номер"
       ,hr_assg_job_name: "Должность (назначение)"
       ,hr_assg_job_name_gen: "Должность (назначение) в родительном падеже"
       ,hr_assg_job_name_dat: "Должность (назначение) в дательном падеже"
       ,hr_assg_job_name_acc: "Должность (назначение) в винительном падеже"
       ,hr_assg_job_name_inst: "Должность (назначение) в творительном падеже"
       ,hr_assg_job_name_prepos: "Должность (назначение) в предложном падеже"
       ,hr_tax_unit_full_name: "ЮЛ полное наименование"
       ,hr_tax_unit_short_name: "ЮЛ краткое наименование"
       ,hr_general_visa_familyio: "Ген. дир. Фамилия И.О."
       ,hr_general_visa_iofamily: "Ген. дир. И.О. Фамилия"
       ,hr_general_visa_fio: "Ген. дир. ФИО"
       ,hr_general_visa_gen: "Ген. дир. ФИО в родительном падеже"
       ,hr_general_visa_dat: "Ген. дир. ФИО в дательном падеже"
       ,hr_general_visa_acc: "Ген. дир. ФИО в винительном падеже"
       ,hr_general_visa_inst: "Ген. дир. ФИО в творительном падеже"
       ,hr_general_visa_prepos: "Ген. дир. ФИО в предложном падеже"
       ,hr_general_visa_job: "Ген. дир. должность"
       ,hr_general_visa_job_gen: "Ген. дир. должность в родительном падеже"
       ,hr_general_visa_job_dat: "Ген. дир. должность в дательном падеже"
       ,hr_general_visa_job_acc: "Ген. дир. должность в винительном падеже"
       ,hr_general_visa_job_inst: "Ген. дир. должность в творительном падеже"
       ,hr_general_visa_job_prepos: "Ген. дир. должность в предложном падеже"
       ,hr_general_visa_snils: "Ген. дир. СНИЛС"
       ,hr_dept_full_name: "Подразделение полное наименование"
       ,hr_dept_short_name: "Подразделение краткое наименование"
       ,hr_dept_manag_name: "Подразделение системное наименование"
       
       ,hr_account_bank_name: "Название банка"
       ,hr_account_bic: "БИК"
       ,hr_account_bank: "Банковский счёт"
       ,hr_account_pers: "Лицевой счёт"
       ,hr_account_corr: "Корреспондентский счёт"
       
       ,hr_sex: "Пол"
       ,hr_reg_city: "Город регистрации"
       ,hr_loan_number: "Номер займа"
       ,hr_loan_purpose: "Цель займа"
       ,hr_loan_date: "Дата займа"
       ,hr_town_of_birth: "Город рождения"
       ,hr_region_of_birth: "Область рождения"
       ,hr_country_of_birth: "Страна рождения"
       ,hr_marital_status_name: "Семейное положение"
       ,hr_tk_status_name: "Информация о трудовой книжке"
       ,hr_reg_country: "Адрес: Страна"
       ,hr_reg_region: "Адрес: Регион"
       ,hr_reg_area: "Адрес: Район"
       ,hr_reg_town: "Адрес: Город"
       ,hr_reg_settlement: "Адрес: Населенный пункт"
       ,hr_reg_street: "Адрес: Улица"
       ,hr_reg_house: "Адрес: Дом"
       ,hr_reg_building: "Адрес: Корпус"
       ,hr_reg_apartment: "Адрес: Квартира"
       ,hr_reg_postal_code: "Адрес: Индекс"
       ,hr_reg_combination: "Адрес: Улица, дом, корпус, квартира"
       ,hr_division: "Кадровое подразделение"
       ,hr_it_num: "Номер ИТ-аккредитации"
       ,hr_it_start_date: "Дата ИТ-аккредитации"
       
       ,subpoena_receipt_date: "Дата получения повестки"
       ,subpoena_seria_num: "Серия и номер повестки"
       ,subpoena_appearence_date_time: "Дата и время явки в военкомат"
       ,subpoena_military_office: "Наименование военкомата"
       ,subpoena_military_office_address: "Адрес военкомата"
       ,subpoena_type: "Тип повестки"
       ,subpoena_reason: "Причина вызова в военкомат"
       
       ,hr_contact_full_name: "ФИО контакта"
       ,hr_contact_type: "Тип контакта"
       ,hr_contact_date_of_birth: "Дата рождения контакта"
       
       ,hr_qualification_name: "Вид образования"
       ,hr_qualification_establishment: "Учебное учреждение"
       ,hr_qualification_type: "Квалификация"
       ,hr_qualification_speciality: "Специальность"
       ,hr_qualification_doc_type: "Тип документа об образовании"
       ,hr_qualification_doc: "Номер документа об образовании"
       ,hr_qualification_awarded_date: "Дата выдачи документа об образовании"
       ,hr_qualification_okso: "Код специальности ОКСО"

       ,hr_military_doc_type: "Наименование документа воинского учёта"
       ,hr_military_doc: "Серия и номер документа воинского учёта"
       ,hr_military_doc_date: "Дата выдачи"
       ,hr_military_issued_by: "Кем выдан"
       ,hr_military_worth_category: "Категория годности"
       ,hr_military_rank: "Воинское звание"
       ,hr_military_reserve_category: "Категория запаса"
       ,hr_military_staff: "Состав (профиль)"
       ,hr_military_speciality: "Код ВУС"
       ,hr_military_office: "Наименование военного комиссариата по месту воинского учёта"
    }
   ,"en": {
        hr_login: "Login"
       ,hr_business_group_name: "Business group"
       ,hr_last_name: "Last name"
       ,hr_first_name: "First name"
       ,hr_middle_names: "Middle names"
       ,hr_fio: "Full name"
       ,hr_familyio: "Last name F.M."
       ,hr_iofamily: "F.M. Last name"
       ,hr_birth_date: "Date of birth"
       ,hr_job_name: "Position"
       ,hr_inn: "INN"
       ,hr_snils: "Insurance Number"
       ,hr_citizenship: "Citizenship"
       ,hr_address: "Personal address"
       ,hr_address_alt: "Alternative address"
       ,hr_email: "Personal email"
       ,hr_phone: "Phone"
       ,id_doc_type: "Type of document"
       ,id_doc_series: "Series"
       ,id_doc_number: "Number"
       ,id_doc_date_from: "Date of issue"
       ,id_doc_issued_by: "Issued by"
       ,id_doc_date_to: "Valid until"
       ,id_doc_dep_code: "Department code"
       ,id_doc_full: "Passport data"
       ,hr_name_en: "Full name in Latin"
       ,hr_fio_gen: "Full name in Genitive"
       ,hr_fio_dat: "Full name in Dative"
       ,hr_fio_acc: "Full name in Accusative"
       ,hr_fio_inst: "Full name in Instrumental"
       ,hr_fio_prepos: "Full name in Prepositional"
       ,hr_address_r: "Registration address"
       ,hr_address_c: "Residential address"
       ,hr_assignment_number: "Assignment number"
       ,hr_contract_number: "Contract number"
       ,hr_contract_date: "Contract date"
       ,hr_employee_number: "Employee number"
       ,hr_assg_job_name: "Position (assignment)"
       ,hr_assg_job_name_gen: "Position (assignment) in Genitive"
       ,hr_assg_job_name_dat: "Position (assignment) in Dative"
       ,hr_assg_job_name_acc: "Position (assignment) in Accusative"
       ,hr_assg_job_name_inst: "Position (assignment) in Instrumental"
       ,hr_assg_job_name_prepos: "Position (assignment) in Prepositional"
       ,hr_tax_unit_full_name: "Tax Unit full name"
       ,hr_tax_unit_short_name: "Tax Unit short name"
       ,hr_general_visa_familyio: "General manager Last name F.M."
       ,hr_general_visa_iofamily: "General manager F.M. Last name"
       ,hr_general_visa_fio: "General manager Full name"
       ,hr_general_visa_gen: "General manager Full name in Genitive"
       ,hr_general_visa_dat: "General manager Full name in Dative"
       ,hr_general_visa_acc: "General manager Full name in Accusative"
       ,hr_general_visa_inst: "General manager Full name in Instrumental"
       ,hr_general_visa_prepos: "General manager Full name in Prepositional"
       ,hr_general_visa_job: "General manager Position"
       ,hr_general_visa_job_gen: "General manager Position in Genitive"
       ,hr_general_visa_job_dat: "General manager Position in Dative"
       ,hr_general_visa_job_acc: "General manager Position in Accusative"
       ,hr_general_visa_job_inst: "General manager Position in Instrumental"
       ,hr_general_visa_job_prepos: "General manager Position in Prepositional"
       ,hr_general_visa_snils: "General manager insurance number"
       ,hr_dept_full_name: "Department full name"
       ,hr_dept_short_name: "Department short name"
       ,hr_dept_manag_name: "Department system name"
       
       ,hr_account_bank_name: "Bank name"
       ,hr_account_bic: "BIC"
       ,hr_account_bank: "Bank account"
       ,hr_account_pers: "Personal account"
       ,hr_account_corr: "Correspondent account"
       
       ,hr_sex: "Gender"
       ,hr_reg_city: "City of registration"
       ,hr_loan_number: "Loan number"
       ,hr_loan_purpose: "Loan purpose"
       ,hr_loan_date: "Loan date"
       ,hr_town_of_birth: "Town of birth"
       ,hr_region_of_birth: "Region of birth"
       ,hr_country_of_birth: "Country of birth"
       ,hr_marital_status_name: "Marital status"
       ,hr_tk_status_name: "Information about employment history book"
       ,hr_reg_country: "Address: Country"
       ,hr_reg_region: "Address: Region"
       ,hr_reg_area: "Address: Area"
       ,hr_reg_town: "Address: Town"
       ,hr_reg_settlement: "Address: Settlement"
       ,hr_reg_street: "Address: Street"
       ,hr_reg_house: "Address: House"
       ,hr_reg_building: "Address: Building"
       ,hr_reg_apartment: "Address: Apartment"
       ,hr_reg_postal_code: "Address: Postal code"
       ,hr_reg_combination: "Address: Street, house, building, apartment"
       ,hr_division: "HR Division"
       ,hr_it_num: "IT-accreditation number"
       ,hr_it_start_date: "IT-accreditation date"
       
       ,subpoena_receipt_date: "Subpoena receipt date"
       ,subpoena_seria_num: "Subpoena seria num"
       ,subpoena_appearence_date_time: "Appearence date time"
       ,subpoena_military_office: "Military office"
       ,subpoena_military_office_address: "Military office address"
       ,subpoena_type: "Subpoena type"
       ,subpoena_reason: "Subpoena reason"
       
       ,hr_contact_full_name: "Contact full name"
       ,hr_contact_type: "Contact type"
       ,hr_contact_date_of_birth: "Contact date of birth"
       
       ,hr_qualification_name: "Education type"
       ,hr_qualification_establishment: "Educational institution"
       ,hr_qualification_type: "Qualification"
       ,hr_qualification_speciality: "Speciality"
       ,hr_qualification_doc_type: "Qualification document type"
       ,hr_qualification_doc: "Qualification document number"
       ,hr_qualification_awarded_date: "Qualification awarded date"
       ,hr_qualification_okso: "Specialty code"
       
       ,hr_military_doc_type: "Type of military registration document"
       ,hr_military_doc: "Series and number of the military registration document"
       ,hr_military_doc_date: "Date of issue"
       ,hr_military_issued_by: "Issued by"
       ,hr_military_worth_category: "Worth category"
       ,hr_military_rank: "Military rank"
       ,hr_military_reserve_category: "Reserve category"
       ,hr_military_staff: "Military staff"
       ,hr_military_speciality: "Military speciality"
       ,hr_military_office: "Military office at the place of military registration"
    }
};


export const emptyRecord = { ...captions[ "ru" ] };
for( const key in emptyRecord ) {
    emptyRecord[ key ] = '';
};


export function setHrPersonsFields(
    dispatch
   ,docState
   ,suffix = undefined
) {
    const tail = suffix === undefined || suffix === null ? '' : suffix;
    const listSuffix = "_list";
    
    let hr_login               = docState[ "hr_login"               + tail ];
    let hr_business_group_name = docState[ "hr_business_group_name" + tail ];
    let hr_assignment_number   = docState[ "hr_assignment_number"   + tail ];
    let hr_loan_number         = docState[ "hr_loan_number"         + tail ];
    let subpoena_receipt_date  = docState[ "subpoena_receipt_date"  + tail ];
    let hr_contact_type        = docState[ "hr_contact_type"        + tail ];
    let hr_contact_full_name   = docState[ "hr_contact_full_name"   + tail ];
    let hr_account_bank_name   = docState[ "hr_account_bank_name"   + tail ];
    let hr_account_bic         = docState[ "hr_account_bic"         + tail ];
    let hr_account_corr        = docState[ "hr_account_corr"        + tail ];
    let hr_account_bank        = docState[ "hr_account_bank"        + tail ];
    let hr_account_pers        = docState[ "hr_account_pers"        + tail ];
    let hr_qualification_name  = docState[ "hr_qualification_name"  + tail ];
    let hr_qualification_establishment = docState[ "hr_qualification_establishment" + tail ];
    let hr_qualification_doc   = docState[ "hr_qualification_doc"   + tail ];
    let hr_military_doc        = docState[ "hr_military_doc"        + tail ];

    let state = {};
    
    // изменилось поле Логин или Номер назначения в компоненте "HR Справочник cотрудников",
    // нужно установить зависимые поля - "ФИО", "Паспорт" и т.д. из справочника hrPersons

    const optionsList = dispatch( ( dispatch, getState ) => getState().doc.hrPersons );

    if( hr_login === undefined ) {
        if( !Array.isArray( optionsList ) || optionsList.length < 1 ) return;
        hr_login = optionsList[0].hr_login;
        dispatch( setDocStatePropValue( "hr_login",  hr_login ) );
        lastRequestParam = hr_login;
    }
    
    // отбор лица по логину
    let recordList = getOptionsListRecords( optionsList, "hr_login", hr_login );
    if( !Array.isArray( recordList ) ) return;
    
    if( hr_business_group_name === undefined && recordList.length > 0 )
        hr_business_group_name = recordList[0].hr_business_group_name;
    
    // Business groups
    const hr_business_group_name_list = recordList.map( i => i.hr_business_group_name );
    state[ "hr_business_group_name" + listSuffix + tail ] = hr_business_group_name_list;


    // если несколько записей по логину, делаем отбор по бизнес-группе
    if( recordList.length > 1 /*&& hr_business_group_name !== undefined && hr_business_group_name !== null && hr_business_group_name !== ''*/ )
        recordList = getOptionsListRecords( recordList, "hr_business_group_name", hr_business_group_name );

    if( !Array.isArray( recordList ) ) return;
    //TODO const record = recordList.length > 0 ? recordList[0] : {}; так вроде правильно, но не очищаются поля, если нажать крест на login select
    const record = recordList[0];


    // если assignmentRecord или loanRecord пустой, нужно очистить их поля,
    // поэтому сначала обнуляем все поля
    copyProps( state, emptyRecord, tail );

    // можно копировать не весь record, а только поля, нужные для заполнения шаблона, по списку captions
    copyProps( state, record, tail );
    
    
    // Assignments

    const hr_assignments = getOptionsRecordProp( record, "hr_assignments" );
    if( Array.isArray( hr_assignments ) ) {

        if( hr_assignment_number === undefined ) {
            hr_assignment_number = hr_assignments.length > 0 ? hr_assignments[0].hr_assignment_number : null;
        }
        // задвоение назначений не должно быть, но могло быть в процессе добавления БГ, если данные без БГ не удаляли
        const hr_assignment_number_list  = hr_assignments.length === 0 ? '' : makeUnique( hr_assignments.map( (item) => item.hr_assignment_number ) );
        const assignmentRecord = hr_assignments.length === 0 ? '' : hr_assignments.find( item => item[ "hr_assignment_number" ] === hr_assignment_number );
        
        copyProps( state, assignmentRecord, tail );
        state[ "hr_assignment_number" + listSuffix + tail ] = hr_assignment_number_list;
    }
    
    
    // Loans
    
    let hr_loans = getOptionsRecordProp( record, "hr_loans" );
    if( Array.isArray( hr_loans ) ) {
        hr_loans = hr_loans.filter( item => item[ "hr_assignment_number" ] === hr_assignment_number );
    
        if( hr_loan_number === undefined ) {
            hr_loan_number = hr_loans.length > 0 ? hr_loans[0].hr_loan_number : null;
        }

        const hr_loan_number_list   = hr_loans.length === 0 ? '' : hr_loans.map( (item) => item.hr_loan_number );
        const loanRecord = hr_loans.length === 0 ? '' : hr_loans.find( item => item[ "hr_loan_number" ] === hr_loan_number );

        copyProps( state, loanRecord, tail );
        state[ "hr_loan_number" + listSuffix + tail ] = hr_loan_number_list;
    }
    
    
    // Subpoenas

    const hr_subpoenas = getOptionsRecordProp( record, "hr_subpoenas" );
    if( Array.isArray( hr_subpoenas ) ) {

        if( subpoena_receipt_date === undefined ) {
            subpoena_receipt_date = hr_subpoenas.length > 0 ? hr_subpoenas[0].subpoena_receipt_date : null;
        }

        const subpoena_receipt_date_list = hr_subpoenas.length === 0 ? '' : hr_subpoenas.map( (item) => item.subpoena_receipt_date );
        const subpoenaRecord = hr_subpoenas.length === 0 ? '' : hr_subpoenas.find( item => item[ "subpoena_receipt_date" ] === subpoena_receipt_date );

        copyProps( state, subpoenaRecord, tail );
        state[ "subpoena_receipt_date" + listSuffix + tail ] = subpoena_receipt_date_list;
    }
    
    
    // Contacts

    const hr_contacts = getOptionsRecordProp( record, "hr_contacts" );
    if( Array.isArray( hr_contacts ) ) {

        let hr_contact_type_list = [];
        let hr_contact_full_name_list = [];
        let contactRecord = {};
        
        if( hr_contacts.length > 0 ) {
         
            if( hr_contact_type !== undefined ) {
                contactRecord = hr_contacts.find( item => item[ "hr_contact_type" ] === hr_contact_type );
            } else {
                if( hr_contact_full_name === undefined ) {
                    hr_contact_full_name = hr_contacts[0].hr_contact_full_name;
                }
                contactRecord = hr_contacts.find( item => item[ "hr_contact_full_name" ] === hr_contact_full_name );
            }

            hr_contact_type_list     = makeUnique( hr_contacts.map( item => item.hr_contact_type ) );
            hr_contact_full_name_list = hr_contacts.map( item => item.hr_contact_full_name );
        }
        
        copyProps( state, contactRecord, tail );
        state[ "hr_contact_type" + listSuffix + tail ] = hr_contact_type_list;
        state[ "hr_contact_full_name" + listSuffix + tail ] = hr_contact_full_name_list;
    }
    
    
    // Accounts

    let hr_accounts = getOptionsRecordProp( record, "hr_accounts" );
    if( Array.isArray( hr_accounts ) ) {
        hr_accounts = hr_accounts.filter( item => item[ "hr_assignment_number" ] === hr_assignment_number
                                               && item[ "hr_business_group_name" ] === hr_business_group_name );

        // Костыльный велосипед:
        // hr_accounts отсортированы по приоритету на бэке,
        // отобрать по порядку, суммируя percentage, пока не станет 100%,
        // лишние отбросить
        let percentage = 0.0;
        let hr_accounts_new = [];
        try {
            hr_accounts.forEach( item => {
                if( percentage >= 100 ) return;
                percentage += parseFloat( item.hr_account_percentage );
                hr_accounts_new.push( item );
            });
        } catch( ex ) {
            console.log( "Error in percentage cycle: " + ex );
            hr_accounts_new = hr_accounts;
        }
        hr_accounts = hr_accounts_new;
        
        
        let hr_account_bank_name_list = [];
        let hr_account_bic_list  = [];
        let hr_account_corr_list = [];
        let hr_account_bank_list = [];
        let hr_account_pers_list = [];
        let accountRecord = {};
        
        if( hr_accounts.length > 0 ) {
         
            if( hr_account_bank_name === undefined
             && hr_account_bic  === undefined
             && hr_account_corr === undefined
             && hr_account_bank === undefined
             && hr_account_pers === undefined
            ) {
                hr_account_bank_name = hr_accounts[0].hr_account_bank_name;
            }
            
            if( hr_account_bank_name !== undefined
             && hr_account_bic  === undefined
             && hr_account_corr === undefined
             && hr_account_bank === undefined
             && hr_account_pers === undefined
            ) {
                accountRecord = hr_accounts.find( item => item[ "hr_account_bank_name" ] === hr_account_bank_name );
            }
            else
            if( hr_account_bank_name === undefined
             && hr_account_bic  !== undefined
             && hr_account_corr === undefined
             && hr_account_bank === undefined
             && hr_account_pers === undefined
            ) {
                accountRecord = hr_accounts.find( item => item[ "hr_account_bic" ] === hr_account_bic );
            }
            else
            if( hr_account_bank_name === undefined
             && hr_account_bic  === undefined
             && hr_account_corr !== undefined
             && hr_account_bank === undefined
             && hr_account_pers === undefined
            ) {
                accountRecord = hr_accounts.find( item => item[ "hr_account_corr" ] === hr_account_corr );
            }
            else
            if( hr_account_bank_name === undefined
             && hr_account_bic  === undefined
             && hr_account_corr === undefined
             && hr_account_bank !== undefined
             && hr_account_pers === undefined
            ) {
                accountRecord = hr_accounts.find( item => item[ "hr_account_bank" ] === hr_account_bank );
            }
            else
            if( hr_account_bank_name === undefined
             && hr_account_bic  === undefined
             && hr_account_corr === undefined
             && hr_account_bank === undefined
             && hr_account_pers !== undefined
            ) {
                accountRecord = hr_accounts.find( item => item[ "hr_account_pers" ] === hr_account_pers );
            }
            else accountRecord = hr_accounts[0];
            
            hr_account_bank_name_list = makeUnique( hr_accounts.map( item => item.hr_account_bank_name ) );
            hr_account_bic_list  = makeUnique( hr_accounts.map( item => item.hr_account_bic ) );
            hr_account_corr_list = makeUnique( hr_accounts.map( item => item.hr_account_corr ) );
            hr_account_bank_list = makeUnique( hr_accounts.map( item => item.hr_account_bank ) );
            hr_account_pers_list = makeUnique( hr_accounts.map( item => item.hr_account_pers ) );
        }
        
        copyProps( state, accountRecord, tail );
        state[ "hr_account_bank_name" + listSuffix + tail ] = hr_account_bank_name_list;
        state[ "hr_account_bic"  + listSuffix + tail ] = hr_account_bic_list;
        state[ "hr_account_corr" + listSuffix + tail ] = hr_account_corr_list;
        state[ "hr_account_bank" + listSuffix + tail ] = hr_account_bank_list;
        state[ "hr_account_pers" + listSuffix + tail ] = hr_account_pers_list;
    }

    
    // Qualifications

    const hr_qualifications = getOptionsRecordProp( record, "hr_qualifications" );
    if( Array.isArray( hr_qualifications ) ) {

        let hr_qualification_name_list = [];
        let hr_qualification_establishment_list = [];
        let hr_qualification_doc_list = [];
        let qualificationsRecord = {};

        
        if( hr_qualifications.length > 0 ) {

            if( hr_qualification_name  === undefined
             && hr_qualification_establishment === undefined
             && hr_qualification_doc === undefined
            ) {
                hr_qualification_name = hr_qualifications[0].hr_qualification_name;
            }
            
            if( hr_qualification_name !== undefined
             && hr_qualification_establishment === undefined
             && hr_qualification_doc === undefined
            ) {
                qualificationsRecord = hr_qualifications.find( item => item[ "hr_qualification_name" ] === hr_qualification_name );
            }
            else
            if( hr_qualification_name  === undefined
             && hr_qualification_establishment !== undefined
             && hr_qualification_doc === undefined
            ) {
                qualificationsRecord = hr_qualifications.find( item => item[ "hr_qualification_establishment" ] === hr_qualification_establishment );
            }
            else
            if( hr_qualification_name  === undefined
             && hr_qualification_establishment === undefined
             && hr_qualification_doc !== undefined
            ) {
                qualificationsRecord = hr_qualifications.find( item => item[ "hr_qualification_doc" ] === hr_qualification_doc );
            }
            else qualificationsRecord = hr_qualifications[0];
            
            hr_qualification_name_list = makeUnique( hr_qualifications.map( item => item.hr_qualification_name ) );
            
            hr_qualification_name = qualificationsRecord ? qualificationsRecord[ "hr_qualification_name" ] : '';
            hr_qualification_establishment_list = makeUnique( hr_qualifications
                .filter( item => item.hr_qualification_name === hr_qualification_name
                    || hr_qualification_name === null || hr_qualification_name === undefined || hr_qualification_name === '' || hr_qualification_name === ' '
                )
                .map( item => item.hr_qualification_establishment ));
                
            hr_qualification_establishment = qualificationsRecord ? qualificationsRecord[ "hr_qualification_establishment" ] : '';
            hr_qualification_doc_list = makeUnique( hr_qualifications
                .filter( item => ( item.hr_qualification_name === hr_qualification_name
                    || hr_qualification_name === null || hr_qualification_name === undefined || hr_qualification_name === '' || hr_qualification_name === ' ' )
                 && ( item.hr_qualification_establishment === hr_qualification_establishment
                    || hr_qualification_establishment === null || hr_qualification_establishment === undefined || hr_qualification_establishment === '' || hr_qualification_establishment === ' ' )
                )
                .map( item => item.hr_qualification_doc ));
                
        }

        
        copyProps( state, qualificationsRecord, tail );
        state[ "hr_qualification_name" + listSuffix + tail ] = hr_qualification_name_list;
        state[ "hr_qualification_establishment" + listSuffix + tail ] = hr_qualification_establishment_list;
        state[ "hr_qualification_doc" + listSuffix + tail ] = hr_qualification_doc_list;
    }

    
    // Military

    const hr_military = getOptionsRecordProp( record, "hr_military" );
    if( Array.isArray( hr_military ) ) {

        let hr_military_doc_list = [];
        let militaryRecord = {};

        if( hr_military.length > 0 ) {
         
            if( hr_military_doc === undefined ) {
                hr_military_doc = hr_military[0].hr_military_doc;
            }

            hr_military_doc_list = hr_military.map( item => item.hr_military_doc );
            militaryRecord = hr_military.find( item => item[ "hr_military_doc" ] === hr_military_doc );

        }
        
        copyProps( state, militaryRecord, tail );
        state[ "hr_military_doc" + listSuffix + tail ] = hr_military_doc_list;
    }

    
    dateParts( state ); // добавить поля _dd, _mm, _yyyy к полям date
    dispatch( setDocStatePropMap( state ) );
}


// 1) выбрать несколько человек, заполнить выпадающий список
let cancelRequest;
let lastRequestParam;
export function getHrPersonsDB( dispatch, value/*, init = false*/ ) {
 
    value = ( value === undefined || value === null ) ? '' : value;
    /*if( !init )*/ if( value === '' || value === lastRequestParam ) return;
    lastRequestParam = value;
    
    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    get_common(
        APP_URL + '/api/hr/persons'
       ,dispatch
       ,caption.hrError + ': '
       ,data => {
            if( !Array.isArray( data ) ) return;
            dispatch( setPropValue( DOC, "hrPersons", data ) );
            // если это первая загрузка шаблона, заполнить поля формы данными HR для пользователя
            //if( init ) setHrPersonsFields( dispatch );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: cancelRequest.token, }
    );
}


// 2) загрузить всю информацию по одному лицу
export function getHrOnePersonDB( dispatch, value, limitToBusinessGroups, suffix = undefined ) {
 
    const tail = suffix === undefined || suffix === null ? '' : suffix;

    value = ( value === undefined || value === null ) ? '' : value;
    
    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    get_common(
        APP_URL + '/api/hr/person'
       ,dispatch
       ,caption.hrError + ': '
       ,data => {
            if( !Array.isArray( data ) ) return;
            
            // Только выбранные в Админке бизнес-группы (если пусто - без ограничения)
            if( Array.isArray( limitToBusinessGroups ) && limitToBusinessGroups.length > 0 )
                data = data.filter( i => limitToBusinessGroups.includes( i.hr_business_group_id ) );

            dispatch( setPropValue( DOC, "hrPersons", data ) );
            // заполнить поля формы данными HR для пользователя
            setHrPersonsFields(
                        dispatch
                       ,{ 
                          [ 'hr_login' + tail ]: value === '' ? undefined : value
                        }
                       ,suffix
                    );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: cancelRequest.token, }
    );
}
