import React from 'react';
import DeleteButton from "./DeleteButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ClearRounded from '@material-ui/icons/ClearRounded';
import {
    gLang
} from '../../../redux/actions/lang';


const captions = {
    "ru": {
        deleteAll: "Очистить таблицу"
    }
    ,"en": {
        deleteAll: "Clear table"
    }
};


let timer = null;

export default function ConfirmationPopup( props ) {
 
    const lang = gLang;
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    const [ anchorEl, setAnchorEl ] = React.useState( null );
    
    const handleClick = ( e ) => {
        setAnchorEl( e.currentTarget );
        setTimer();
    };

    const handleClose = () => {
        clearTimer();
        setAnchorEl( null );
    };

    const clearTimer = () => {
        if( timer !== null ) clearTimeout( timer );
        timer = null;
    };

    const setTimer = () => {
        clearTimer();
        timer = setTimeout( handleClose, 3000 );
    };

    const open = Boolean( anchorEl );

    const menu =
        <Menu
            id = "Menu"
            anchorEl = { anchorEl }
            keepMounted
            open = { open }
            onClose = { handleClose }
        >
            <MenuItem
                onClick = { () => { props.onClick(); handleClose(); } }
                onMouseOver = { clearTimer }
                onMouseOut = { setTimer }
                style = {{ fontSize: "small" }}
            >
                <ListItemIcon
                    style = {{ minWidth: "0px", color: "red" }}
                >
                    <ClearRounded fontSize = "small" />
                </ListItemIcon>
                &nbsp;
                { caption.deleteAll }
                &nbsp;
            </MenuItem>
         </Menu>;
      
      
    return (
      <>
        <DeleteButton
            caption = { caption.deleteAll }
            onDelete = { handleClick }
        />

        { menu }
        
      </>
    );
}
