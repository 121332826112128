import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    previewTemplate
   ,postPreview
} from '../redux/actions/preview';

// Document preview in iFrame -
// docx template is converted to html by Aspose,
// then tags are filled out by Nunjucks JS script loaded to no-cookies domain (we use cdoc-ext)
// because Nunjucks is using dangerous eval()

// CRA (Create React App) in development mode will load /preview/preview.html
// from cdoc\front\public\preview\preview.html, without backend and CSP

// Built front we copy to backend at cdoc\src\main\resources\public\preview\preview.html,
// then build cdoc and cdoc-ext

// Deployed application must redirect to cdoc-ext

// закрыть Preview:
// 1) по клику меню cdoc\front\src\containers\NavBar.js - onNavItemClick()
// 2) по переходу на другой шаблон cdoc\front\src\containers\DocForm.js - onDocChange(), onGroupChange()

// scroll в окне preview на последнее изменённое поле
// DocForm.js - onChange, onNameValueChange

// загрузка Subtemplate в поле шаблона
// DocForm.js - onChange

// типовое решение для установки высоты iframe по содержимому 100%
// cdoc\front\src\IframeHeight.js - следит за высотой содержимого, посылает сообщение из iframe
// cdoc\front\public\preview\embed.js - принимает сообщение, устанавливает высоту iframe в родительском блоке

/* Оптимизация - нет решения, черновик:
хотел сохранить iframe, чтобы не грузить Nunjucks каждый раз, и сократить время повторного открытия preview
1) сохранить iframe element - проблема, браузеры перезагружают iframe при перемещении по DOM
   It isn't possible to move an iframe from one place in the dom to another without it reloading.
   https://stackoverflow.com/questions/8318264/how-to-move-an-iframe-in-the-dom-without-losing-its-state
2) grab the contents of the iframe prior to moving it using contents()
   - <iframe src="/preview/preview.html">
   - save contents
   - <iframe src="" onLoad = { getHtml }>
   - set contents
   if( previewElement ) return;
   previewElement = getPreviewFrame();
   console.log( previewElement.contentWindow.document.body.innerHTML );
3) set iframe size to 1px and move it outside the screen -
   но iframe находится в layout, при изменении layout придётся переместить iframe внутри DOM
*/

let iframeTag = null;
let gPreviewContainer = null;

class Preview extends Component {

    constructor( props ) {
        super( props );
        if( iframeTag ) return;
        iframeTag =
            <iframe src="/preview/preview.html"
                height = "100%"
                width = "100%"
                frameBorder = "0"
                scrolling = "no"
                name = "xxcdocPreviewFrame"
                id = "xxcdocPreviewFrame"
                title = "xxcdocPreviewFrame"
                onLoad = { props.onLoad }
            ></iframe>;
    };

    componentDidMount() {
        gPreviewContainer = document.getElementById( "previewContainer" );
    }
    
    shouldComponentUpdate( nextProps, nextState ) {
        postPreview( '', nextProps.docState, gPreviewContainer.scrollTop, gPreviewContainer.offsetHeight );
        return true;
    };

    
    render() {
        return iframeTag;
    };
};

// компонент реагирует на изменение docState,
// и для первой отрисовки следит за spin запроса шаблона /doc/html,
// также нужно дождаться установки всех полей по умолчанию
// при загрузке шаблона и строк на сложных компонентах
const mapStateToProps = ({ doc, spin }) => {
    return {
        docState: doc.docStates[ doc.docId ]
       ,spin
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch
   ,onLoad: () => {
        previewTemplate( dispatch );
    }
});

export default connect( mapStateToProps, mapDispatchToProps )( Preview );
