import {
    // actions
    ADD
   ,DELETE
   ,DELETE_ALL
   ,SET_STATE_PROP_VALUE
    // reducer names
   ,TABLE
} from '../constants';
import {
    previewLastField
} from '../actions/preview';
import {
    setDocStatePropValue
} from '../actions/doc';


// вставка из Excel
// текст разделён \t на столбцы и \n на строки
export const onPaste = ( e, dispatch ) => {

    const pasteText = e.clipboardData.getData( 'text' );
    const target = e.target;
    let [ , name, row, col ] = target.name.match( /(.+)\$(\d+)\$(\d+)$/ ); // split text field name "table$0$0" into table name "table", row and column number
    row = Number( row );
    col = Number( col );

    const paste = pasteText.split( /\r?\n|\r/ ).map( i => i.split( /\t/ ) );

    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const docId = propsDoc.docId;
    const state = propsDoc.docStates[ docId ];
    let newVal = state[ name ];
    let rows = newVal.length;
    const cols = newVal[0].length;
    const pasteRows = paste.length;
    const pasteCols = paste[0].length;
    
    // есть неразрешимое противоречие -
    // текст без табуляций с переводами строк
    // можно вставить в ячейку целиком или разбить на строки,
    // я решил вставлять целиком
    if( pasteCols < 2 ) return; // уходим в обработчик вставки по умолчанию
    else paste.forEach( ( i, r ) => {
     
        // в конце стоит перевод строки, игнорируем
        if( r + 1 >= pasteRows && i.length === 1 && i[0] === '' ) return;

        // добавить строку, если не хватает
        if( r + row >= rows ) {
            const newRow = [ ...new Array( cols ) ]; // create row and initialize cells with undefined
            newVal.push( newRow );
            rows++;
        }
        
        // вставка значений
        i.forEach( ( j, c ) => {
            if( c + col >= cols ) return; // лишние колонки отбросить
            newVal[ r + row ][ c + col ] = j;
        });
    });
    
    e.preventDefault();
    dispatch( setDocStatePropValue( name, newVal ) );
};


// установить высоту всей строки таблицы по максимальной высоте multiline
export const setRowHeight = ( value, name ) => {
    setTimeout( () => setRowHeightHelper( value, name ) );
};

const setRowHeightHelper = ( value, name ) => {
    try {
     
        value.forEach( ( item, idx ) => {
            let field = document.getElementsByName( name + '$' + idx )[0];
            field.style.height = "auto";
        });
        
        let maxHeight = value.reduce( ( result, item, idx ) => {
            let field = document.getElementsByName( name + '$' + idx )[0];
            let height = field.scrollHeight;
            return result > height ? result : height;
        }, 0 );

        value.forEach( ( item, idx ) => {
            let field = document.getElementsByName( name + '$' + idx )[0];
            field.style.height = maxHeight + "px";
        });

    } catch( error ) {
    }
};


export const onChangeTable = ( e, dispatch, name, row, col ) => {
    const target = e.target;
    //const name = target.name;
    const type = target.type;
    const value = type === 'checkbox' ? target.checked :
                  target.value;

    dispatch( setDocStateTableValue( name, value, row, col ) );
};


const setDocStateTableValue = ( name, value, row, col ) => {
 
    // Preview, скролл на редактируемое поле
    previewLastField( name );
    
    return {
        type: TABLE + SET_STATE_PROP_VALUE
       ,payload: {
            name
           ,value
           ,row
           ,col
        }
    };
};


export const onAddRow = ( dispatch, name ) => {

    // Preview, скролл на редактируемое поле
    previewLastField( name );

    dispatch({
        type: TABLE + ADD
       ,payload: {
            name
        }
    });
};


export const onDeleteAll = ( dispatch, name ) => {

    // Preview, скролл на редактируемое поле
    previewLastField( name );

    dispatch({
        type: TABLE + DELETE_ALL
       ,payload: {
            name
        }
    });
};


export const onDeleteRow = ( dispatch, name, row ) => {

    // Preview, скролл на редактируемое поле
    previewLastField( name );

    dispatch({
        type: TABLE + DELETE
       ,payload: {
            name
           ,row
        }
    });
};


export const onOrderChange = ( dispatch, name, value ) => {

    dispatch( setDocStatePropValue( name, value ) );
};

