import {
    USER,
    SET_PROP_VALUE
} from '../constants';

export const initialState = {

    login: null
   ,roles: []   
   ,maxAccessType: 'USER' // максимальный уровень прав, определяет видимость Админки, USER/ADMIN
   ,isSuper: false // Суперпользователь
   ,isHrSuper: false // Суперпользователь HR, полный доступ к компоненту Сотрудник
   ,isInitiator: false // Инициатор
   ,isExternal: true // Внешний пользователь
   ,isIframe: false // Форма встроена в iframe, без шапки
   ,isPassiveIframe: false // iframe + запрос на скачивание документа отправляется родителю через postMessage, сам cdoc документ не скачивает
   ,extUserId: null // uuid из url, добавляется в каждый запрос от внешней формы, доступ проверяется по таблице xxya.cdoc_ext
};

export default (state = initialState, action) => {
    switch (action.type) {

        case USER + SET_PROP_VALUE:
            const name = action.payload.name
            const value = action.payload.value
            return {
                ...state,
                [name]: value
            }

        default:
            return state;
    }
}
