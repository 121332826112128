import {
   // actions
    SET_PROPS
   // reducer names
   ,EXT_EDITOR
} from '../constants'

export const close = () => (
    {
        type: EXT_EDITOR + SET_PROPS,
        payload: {
            value: { open: false }
        }
    }
);
