import React from 'react';

const HiddenInput = ( props ) => {
    return (
        <input
            type = "hidden"
            name = { props.name }
            value = { props.value === undefined || props.value === null ? '' : props.value }
        />
    )
};

export default HiddenInput;
