import React from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import App from './containers/App';

const useStyles = makeStyles({
  root: {
    top: "65px"
  }
});

export default function AppProvider() {
    const classes = useStyles();
    return (
        <SnackbarProvider
            anchorOrigin = {{
                vertical: 'top'
               ,horizontal: 'right'
            }}
            maxSnack = { 5 }
            autoHideDuration = { 3000 }
            preventDuplicate = { false }
            style = {{ whiteSpace: "pre-line" }}
            classes = {{
                containerRoot: classes.root
            }}
        >
            <App />
        </SnackbarProvider>
    );
}
