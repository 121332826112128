import React from 'react';
import { Bug } from "../Icon/Bug";
import styles from './DebugPanel.module.css';

const DebugPanel = ( props ) => {

    if( props.isExternal ) return '';
     
    return <div className = { styles.debugPanel } >
        <a className = { styles.listItem } href={ "https://forms.yandex-team.ru/surveys/85609" } target="_blank" rel="noreferrer noopener" >
            <Bug title="Bug"/>
        </a>
    </div>;
}

export default DebugPanel;
