import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import ClearRounded from '@material-ui/icons/ClearRounded';

let gEnqueueSnackbar = null;
let gCloseSnackbar = null;

export const initSnackbar = ( enqueueSnackbar, closeSnackbar ) => {
    gEnqueueSnackbar = enqueueSnackbar;
    gCloseSnackbar = closeSnackbar;
}

export const closeSnackbar = ( props ) => {
    if( gCloseSnackbar ) gCloseSnackbar( props );
}

export const successSnackbar = ( props ) => {
    if( gEnqueueSnackbar ) gEnqueueSnackbar( props, {
        variant: 'success'
    } );
    
    /*
    // UPD исправлено через containerRoot в AppProveder.js
    // не удавалось исправить глюк - snackbar загораживает кнопки сверху невидимым контейнером
    // радикальное решение
    const element = document.getElementById( "notistack-snackbar" );
    console.log( element );
    element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.top = "50px";
    */
}

export const errorSnackbar = ( props ) => {
    if( gEnqueueSnackbar ) gEnqueueSnackbar( props, {
        variant: 'error'
    } );
}

export const successSnackbarLong = ( props ) => {
    const action = key => (
        <IconButton
            color="inherit"
            onClick={ () => closeSnackbar(key) }
        >
            <ClearRounded />
        </IconButton>
    );
    
    if( gEnqueueSnackbar ) gEnqueueSnackbar( props, {
        variant: 'success'
       ,autoHideDuration: 20000
       ,action
    } );
}

export const errorSnackbarLong = ( props ) => {
    const action = key => (
        <IconButton
            color="inherit"
            onClick={ () => closeSnackbar(key) }
        >
            <ClearRounded />
        </IconButton>
    );
    
    if( gEnqueueSnackbar ) gEnqueueSnackbar( props, {
        variant: 'error'
       ,autoHideDuration: 20000
       ,action
    } );
}

