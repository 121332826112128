import React from 'react'
import styles from "./Form.module.css"
import Button from '@material-ui/core/Button';
import { TextValidator } from 'react-material-ui-form-validator';
import {
    GRAY
   ,HIDE
} from "../../redux/constants";

const captions = {
    "ru": {
        classHelperText: "Содержимое файла будет вставлено в документ."
                       /*"Создайте файл в программе Microsoft Word, вставьте в него картинку, таблицу или текст,"
                       + " сохраните в формате DOCX (или RTF, если шаблон RTF)."
                       + ' Нажмите кнопку "Выбрать файл" и выберите ваш файл.'*/
       ,selectFile: "Выбрать файл"
    }
    ,"en": {
        classHelperText: "File contents will be inserted into the document."
                       /*"Create a file in Microsoft Word, insert a picture, table or text into it, save in DOCX format"
                       + " (or RTF, for the RTF template)."
                       + ' Click the "Select file" button and select your file.'*/
       ,selectFile: "Select file"
    }
};

// локальный file object нельзя присвоить file input
// из соображений безопасности, чтобы нельзя было программно открыть произвольный файл с диска,
// поэтому при переходе между формами открытые файлы теряются

const Subtemplate = ( props ) => {
 
    const name = props.name;
    
    // если поле скрыто, нужно оставить скрытый тег file,
    // чтобы Publisher не ругался, что вложенный шаблон не определён
    const emptyFile = <input type="file" style={{ display: 'none' }} name={ name }/>;
    
    if( !props.visible && props.visible !== undefined ) return emptyFile;
    const file = props.value;
    let value = file === undefined || file === null ? '' : file.name;
    value = value === undefined || value === null ? '' : value;

    // protect для внешней формы
    const hidden = props.isExternal && props.protect === HIDE;
    if( hidden ) return emptyFile;

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    
    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];
    
    return (
        <div className='padding'>
        <div style={{ display: "flex", flexDirection: "row" }}>
            <TextValidator
                className={ props.className }
                label={ props.label }
                name={ name }
                value={ value }
                InputLabelProps={{ shrink: !!value }} // поднять label над полем, иначе может наехать на значение
                onChange={ f=>f }
                validators={ props.validators }
                errorMessages={ props.errorMessages }
                autoFocus={ props.autoFocus }
                InputProps={{
                    readOnly: readonly
                   ,style: props.bold ? {fontWeight: 550} : {}
                   ,className: readonly ? 'Mui-disabled' : undefined
                }}
                // disabled={ readonly } // disabled поля не попадают в submit form
                helperText={ props.helperText }
                error={ props.error }
            />
            <Button variant="contained" color="primary" component="label" style={{ margin: '10px 0px 0px 10px', minWidth: '140px', maxHeight: '36px' }}>
                { caption.selectFile }
                <input
                  type="file"
                  accept=".docx,.doc,.rtf,.xlsx,.xls,.png,.jpg,.jpeg" // pdf плохо прикладывается, не указываем; TODO rtf или docx в зависимости от формата шаблона
                  onChange={ props.onChange }
                  style={{ display: 'none' }}
                  name={ name }
                  // value={ file }
                  // нельзя подставить локальный файл программно в file input,
                  // но можно потом подставить file object из state в formData, см. DocForm.js onFormSubmit()
                />
            </Button>
        </div>
        <div className={ styles.xxHelperText } >
            { caption.classHelperText }
        </div>
        </div>
    );
}

export default Subtemplate
