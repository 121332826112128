import {
    SET_NAVBAR_ACTIVE
} from '../constants';

export const setNavBarActive = value => (
    {
        type: SET_NAVBAR_ACTIVE,
        value
    }
);
