import React from 'react'
import {
    transformMarkdown
} from "../../redux/actions/text";
import {
    GRAY
   ,HIDE
} from "../../redux/constants";


const Text = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;
 
    // protect для внешней формы
    if( props.isExternal && props.protect === HIDE ) return;

    const value = props.value === undefined || props.value === null ? '' : props.value;

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const colorStyle = readonly ? { color: 'rgba(0, 0, 0, 0.38)' } : {};
    
    const label = props.label;
    const content = transformMarkdown( label );
  
    return (
        <div className={ 'padding ' + props.className } style={ colorStyle } >
        
        { content }
        
        { ( label ? ' ' : '' ) + ( value ) }
        
        </div>
    );
}

export default Text
