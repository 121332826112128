// import copy from '../../assets/copy.svg';

// анимация копирования поля - иконка копирования улетает в шапку

export function transition( e, next ) {
    
    const coords = e.target.getBoundingClientRect();
    
    // создание svg в коде через path
    const xmlns = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS( xmlns, "svg" );

    // работает, но в начале анимации width/height теряются на миг, и картинка расползается на весь экран,
    // заменил на svg.style.width/height
    //svg.setAttribute( "style", "width:35px; height:35px;" );
    
    svg.setAttribute( "viewBox", "0 0 24 24" );
    
    const path = document.createElementNS( xmlns, "path" );
    path.setAttribute( "d", "M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z" );
    path.setAttribute( "fill", "#d8d8d8" );
    svg.appendChild( path );

    svg.style.position = "absolute";
    svg.style.top = coords.top + window.pageYOffset;
    svg.style.left = coords.left + window.pageXOffset;
    svg.style.zIndex = 9000;
    svg.style.width = '35px';
    svg.style.height = '35px';
    
    svg.style.transitionTimingFunction = "ease-in-out";
    svg.style.transitionProperty = "left, top";
    svg.style.transitionDuration = ".8s, .8s";
  
    document.body.appendChild( svg );
    
    setTimeout( start, 0, svg, next );
}


function start( svg, next ) {
 
    const elt = document.getElementById( "copiedFields" );
    const coords = elt.getBoundingClientRect();
    
    svg.style.top = coords.top + window.pageYOffset;
    svg.style.left = coords.left + window.pageXOffset;
    
    svg.addEventListener('transitionend', f => finish( svg, next ));
}


function finish( svg, next ) {
    try{
        document.body.removeChild( svg );
        next();
    }
    catch( err ) {}
}
