import React from 'react'
import * as moment from "moment"
import './DocForm.css'
import styles from "./Form.module.css"
import { ValidatorForm } from 'react-material-ui-form-validator';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Send from '@material-ui/icons/Send';
import ClearRounded from '@material-ui/icons/ClearRounded';
import RedoRounded from '@material-ui/icons/RedoRounded';
import RoleList from '../RoleForm/RoleList';
import HiddenInput from "../DocParts/HiddenInput";
import Lang from "../DocParts/Lang";
import {
    getUserDocSelect
   ,getUserGroupSelect
   ,urlCopy
} from '../../redux/actions/admin'
import {
    caption
} from '../../redux/actions/lang';


const DocFormContent = ( props ) => {
    
    const docId = props.doc.docId;
    const isIframe = props.user && props.user.isIframe;
    const isExternal = props.user && props.user.isExternal;

    const date = moment( new Date() ).format('YYYYMMDD');
    
    const GroupSelect = getUserGroupSelect( props );
    const [ DocSelect, docName, docCode ] = getUserDocSelect( props );
    
    /* для компоненты "Интеграция с Трекером"
    // TODO удалить, поле ya_system_name добавлено в компоненту "Компания группы Яндекс"
    const docState = !docId || !props.doc.docStates ? null : props.doc.docStates[ docId ];
    const systemName = !docState ? '' : docState.systemName;*/
    
    // объединить список админов шаблона и суперпользователей без повторов
    let ownerList = [];
    if( !props.DynForm ) {
        
        let adminList = !props.role.templateAdminList || !docId  || !props.role.templateAdminList[ docId ] ? [] :
            [ ...props.role.templateAdminList[ docId ] ];
        ownerList = [ ...adminList ];
        props.doc.superuserList.forEach( i => {
            if( adminList.findIndex( k => k.userId === i.userId && k.userType === i.userType ) === -1 ) ownerList.push( i );
        } );
    }
    
    return (
        
        props.doc.expiredUrl ?
            <div style={{ padding: '9px', textAlign: 'center' }}>
            { caption.expiredUrl }
            </div>
        :
        <>
        { !docId ? '' : <>
        
            { props.user.login === null || isExternal || isIframe || !document.queryCommandSupported('copy') ||
            <Tooltip title = { caption.copyURL } arrow>
            <IconButton
                color="primary"
                style={{ position: "absolute", top: 110, right: -4 }}
                onClick={ urlCopy }
            >
                <Send fontSize="default" className={ styles.xxIcon } />
            </IconButton>
            </Tooltip>
            }
            
            { isIframe &&
            <Lang
                lang = { props.lang }
                dispatch = { props.dispatch }
                site = "doc"
            />
            }

            { /* PreviewIcon раньше был здесь, а сейчас отображается компонентом Preview */
            /*isExternal ||*//* isIframe || format !== "docx" ||
            <PreviewIcon {...props} />
            */}
            
            <Tooltip title = { caption.redoFillOut } arrow>
            <IconButton
                color="primary"
                style={{ position: "absolute", top: -10, right: 50 }}
                onClick={ () => props.onFormRedo( docId ) }
            >
                <RedoRounded fontSize="large" className={ styles.xxIcon } />
            </IconButton>
            </Tooltip>

            <Tooltip title = { caption.clearForm } arrow>
            <IconButton
                color="primary"
                style={{ position: "absolute", top: -10, right: -10 }}
                onClick={ props.onFormClear }
            >
                <ClearRounded fontSize="large" className={ styles.xxIcon } />
            </IconButton>
            </Tooltip>
            
          </>
        }
        
        
        <ValidatorForm
            id="docForm"
            onKeyPress={ props.onFormKeyPress }
            onSubmit={ props.onFormSubmit }
            onError={ props.onFormError }
        >

        {/* если сделать padding или row-gap здесь, то скрытые поля будут с отступами - лишние отступы на форме,
            поэтому padding находится внутри каждого компонента */}
        <div className={ styles.xxFormContent }>

            { GroupSelect }
            { DocSelect }
            
            {/* docCode передаётся в backend через скрытое поле на форме */}
            {/* TODO сделать хитрый тег, чтобы не было конфликтов? */}
            <HiddenInput name="docName" value = { docName } />
            <HiddenInput name="docCode" value = { docCode } />
            <HiddenInput name="fileName" value = { ( docCode === undefined || docCode === null || docCode === '' ? docName : docCode ) + "_" + date } />
            
            {/* TODO удалить, поле ya_system_name добавлено в компоненту "Компания группы Яндекс" */}
            {/*<HiddenInput name="systemName" value = { systemName } />*/}
            
            { /* поля формы */
              props.DynForm ? props.DynForm.map( (item, key) => <div key={key} className='table__row' >{ item }</div> )
                  : props.spin.isLoading || !docId ? ''
                  :
                  <>
                  <div style={{ padding: '9px'}}>
                  { caption.formAccess }
                  </div>
                  
                  <RoleList
                      list = { ownerList }
                  />
                  </>
            }
    
        </div>

        </ValidatorForm>
        </>

    )
}

export default DocFormContent
