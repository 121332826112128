import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import HiddenInput from "./HiddenInput";

// UUID обновляется при отправке формы onFormSubmit,
// чтобы при каждом заполнении формы было новое значение,

// UUID удаляются из predefined состояния внешней формы
// и сохранённого состояния формы в localStorage

// UUID должен быть доступен для preview на фронте,
// поэтому заполняется на фронте,
// для масс-загрузки и ручек API /doc, /ticket - заполняется в бекенде FillBack.java

const Uuid = ( props ) => {

    const name = props.name;
    let value = props.state[ name ];
    if( value === undefined ) {
        value = uuidv4();
        props.onNameValueChange( name, value );
    }
    
    return (
        <HiddenInput
            name = { name }
            value = { value }
        />
    );
}

export default Uuid

