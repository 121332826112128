import axios from 'axios';
import {
// actions
    ADD
   ,DELETE
   ,SET_ID
// reducer names
   ,ROLE
// properties
   ,APP_URL
   //,STAFF_ALL_LIST_ITEM
} from '../constants';
import {
    get_common
   ,post_common
   ,addListValues
   ,setListById
   ,setPropValue
} from '../actions/utils';
import {
    caption
   ,gLang
} from './lang';
//import { suggestMock } from './suggestMock';


export const addRole = ( id, name, value ) => ({
    type: ROLE + ADD
   ,payload: {
        id
       ,name
       ,value
    }
});

export const deleteRole = ( id, name, roleId ) => ({
    type: ROLE + DELETE
   ,payload: {
        id
       ,name
       ,roleId
    }
});

export const setRoleId = ( id, name, oldId, newId ) => ({
    type: ROLE + SET_ID
   ,payload: {
        id
       ,name
       ,oldId
       ,newId
    }
});


/*
Раньше было три ручки к StaffAPI
/staff/people - по данным из OEBS, Лица, только по-русски, работает быстро, но последние данные поставляются ночью
/staff/persons - лица из StaffAPI
/staff/groups - подразделения из StaffAPI
StaffAPI работает медленно, поэтому перешли на Suggest

https://search.yandex-team.ru/suggest
https://wiki.yandex-team.ru/intranetpoisk/suggest/api/


Suggest не доступен с localhost, возвращает ошибку CORS - Cross-origin resource sharing
https://habr.com/ru/companies/otus/articles/706908/
флаг --disable-web-security в Яндекс Браузере не работает,
axios withCredentials: true и headers: { origin: "https://cdoc.yandex-team.ru" } тоже не сработали,
можно попробовать proxy (local-cors-proxy) или mock

suggest возвращает один язык,
в момент добавления роли /add нужно запросить второй язык, и сохранить в нашей таблице xxya.cdoc_roles
*/

const headers = {
    'Content-Type': 'application/json' // допустимые типы для проверки CORS
   ,Accept: 'application/json'
   ,"x-csrf-token": undefined // иначе не отправляются cookies
   // также нужно поднять версию "axios": "^1.5.1",
   // а ещё новый axios требует "typescript": "^4.9.5"
};

let staffCancelRequest;
let staffLastParam;
export function suggestStaff( dispatch, listName, value ) {

    const language = gLang === "ru" ? "ru" : "en";
    
    value = ( value === undefined || value === null ) ? '' : value;
    value = value.replace(/\r?\n|\r/gm, ""); // чистим перевод строки
    //value = value.replace(/[^\x20-\x7E]/gmi, ""); // или так
    
    if( value === '' || value === staffLastParam ) return;
    if( !/\S/.test( value ) ) return; // если в строке только пробелы
    staffLastParam = value;
    
    if( staffCancelRequest ) {
        staffCancelRequest.cancel();
    }
    staffCancelRequest = axios.CancelToken.source();


    var counter = 0;

    const clearList = () => {
        if( ++counter === 1 ) {
            // пришла первая порция данных, нужно убрать старые из списка, оставить только "Все"
            // Админы шаблона = Все - убрали, лишняя возможность
            dispatch( setPropValue( ROLE, listName, listName === "staffTemplateAdminList" ? [] : [ caption.staffAllListItem ] ) );
        }
    }
    
    // простой mock на время разработки
    //const data = suggestMock;

    // а так реализован mock в Портале
    /*if (process.env.NODE_ENV === 'development' && !!process.env.FAST_REFRESH) {
        return suggestMock;
    }*/
    

    // Список лиц по частичному совпадению с value
    // Пример https://search.yandex-team.ru/suggest?version=2&language=en&layers=people&people.query=i_is_dismissed:0&people.per_page=20&text=copoka
    const paramsPeople = {
        version: 2
       ,language
       ,layers: "people"
       ,"people.query": "i_is_dismissed:0" // исключены бывшие сотрудники
       ,"people.per_page": 20
       //,"people.page": 0 // первая страница
       ,text: value
    };
    
    
    get_common(
        "https://search.yandex-team.ru/suggest"
       ,dispatch
       ,caption.empListError + ': '
       ,data => {
            
            if( !data || !data.people || !Array.isArray( data.people.result ) ) return;
            const list = data.people.result.map( i => ({
                id:   i.login
               ,name: i.title + ", " + i.login
               ,type: "PERSON"
               ,userName: language === "ru" ? i.title : null
               ,userNameEn: language === "en" ? i.title : null
               ,language // запомнить, какой язык был выбран, чтобы в момент добавления роли выбрать и на втором языке тоже
            }));
            
            clearList();
            dispatch( addListValues( ROLE, listName, list ) );
        }
       ,f=>f
       ,null
       ,{ params: paramsPeople, headers, cancelToken: staffCancelRequest.token, withCredentials: true }
    );

    
    // Список подразделений по частичному совпадению с value
    // Пример https://search.yandex-team.ru/suggest/?version=2&language=en&layers=groups&groups.query=s_type:department&groups.per_page=20&text=yandex
    const paramsGroups = {
        version: 2
       ,language
       ,layers: "groups"
       ,"groups.query": "s_type:department"
       ,"groups.per_page": 20
       ,text: value
    };
    

    get_common(
        "https://search.yandex-team.ru/suggest"
       ,dispatch
       ,caption.depListError + ': '
       ,data => {
            
            if( !data || !data.groups || !Array.isArray( data.groups.result ) ) return;
            const list = data.groups.result.map( i => ({
                id:   i.department
               ,name: i.title
               ,type: "GROUP"
               ,userName: language === "ru" ? i.title : null
               ,userNameEn: language === "en" ? i.title : null
               ,language // запомнить, на каком языке выбран suggest, чтобы в момент добавления роли выбрать и на втором языке тоже
            }));
            
            clearList();
            dispatch( addListValues( ROLE, listName, list ) );
        }
       ,f=>f
       ,null
       ,{ params: paramsGroups, headers, cancelToken: staffCancelRequest.token, withCredentials: true }
    );

}



export function getStaff( dispatch, listName, value ) {
 
    suggestStaff( dispatch, listName, value );
    return;

/* OLD VERSION, обращение к Staff API

    value = ( value === undefined || value === null ) ? '' : value;
    value = value.replace(/\r?\n|\r/gm, ""); // чистим перевод строки
    //value = value.replace(/[^\x20-\x7E]/gmi, ""); // или так
    
    if( value === '' || value === staffLastParam ) return;
    staffLastParam = value;
    
    if( staffCancelRequest ) {
        staffCancelRequest.cancel();
    }
    staffCancelRequest = axios.CancelToken.source();

    
    var counter = 0;

    const clearList = () => {
        if( ++counter === 1 ) {
            // пришла первая порция данных, нужно убрать старые из списка, оставить только "Все"
            // Админы шаблона = Все - убрали, лишняя возможность
            dispatch( setPropValue( ROLE, listName, listName === "staffTemplateAdminList" ? [] : [ STAFF_ALL_LIST_ITEM ] ) );
        }
    }

    get_common(
        APP_URL + '/api/staff/people' // per_all_people_f - быстро, но не все
       ,dispatch
       ,caption.empListError + ': '
       ,data => {
            
            if( !data ) return;
            const list = data.map( i => {
                const name = gLang === 'en' && i.name_en !== undefined && i.name_en !== null && i.name_en !== '' ? i.name_en : i.name;
                return {
                    id:   i.login
                   ,name: name + ", " + i.login
                   ,type: "PERSON"
                   ,userName: i.name
                   ,userNameEn: i.name_en
                };
            });
            
            clearList();
            dispatch( addListValues( ROLE, listName, list ) );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: staffCancelRequest.token }
    );
    
    get_common(
        APP_URL + '/api/staff/persons' // StaffAPI - все, но медленно
       ,dispatch
       ,caption.empListError + ': '
       ,data => {
            
            // ответ StaffAPI прошёл через JSON parser
            if( !data ) return;
            const list = data.map( i => {
                const name = gLang === 'en' && i.name_en !== undefined && i.name_en !== null && i.name_en !== '' ? i.name_en : i.name;
                return {
                    id:   i.login
                   ,name: name + ", " + i.login
                   ,type: "PERSON"
                   ,userName: i.name
                   ,userNameEn: i.name_en
                };
            });
            
            clearList();
            dispatch( addListValues( ROLE, listName, list ) );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: staffCancelRequest.token }
    );
    
    get_common(
        APP_URL + '/api/staff/groups'
       ,dispatch
       ,caption.depListError + ': '
       ,data => {
            if( !data.result ) return;
            const list = data.result.map( i => {
                let nameEn = '';
                try { nameEn = i.department.name.full.en; } catch( ex ) {};
                const name = gLang === 'en' && nameEn !== undefined && nameEn !== null && nameEn !== '' ? nameEn : i.name;
                return {
                    id:   i.url
                   ,name: name // + ", " + i.url
                   ,type: "GROUP"
                   ,userName: i.name
                   ,userNameEn: nameEn
                };
            });
            clearList();
            dispatch( addListValues( ROLE, listName, list ) );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: staffCancelRequest.token }
    );
*/
}


// Одно лицо по логину
function suggestPerson( dispatch, language, role, func ) {

    // Пример https://search.yandex-team.ru/suggest?version=2&language=en&layers=people&people.query=i_is_dismissed:0+%26+s_login:copoka&people.per_page=10&text=copoka

    const value = role.userId;
    
    const paramsPeople = {
        version: 2
       ,language
       ,layers: "people"
       ,"people.query": "i_is_dismissed:0 & s_login:" + value // точное совпадение
       ,"people.per_page": 1
       ,text: value // указываем, потому что это обязательный параметр
    };
    
    
    get_common(
        "https://search.yandex-team.ru/suggest"
       ,dispatch
       ,caption.empListError + ': '
       ,data => {
            
            if( data && data.people && Array.isArray( data.people.result ) && data.people.result.length === 1 ) {

                const name = data.people.result[0].title;
                
                if( language === "ru" )
                    role.userName = name;
                else
                    role.userNameEn = name;
            }
            
            func( role );
        }
       ,f=>f
       ,null
       ,{ params: paramsPeople, headers, withCredentials: true }
    );
    
}


// Одно подразделение по id
function suggestGroup( dispatch, language, role, func ) {

    // Пример https://search.yandex-team.ru/suggest?version=2&language=en&layers=groups&groups.query=s_type:department+%26+s_url:yandex&groups.per_page=10&text=yandex

    const value = role.userId;
    
    const paramsGroups = {
        version: 2
       ,language
       ,layers: "groups"
       ,"groups.query": "s_type:department & s_url:" + value // точное совпадение
       ,"groups.per_page": 1
       ,text: value // указываем, потому что это обязательный параметр
    };
    
    
    get_common(
        "https://search.yandex-team.ru/suggest"
       ,dispatch
       ,caption.depListError + ': '
       ,data => {
            
            if( data && data.groups && Array.isArray( data.groups.result ) && data.groups.result.length === 1 ) {

                const name = data.groups.result[0].title;
                
                if( language === "ru" )
                    role.userName = name;
                else
                    role.userNameEn = name;
            }
            
            func( role );
        }
       ,f=>f
       ,null
       ,{ params: paramsGroups, headers, withCredentials: true }
    );

}


export function addRoleDB( dispatch, id, listName, role ) {
 
    if( [ "PERSON", "GROUP" ].includes( role.userType ) ) {
     
        // suggest возвращает данные на одном языке ru/en,
        // перед сохранением роли нужно прочитать на втором языке
        const language = role.language === "ru" ? "en" : "ru";
        let func = f=>f;
        
        if( role.userType === "PERSON" )
            func = suggestPerson;
        if( role.userType === "GROUP" )
            func = suggestGroup;

        func( dispatch, language, role, (role) => addRoleDBPrivate( dispatch, id, listName, role ) );
        
    } else // другой тип, например, ALL
        addRoleDBPrivate( dispatch, id, listName, role );
}


function addRoleDBPrivate( dispatch, id, listName, role ) {
    
    const formData = new FormData();
    formData.append('itemType',   role.itemType);
    formData.append('itemId',     role.itemId);
    formData.append('accessType', role.accessType);
    formData.append('userType',   role.userType);
    formData.append('userId',     role.userId);
    if( role.userName !== undefined && role.userName !== null && role.userName !== '' )
        formData.append('userName',   role.userName);
    if( role.userNameEn !== undefined && role.userNameEn !== null && role.userNameEn !== '' )
        formData.append('userNameEn', role.userNameEn);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/role/add'
       ,formData
       ,config
       ,dispatch
       ,caption.addRoleError + ': '
       ,data => {
            const roleId = data;
            if( !Number.isInteger( roleId ) ) return;
            dispatch( setRoleId( id, listName, role.roleId, roleId ) ); // заменить временный id на постоянный из базы
        }
    );
}


export function deleteRoleDB( dispatch, id, name, roleId, itemType, itemId ) {

    if( !Number.isInteger( roleId ) ) return;
    // не удаляю, пока роль добавляется в базу
    if( roleId < 1 ) { /* dispatch( deleteRole( id, name, roleId ) ); */ return; }
    const formData = new FormData();
    formData.append('roleId',   roleId);
    formData.append('itemType', itemType);
    formData.append('itemId',   itemId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/role/delete'
       ,formData
       ,config
       ,dispatch
       ,caption.delRoleError + ': '
       ,data => {
            if( data !== 'OK' ) return;
            dispatch( deleteRole( id, name, roleId ) );
        }
    );
    
}

export function loadRoleListDB( dispatch, listName, id, itemType, accessType ) {

    const config = {
        params: {
            itemType
           ,itemId: id
           ,accessType
           ,userType: ''
           ,userId: ''
        }
    }

    get_common(
        APP_URL + '/api/role'
       ,dispatch
       ,caption.roleListError + ': '
       ,data => { dispatch( setListById( ROLE, listName, id, data ) ); }
       ,f=>f
       ,null
       ,config
    );
}
