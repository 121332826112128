import React from 'react';
import styles from "./Form.module.css";

import IconButton from '@material-ui/core/IconButton';
import DragIndicator from '@material-ui/icons/DragIndicator';
import ClearRounded from '@material-ui/icons/ClearRounded';
import { TextValidator } from 'react-material-ui-form-validator';
import { SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import BankIcon from "@material-ui/icons/AccountBalance";
import Tooltip from "@material-ui/core/Tooltip";
import {
    isLangEn
   ,caption
} from '../../redux/actions/lang';

const ListItem = ( props ) => {
 
    const list = props.list; // уже выбранные значения (list) удаляем из общего списка (optionsList)
    // пока только для простых списков строк
    const selectList = Array.isArray( props.optionsList )
        ? props.optionsList.filter( i => i.id !== undefined || i === props.item || !list.includes( i ) )
        : props.optionsList;

    return (
        <span style={{ display: "flex", alignItems: "center", marginRight: "-10px" }} >

        <Tooltip title={ caption.move } arrow>
        <DragIndicator
            fontSize="default"
            className={ styles.xxAdd }
        />
        </Tooltip>

        {
            Array.isArray( selectList ) ?
                <SelectValidator
                    style={{ margin: "3px" }}
                    className={ styles.xxTextField }
                    name={ String( props.key ) }
                    value={ props.item }
                    onChange={ (e) => props.onChange( e ) } // не исправлять на { props.onChange }, нам нужен строго один параметр!
                    size="small"
                    variant="outlined"
                >
                {
                    selectList &&
                    selectList.map( ( item, i ) => (
                        item && item.subheader ?
                        <optgroup // заголовок группы
                            key={i}
                            value={ item.id }
                            label={ item.name }
                            style={{ textAlign: "center" }}
                        />
                        :
                        //item !== undefined ?
                        <MenuItem
                            key={i}
                            value={ item === undefined || item.id === undefined ? item : item.id }
                        >
                            { item === undefined || item.name === undefined ? item : item.name }
                        </MenuItem>
                        //: ''
                    ))
                }
                </SelectValidator>
            :
                <>
                <TextValidator
                    multiline
                    style={{ margin: "3px" }}
                    className={ styles.xxTextField }
                    name={ String( props.key ) }
                    value={ props.item }
                    onChange={ props.onChange }
                    size="small"
                    variant="outlined"
                    placeholder=""
                />
                { !isLangEn ? '' :
                <TextValidator
                    multiline
                    style={{ margin: "3px" }}
                    className={ styles.xxTextField }
                    name={ String( props.key ) }
                    value={ props.item_en }
                    onChange={ (e) => props.onChange( e, "en" ) }
                    size="small"
                    variant="outlined"
                    placeholder="[En]"
                />
                }
                </>
        }

        <Tooltip title={ caption.delete } arrow>
        <IconButton
            color="primary"
            onClick={ props.onDelete }
        >
            <ClearRounded fontSize="default" className={ styles.xxDelete } />
        </IconButton>
        </Tooltip>

        {
            props.bankDetails ?
                <Tooltip title={ caption.bankDetails } arrow>
                <IconButton
                    color="primary"
                    disabled={ !props.item }
                    onClick={ props.onBankDetailsList }
                >
                    <BankIcon fontSize="default" className={ styles.xxDelete }/>
                </IconButton>
                </Tooltip>:
                <></>
        }
        
        </span>
    )
};

export default ListItem;
