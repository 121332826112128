import {
// actions
    ADD
   ,DELETE
   ,EDIT
   ,SET_ID
   ,SET_PROPS
   ,SET_PROP_VALUE
// reducer names
   ,GROUP
   ,GROUP_EDITOR
} from './../constants'

// groupAdminList - список групп шаблонов с правами админа
// по идее ускоряет интерфейс, но здесь приходится дублировать действия над groupNames

export default (state, action) => {
    switch (action.type) {

        case GROUP + ADD:
            const tmpIdSequence = --state.tmpIdSequence
            return {
                ...state
               ,tmpIdSequence
               ,groupId: tmpIdSequence
               ,docId: ''
               ,groupNames: [
                    ...state.groupNames
                   ,{
                        id: tmpIdSequence // отрицательные id используются, пока база не даст новый номер из последовательности
                       ,name: action.payload.name
                       ,access_type: 'ADMIN'
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
                // повторить для groupAdminList
               ,groupAdminList: [
                    ...state.groupAdminList
                   ,{
                        id: tmpIdSequence
                       ,name: action.payload.name
                       ,access_type: 'ADMIN'
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
            }
            
        case GROUP + EDIT:
            const editGroupId = action.payload.id;
            return {
                ...state
               ,groupId: editGroupId
               ,groupNames: [
                    ...state.groupNames.filter( i => i.id !== editGroupId )
                   ,{
                        id: editGroupId
                       ,name: action.payload.name
                       ,access_type: 'ADMIN'
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
               ,groupAdminList: [
                    ...state.groupAdminList.filter( i => i.id !== editGroupId )
                   ,{
                        id: editGroupId
                       ,name: action.payload.name
                       ,access_type: 'ADMIN'
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
            }
            
        case GROUP + DELETE:
            const groupNames = state.groupNames.filter( i => state.groupId !== i.id );
            const groupAdminList = state.groupAdminList.filter( i => state.groupId !== i.id );
            return {
                ...state
               ,groupId: groupAdminList.length ? groupAdminList[0].id : groupNames.length ? groupNames[0].id : ''
               ,groupNames
               ,groupAdminList
            }
            
        // когда пришёл groupId из базы,
        // заменить временный id
        case GROUP + SET_ID:
            const oldId = state.groupId
            const newId = action.payload.id
            return {
                ...state
               ,groupId: newId
               ,groupNames: state.groupNames.map( i => i.id === oldId ? { ...i, id: newId } : i )
               ,groupAdminList: state.groupAdminList.map( i => i.id === oldId ? { ...i, id: newId } : i )
            }
            
        case GROUP_EDITOR + SET_PROPS:
            return {
                ...state,
                groupEditor: action.payload.value
            }
            
        case GROUP_EDITOR + SET_PROP_VALUE:
            return {
                ...state,
                groupEditor: {
                    ...state.groupEditor
                   ,[ action.payload.name ]: action.payload.value
                }
            }
            
        default:
            return state
    }
}
