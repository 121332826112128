import {
// actions
    ADD
   ,DELETE
   ,ADD_LIST_VALUES
   ,SET_LIST_BY_ID
   ,SET_PROP_VALUE
   ,SET_ID
// reducer names
   ,ROLE
// properties
//   ,STAFF_ALL_LIST_ITEM
} from '../constants';
import roleEditor from './roleEditor';

export const initialState = {

    // ответ Staff API для поиска лиц и подразделений + Все
    staffGroupAuthorList:   [] // [ STAFF_ALL_LIST_ITEM ] переместил в lang.js
   ,staffTemplateAdminList: [] // Админы шаблона = Все - убрали, лишняя возможность
   ,staffTemplateUserList:  [] // [ STAFF_ALL_LIST_ITEM ] переместил в lang.js

    // выбранное лицо или подразделение из списка со Staff
   ,staffGroupAuthor: ''
   ,staffTemplateAdmin: ''
   ,staffTemplateUser: ''
    
   ,tmpIdSequence: 0 // убывающий счётчик временных id, потом из базы должен вернуться реальный id
   
    // списки уполномоченных лиц и подразделений { 1: [ { login: "ylysenko78", name: "Yuri", type: "PERSON" } ] }
   ,groupAuthorList: {}
   ,templateAdminList: {}
   ,templateUserList: {}

};

export default (state = initialState, action) => {
    let name;
    switch (action.type) {
     
        case ROLE + SET_PROP_VALUE:
            name = action.payload.name
            const value = action.payload.value
            return {
                ...state,
                [name]: value
            };
            
        case ROLE + ADD_LIST_VALUES:
            name = action.payload.name
            const newList = action.payload.value;
            let stateList = [ ...state[ name ] ];
            
            // без повторов
            newList.forEach( i => {
                if( stateList.findIndex( k => k.id === i.id && k.type === i.type ) === -1 ) stateList.push( i );
            } );
            
            // с сортировкой
            stateList.sort( (a,b) => a.name.localeCompare(b.name) );
            
            return {
                ...state,
                
                // было просто слияние
                //[ name ]: [ ...state[ name ], ...listValues ]
                
                [ name ]: stateList
            };
        
        case ROLE + SET_LIST_BY_ID:
            name = action.payload.name
            return {
                ...state,
                [ name ]: {
                    ...state[ name ]
                   ,[ action.payload.id ]: action.payload.value
                }
            };
            
        case ROLE + ADD:
            state.tmpIdSequence = action.payload.value.roleId
            /* falls through */
        case ROLE + DELETE:
            /* falls through */
        case ROLE + SET_ID:
            name = action.payload.name
            const id = action.payload.id // id шаблона или группы
            return {
                ...state,
                [ name ]: {
                    ...state[ name ]
                   ,[ id ]: roleEditor( state[ name ][ id ], action )
                }
            }
            
        default:
            return state;
    }
}
