import React from 'react';
import styles from "./Form.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import DragIndicator from '@material-ui/icons/DragIndicator';
import {
    gLang
} from '../../../redux/actions/lang';


const captions = {
    "ru": {
        move: "Подвинуть"
    }
    ,"en": {
        move: "Move"
    }
};


const MoveButton = ( props ) => {

    const lang = gLang;
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];
    
    const readonly = props.readonly;
    
    const moveButton = readonly ? <div style={{ padding: '4px' }} ></div> :
        <Tooltip title = { caption.move } arrow>
        <DragIndicator
            fontSize = "default"
            className = { styles.xxIcon }
            style={{ paddingTop: '4px' }}
        />
        </Tooltip>;
        
    return moveButton;
};

export default MoveButton;
