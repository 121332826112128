import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import monitorReducersEnhancer from './../enhancers/monitorReducers';
import loggerMiddleware from './../middlewares/logger';

import rootReducer from './../reducers';

export default function configureStore() {
    let middlewares = [ thunkMiddleware ];

    if (process.env.NODE_ENV !== 'production')  {
        middlewares.push(loggerMiddleware);
    }

    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [ middlewareEnhancer, monitorReducersEnhancer ];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(rootReducer, composedEnhancers);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./../reducers', () => store.replaceReducer(rootReducer))
    }

    return store;
}