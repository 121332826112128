import React from 'react';
import styles from "./Form.module.css";
import Card from "@material-ui/core/Card";
import HiddenInput from "./HiddenInput";
import InputWithSelect from "./InputWithSelect";
import Multiline from "./Multiline";
import { DateInput, onChangeDate } from "./DateInput";
import {
    getDocItemListByOptionsList
} from '../../redux/actions/optionsList';
import {
    defaultValue
   ,onChangeBankDetails
   ,setYandexFields
   ,captions
   ,emptyRecord
   ,yandexOrgsByBgList
} from '../../redux/actions/yandex';
import {
    GRAY
   ,HIDE
} from "../../redux/constants";


const Yandex = ( props ) => {

    if( !props.visible && props.visible !== undefined ) return;

    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    // protect для внешней формы
    const hidden = props.isExternal && props.protect === HIDE;
    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const protect = props.protect;
    const isExternal = props.isExternal;
    const optionsList = props.optionsList;
    const dispatch = props.dispatch;
    const selectonly = props.selectonly;

    const suffix = props.suffix === undefined || props.suffix === null ? '' : props.suffix;

    const globalLists = props.globalLists;
    
    let list = props.list;
    let fullNameList = [];

    if( optionsList === "yaBGs" ) {
        list = yandexOrgsByBgList( list, globalLists );
    } else {
        // TODO хорошо бы вынести заполнение списков, чтобы заполнять один раз
        list = getDocItemListByOptionsList( globalLists.yaOrgs, list, "ya_name", "ya_name" );
    }
    fullNameList = getDocItemListByOptionsList( globalLists.yaOrgs, list, "ya_name", "ya_full_name" );


    // сортировку перенёс в actions/yandex.js/loadYaOrgs() и reducers/yandex.js
    // list.sort( (a,b) => a.localeCompare(b) );
    // fullNameList.sort( (a,b) => a.localeCompare(b) );

    // раньше весь справочник Yandex загружался в начале сеанса,
    // теперь в начале получаем только список с ключевыми полями (id, ya_name, ya_full_name, ya_inn, ya_reg_num, ya_org_id, isRead, isDefault),
    // полная строка загружается по необходимости, isRead - признак полной строки
    
    // predefined, extdefined читаются вместе с загрузкой шаблона,
    // полная строка при этом не загружается - это может быть проблемой только для списков
    // (если InputWithSelect не поддерживает ввод текста, и если в списке нет значения, то поле нельзя установить),
    // сейчас списки только name и fullName, значения для них есть
    
    // если predefined / extdefined пусты, нужно установить значение по умолчанию,
    // defaultValue хранит только значение из списка name,
    // для остальных полей нужно загрузить полную строку в справочник

    const value = defaultValue( props, props.state, props, suffix, list );

    const specialFields = {};

    // Выпадающий список с полем ввода для названия организации
    let tag = 'ya_name' + suffix;
    specialFields.ya_name =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            label = { caption.ya_name }
            name = { tag }
            value = { value }
            // раньше список юрлиц Яндекс был один для всех
            //,list: props.doc.yaOrgs.map( item => item.name )
            // теперь list хранится в каждом шаблоне свой (значения из поля optionsList.name, в случае ЮЛ Яндекс - краткие имена),
            // а полный список значений, доступных для отбора задан в optionsList
            list = { list }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onSelectChange = { ( value ) => { setYandexFields( dispatch, suffix, props, 'ya_name', value ); } }
            // readonly={ readonly } // сюда не передаём, можно выбирать
            protect = { protect }
            isExternal = { isExternal }
        />;

        
    tag = 'ya_full_name' + suffix;
    specialFields.ya_full_name =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            label = { caption.ya_full_name }
            name = { tag }
            value = { props.state[ tag ] }
            list = { fullNameList }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onSelectChange = { ( value ) => { setYandexFields( dispatch, suffix, props, 'ya_full_name', value ); } }
            //readonly = { readonly }
            protect = { protect }
            isExternal = { isExternal }
        />;
    

    tag = 'ya_inn' + suffix;
    specialFields.ya_inn =
        <Multiline
            className = { props.className }
            key = { tag }
            label = { caption.ya_inn }
            name = { tag }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { ( e ) => {
                props.onChange( e );
                
                const value = e.target.value;
                if( !value || !( [10, 12].includes( value.length ) ) ) {
                    return;
                }
                setYandexFields( dispatch, suffix, props, 'ya_inn', value );
            }}
            //readonly = { readonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

        
    tag = 'ya_reg_num' + suffix;
    specialFields.ya_reg_num =
        <Multiline
            className = { props.className }
            key = { tag }
            label = { caption.ya_reg_num }
            name = { tag }
            value = { props.state[ tag ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { ( e ) => {
                props.onChange( e );
                setYandexFields( dispatch, suffix, props, 'ya_reg_num', e.target.value );
            }}
            //readonly = { readonly }
            protect = { protect }
            isExternal = { isExternal }
        />;
    

    tag = 'ya_bank_name' + suffix;
    specialFields.ya_bank_name =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            label = { caption.ya_bank_name }
            name = { tag }
            value = { props.state[ tag ] }
            list = { props.session[ 'ya_bank_list' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { e => { // печать в поле ввода
                props.onChange( e );
                const newValue = e.target.value;
                onChangeBankDetails(
                    dispatch
                   ,suffix
                   ,props.session[ 'ya_bank_details' + suffix ]
                   ,'ya_bank_name'
                   ,newValue
                   ,false // clearOnError = false, можно вбить новые значения
                );
            }}
            //onNameValueChange = { props.onNameValueChange } // функция не объявлена, не нужно
            onSelectChange = { value => { // выбор из списка
                onChangeBankDetails(
                    dispatch
                   ,suffix
                   ,props.session[ 'ya_bank_details' + suffix ]
                   ,'ya_bank_name'
                   ,value
                   ,true // clearOnError
                );
            }}
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

        
    tag = 'ya_bic' + suffix;
    specialFields.ya_bic =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            label = { caption.ya_bic }
            name = { tag }
            value = { props.state[ tag ] }
            list = { props.session[ 'ya_bic_list' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { e => {
                props.onChange( e );
                const newValue = e.target.value;
                onChangeBankDetails(
                    dispatch
                   ,suffix
                   ,props.session[ 'ya_bank_details' + suffix ]
                   ,'ya_bic'
                   ,newValue
                   ,false // clearOnError
                );
            }}
            //onNameValueChange = { props.onNameValueChange }
            onSelectChange = { value => {
                onChangeBankDetails(
                    dispatch
                   ,suffix
                   ,props.session[ 'ya_bank_details' + suffix ]
                   ,'ya_bic'
                   ,value
                   ,true // clearOnError
                );
            }}
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

        
    tag = 'ya_payment_account' + suffix;
    specialFields.ya_payment_account =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            label = { caption.ya_payment_account }
            name = { tag }
            value = { props.state[ tag ] }
            list = { props.session[ 'ya_payment_account_list' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            // если впечатали счёт,
            // нужно или выбрать банк, если комбинация существует,
            // или оставить возможность ввести любые данные
            onChange = { e => {
                props.onChange( e );
                const newValue = e.target.value;
                onChangeBankDetails(
                    dispatch
                   ,suffix
                   ,props.session[ 'ya_bank_details' + suffix ]
                   ,'ya_payment_account'
                   ,newValue
                   ,false // clearOnError
                );
            }}
            //onNameValueChange = { props.onNameValueChange }
            // если счета ограничены банком, то не имеет смысла,
            // но если полный список счетов - нужно менять банк по выбранному счёту
            onSelectChange = { value => {
                onChangeBankDetails(
                    dispatch
                   ,suffix
                   ,props.session[ 'ya_bank_details' + suffix ]
                   ,'ya_payment_account'
                   ,value
                   ,true // clearOnError
                );
            }}
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

        
    tag = 'ya_corr_account' + suffix;
    specialFields.ya_corr_account =
        <InputWithSelect
            className = { props.className }
            key = { tag }
            label = { caption.ya_corr_account }
            name = { tag }
            value = { props.state[ tag ] }
            list = { props.session[ 'ya_corr_account_list' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { e => {
                props.onChange( e );
                const newValue = e.target.value;
                onChangeBankDetails(
                    dispatch
                   ,suffix
                   ,props.session[ 'ya_bank_details' + suffix ]
                   ,'ya_corr_account'
                   ,newValue
                   ,false // clearOnError
                );
            }}
            //onNameValueChange = { props.onNameValueChange }
            onSelectChange = { value => {
                onChangeBankDetails(
                    dispatch
                   ,suffix
                   ,props.session[ 'ya_bank_details' + suffix ]
                   ,'ya_corr_account'
                   ,value
                   ,true // clearOnError
                );
            }}
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;


    const fieldsList = Array.isArray( props.fieldsList ) ? props.fieldsList : [];

    const content = Object.keys( emptyRecord ).map( id =>
        {
            const tag = id + suffix;
            let item = undefined;

            // поля с особым поведением
            item = specialFields[ id ];
            
            if( item !== undefined )
                if( fieldsList.includes( id ) )
                    return item;
                else
                    return <HiddenInput key = { tag } name = { tag } value = { props.state[ tag ] } />;
            
            // остальные поля
            if( fieldsList.includes( id ) )
                return id.includes( "date" ) ?
                <DateInput
                    key = { tag }
                    className = { props.className }
                    label = { caption[ id ] }
                    name = { tag }
                    value = { props.state[ tag ] }
                    value_dd = { props.state[ tag + "_dd" ] }
                    value_mm = { props.state[ tag + "_mm" ] }
                    value_yyyy = { props.state[ tag + "_yyyy" ] }
                    onChange = { onChangeDate( tag, props.onNameValueChange ) }
                    validators = { props.validators }
                    errorMessages = { props.errorMessages }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                    isDefaultDate = { false } // не устанавливать текущую дату по умолчанию
                />
                :
                <Multiline
                    key = { tag }
                    className = { props.className }
                    label = { caption[ id ] }
                    name = { tag }
                    value = { props.state[ tag ] }
                    onChange = { props.onChange }
                    validators = { props.validators }
                    errorMessages = { props.errorMessages }
                    readonly = { readonly }
                    protect = { protect }
                    isExternal = { isExternal }
                />;
            else
                // невидимые поля должны попасть в form submit
                return <HiddenInput key = { tag } name = { tag } value = { props.state[ tag ] } />;
        }
    );
    
    if( hidden ) return content;
    
    return (
        <Card className={ styles.xxComponentCard }>
        <div className={ styles.xxComponentCardLabel }>{ props.label }</div>
        <div className={ styles.xxComponentCardContent }>
            { content }
        </div>
        </Card>
    );
}

export default Yandex
