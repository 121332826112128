import React from 'react'
import styles from "../DocForm/Form.module.css"
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { VisibilityRounded } from "@material-ui/icons";
import { VisibilityOffRounded } from "@material-ui/icons";
import {
    caption
} from '../../redux/actions/lang';


const PreviewIcon = ( props ) => {
 
    const docId = props.docId;
    const docInfo = props.docNames ? props.docNames.find( i => i.id === docId ) : null;
    const format = docInfo ? docInfo.format : null;
    const isIframe = props.isIframe;
    //const isExternal = props.isExternal;
    
    if( /*isExternal ||*/ isIframe || format !== "docx" ) return '';
    
    const showPreview = props.showPreview;
    const Icon = showPreview ? VisibilityOffRounded : VisibilityRounded;
    const PreviewButton =
        <Tooltip title = { showPreview ? caption.hidePreview : caption.showPreview } arrow>
            <IconButton
                color = "primary"
                style = {{ position: "absolute", top: -9, right: 113 }}
                onClick = { () => props.onClickPreview( showPreview ) }
            >
                <Icon fontSize="large" className = { styles.xxIcon }/>
            </IconButton>
        </Tooltip>;
            
    return PreviewButton;
}

export default PreviewIcon

