import React from 'react'
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';

function isNumber(num) {
    return typeof num === 'number' && !isNaN(num);
}

// Usage:
// props.list = [ "item", ... ] OR
// props.list = [ { id: "item", name: "Name" }, ... ]

// Список полей составного компонента.
// Если нужно несколько компонентов в шаблоне, теги полей должны отличаться на suffix.

const MultiSelect = ( props ) => {
 
    const suffix = props.suffix === undefined || props.suffix === null ? '' : props.suffix;
    
    return (
        ( !props.visible && props.visible !== undefined ) ? '' :
        <div className='padding'>
            <InputLabel>{ props.label }</InputLabel>
            <Select
                className={ props.className }
                name={ props.name }
                value={ Array.isArray( props.value ) ? props.value : [] }
                //InputLabelProps={{ shrink: props.value !== [] }} // вызывает warning: React does not recognize the InputLabelProps prop on a DOM element.
                onChange={ props.onChange }
                multiple
// до OEBS-38288 было
//                renderValue={ selected => props.list.filter( i => selected.includes( i.id ) ).map( i => i.name ).join(', ') }
                renderValue={ selected => props.list.filter( i => selected.includes(  i.id !== undefined ? i.id : i  ) ).map( i => i.name !== undefined ? i.name : i  ).join(', ') }
            >
            {
                props.list &&
                props.list.map( ( item, i ) => (
                    item ?
                    <MenuItem
                        key={i}
                        value={ item.id !== undefined ? item.id : item }
                    >
                        <Checkbox checked={ props.value.indexOf( item.id !== undefined ? item.id : item ) > -1 }
// до OEBS-38288 было
//                      { item.name !== undefined ? `${item.name} (${ item.id }${ suffix })` : item } 
                         />
                        { item.name !== undefined ? isNumber(item.id) ? `${item.name}` : `${item.name} (${ item.id }${ suffix })` : item }
                    </MenuItem>
                    : ''
                ))
            }
            </Select>
        </div>
    )
}

export default MultiSelect
