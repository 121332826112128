// если cdoc встроен в iframe,
// следить за высотой и отправлять сообщения родителю,
// чтобы установить 100% высоту iframe по содержимому,
// и не было скролла внутри iframe,
// работает в паре с embed.js в родительском окне
function throttle( func, ms, ctx ) {

    let isThrottled = false,
        savedArgs,
        savedThis;

    function wrapper() {

        if( isThrottled ) {
            savedArgs = arguments;
            savedThis = ctx;
            return;
        }

        func.apply( ctx, arguments );

        isThrottled = true;

        setTimeout( function() {
            isThrottled = false;
            if( savedArgs ) {
                wrapper.apply( savedThis, savedArgs );
                savedArgs = savedThis = null;
            }
        }, ms );
    }

    return wrapper;
}


function IframeHeight() {

    this.height = 0;

    this.init = function() {
        if( this._inited ) {
            return;
        }

        this._inited = true;

        this._detectChangeHeight();
        this._checkChangeHeight();
    };

    /**
     * Ловит события любых изменений в DOM с помощью MutationObserver.
     * В случае обнаружения изменений вызывает {@link this._checkChangeHeight}.
     *
     * Для браузеров, не поддерживающих MutationObserver, используется setInterval.
     */
    this._detectChangeHeight = function() {
        // Предотвращаем ситуацию с большим количеством вызовов
        const checkChangeHeight = throttle( this._checkChangeHeight, 50, this );

        // Если iframe скрыт, то мы должны ждать событие resize –
        // оно вызовется после раскрытия этого iframe
        window.addEventListener( 'resize', checkChangeHeight, false );

        if( window.MutationObserver ) {
            const observer = new MutationObserver( checkChangeHeight );

            observer.observe( document.body, {
                attributes: true
               ,childList: true
               ,subtree: true
            } );
        } else {
            setInterval( this._checkChangeHeight.bind( this ), 500 );
        }
    };

    /**
     * Вычисляет высоту страницы с учетом открытых попапов.
     * В случае изменения высоты шлет ее по postMessage.
     */
    this._checkChangeHeight = function() {
        const popups = null; // document.querySelector('.popup_visibility_visible, .popup2_visible_yes');

        const height = popups ?
            Math.max(
                document.documentElement.scrollHeight
               ,document.body.scrollHeight
            ) :
            document.body.offsetHeight;

        if( height !== this.height ) {
            this.height = height;

            // https://yastatic.net/s3/frontend/forms/_/embed.js
            // contains JSON.parse(e.data), so we must send string, not object
            //window.top.postMessage( { 'iframe-height': height }, '*' );
            
            window.top.postMessage( '{ "type": "height", "iframe-height": ' + height + ' }', '*' );
            
            //console.log( "iframe-height = " + height );
        }
    };
}

export default IframeHeight;
