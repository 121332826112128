import React from 'react'
import MButton from '@material-ui/core/Button';

const Button = ( props ) => {
    return (
        <MButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={ props.onClick }
            name={ props.name }
        >{ props.label }</MButton>
    )
}

export default Button


