import {
   // actions
    SET_PROPS
   // reducer names
   ,EDIT_DIALOG
} from '../constants';


export const close = () => (
    {
        type: EDIT_DIALOG + SET_PROPS,
        payload: {
            value: { open: false }
        }
    }
);
