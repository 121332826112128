import React from 'react'
import styles from "./Form.module.css"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ValidatorForm } from 'react-material-ui-form-validator';
import HistoryTable from './HistoryTable';
import HistoryFilter from './HistoryFilter';
import EditDialog from '../../containers/EditDialog';
import Legend from './Legend';
import {
    getAdminDocSelect
   ,getAdminGroupSelect
} from '../../redux/actions/admin'


const HistoryForm = ( props ) => {
    
    const GroupSelect = getAdminGroupSelect( props );
    const DocSelect = getAdminDocSelect( props );
    
    return (
        <div className={ styles.HistoryForm }>
        
        <EditDialog />
        
        <Card className={ styles.xxGridActionPanel }>
        <CardContent className='Card'>
            
            <ValidatorForm
                id="historyForm"
                onKeyPress={ props.onFormKeyPress }
                onSubmit={ props.onFormSubmit }
            >
            <div style={{ display: "flex" }}>
                <div style={{ margin: "15px", width: "100%" }}>
                    { GroupSelect }
                    { DocSelect }
                </div>
                <div style={{ margin: "-1.75px", marginRight: "15px" }}>
                    <HistoryFilter
                        props = { props }
                        history = { props.history }
                    />
                </div>
            </div>
                <Legend />
                { !props.doc.docId || props.doc.docAdminList.findIndex( i => i.id === props.doc.docId ) === -1 ? '' :
                  <HistoryTable
                      present = { props.history.present[ props.doc.docId ] }
                      past    = { props.history.past[ props.doc.docId ] }
                      history = { props.history }
                      props   = { props }
                  />
                }
            </ValidatorForm>
            
        </CardContent>
        </Card>
        </div>
    )
}

export default HistoryForm
