import {
    APP_URL
   ,SET_NAVBAR_ACTIVE
} from '../constants';

export const initialState = {
    active: APP_URL + "/"
};

export default (state = initialState, action) => {
    switch (action.type) {
     
        case SET_NAVBAR_ACTIVE:
            return {
                ...state,
                active: action.value
            };
            
        default:
            return state;
    }
}
