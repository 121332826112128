import {
// actions
    SET_PROPS
    ,SET_PROP_VALUE
} from './../constants'
import {BANK_DET_LIST_EDITOR} from "../constants";


const initialState = {
    open: false
}

export default (state = initialState, action) => {
    switch (action.type) {
     
        case BANK_DET_LIST_EDITOR + SET_PROPS:
            return action.payload.value
            
        case BANK_DET_LIST_EDITOR + SET_PROP_VALUE:
            return {
                ...state
               ,[ action.payload.name ]: action.payload.value
            }

            
        default:
            return state
    }
}
