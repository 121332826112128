import {
// actions
    ADD
   ,COPY
   ,DELETE
   ,EDIT
   ,SET_CHANGE_FLAG
   ,SET_DOC_ID
   ,SET_PROPS
   ,SET_PROP_VALUE
// reducer names
   ,FORM_TEMPLATE
   ,TEMPLATE_EDITOR
} from './../constants'
import { newTemplate } from './newTemplate';

// docAdminList - список шаблонов с правами админа
// по идее ускоряет интерфейс, но здесь приходится дублировать действия над docNames

export default (state, action) => {
    switch (action.type) {

        // флаг сохранения, если форма изменилась - isChanged = true
        case FORM_TEMPLATE + SET_CHANGE_FLAG:
            const docId = state.docId;
            return {
                ...state
               ,docAdminList: state.docAdminList.map( i => i.id === docId ? { ...i, isChanged: action.payload.value } : i )
            }
        
        case FORM_TEMPLATE + ADD:
            const addIdSequence = --state.tmpIdSequence
            const addGroupId = action.payload.groupId;
            return {
                ...state
               ,docId: addIdSequence
               ,groupId: addGroupId
               ,docNames: [
                    ...state.docNames
                   ,{
                        id: addIdSequence // отрицательные id используются, пока база не даст новый номер из последовательности
                       ,name: action.payload.name
                       ,code: action.payload.code
                       ,group_id: addGroupId
                       ,access_type: 'ADMIN' // создатель становится Админом шаблона
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
                // повторить для docAdminList
               ,docAdminList: [
                    ...state.docAdminList
                   ,{
                        id: addIdSequence
                       ,name: action.payload.name
                       ,code: action.payload.code
                       ,group_id: addGroupId
                       ,access_type: 'ADMIN'
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
               ,docTemplates: {
                    ...state.docTemplates
                   ,[ addIdSequence ]: newTemplate
                }
               ,docStates: {
                    ...state.docStates
                   ,[ addIdSequence ]: { login: state.login }
                }
            }
            
        case FORM_TEMPLATE + COPY:
            const srcDocId = action.payload.id;
            const copyGroupId = action.payload.groupId;
            const copyIdSequence = --state.tmpIdSequence;
            const templateCopy = state.docTemplates[ srcDocId ];
            const stateCopy = { ...state.docStates[ srcDocId ], login: state.login };
            return {
                ...state
               ,docId: copyIdSequence
               ,groupId: copyGroupId
               ,docNames: [
                    ...state.docNames
                   ,{
                        id: copyIdSequence // отрицательные id используются, пока база не даст новый номер из последовательности
                       ,name: action.payload.name
                       ,code: action.payload.code
                       ,group_id: copyGroupId
                       ,access_type: 'ADMIN' // создатель становится Админом шаблона
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
               ,docAdminList: [
                    ...state.docAdminList
                   ,{
                        id: copyIdSequence // отрицательные id используются, пока база не даст новый номер из последовательности
                       ,name: action.payload.name
                       ,code: action.payload.code
                       ,group_id: copyGroupId
                       ,access_type: 'ADMIN' // создатель становится Админом шаблона
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
               ,docTemplates: {
                    ...state.docTemplates
                   ,[ copyIdSequence ]: templateCopy
                }
               ,docStates: {
                    ...state.docStates
                   ,[ copyIdSequence ]: stateCopy
                }
            };
            
        case FORM_TEMPLATE + EDIT:
            const editDocId = action.payload.id;
            const editGroupId = action.payload.groupId;
            return {
                ...state
               ,groupId: editGroupId
               ,docNames: [
                    ...state.docNames.filter( i => i.id !== editDocId )
                   ,{
                        id: editDocId
                       ,name: action.payload.name
                       ,code: action.payload.code
                       ,group_id: editGroupId
                       ,access_type: 'ADMIN'
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
               ,docAdminList: [
                    ...state.docAdminList.filter( i => i.id !== editDocId )
                   ,{
                        id: editDocId
                       ,name: action.payload.name
                       ,code: action.payload.code
                       ,group_id: editGroupId
                       ,access_type: 'ADMIN'
                    }
                ].sort( (a,b) => a.name.localeCompare(b.name) )
            }
            
        case FORM_TEMPLATE + DELETE:
            delete state.docTemplates[ state.docId ];
            delete state.docStates[ state.docId ];
            const docNames = state.docNames.filter( i => state.docId !== i.id );
            const docAdminList = state.docAdminList.filter( i => state.docId !== i.id );
            // после удаления переходим к первому шаблону в группе
            const docFirstInGroup = docNames.find( i => state.groupId === i.group_id );
            const docAdminFirstInGroup = docAdminList.find( i => state.groupId === i.group_id );
            return {
                ...state
               ,docId: docAdminFirstInGroup !== undefined ? docAdminFirstInGroup.id : docFirstInGroup !== undefined ? docFirstInGroup.id : ''
               ,docNames
               ,docAdminList
            }
            
        // когда пришёл docId из базы,
        // заменить временный id
        case FORM_TEMPLATE + SET_DOC_ID:
            const oldId = state.docId
            const newId = action.payload.id
            const { [ oldId ]: docTemplate, ...otherDocTemplates } = state.docTemplates
            const { [ oldId ]: docState, ...otherDocStates } = state.docStates
            return {
                ...state
               ,docId: newId
               ,docNames: state.docNames.map( i => i.id === oldId ? { ...i, id: newId } : i )
               ,docAdminList: state.docAdminList.map( i => i.id === oldId ? { ...i, id: newId } : i )
               ,docTemplates: {
                    ...otherDocTemplates
                   ,[ newId ]: docTemplate
                }
               ,docStates: {
                    ...otherDocStates
                   ,[ newId ]: docState
                }
            }
            
        case TEMPLATE_EDITOR + SET_PROPS:
            return {
                ...state,
                templateEditor: action.payload.value
            }
            
        case TEMPLATE_EDITOR + SET_PROP_VALUE:
            return {
                ...state,
                templateEditor: {
                    ...state.templateEditor
                   ,[ action.payload.name ]: action.payload.value
                }
            }

        default:
            return state
    }
}
