import React from 'react';

export const Bug = ( { title } ) => (
    <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <line x1="11.7396" y1="4.56856" x2="12.1619" y2="1.90245" stroke="#21201F" strokeLinecap="round"/>
    <line x1="6.74105" y1="6.92845" x2="5.15441" y2="4.74463" stroke="#21201F" strokeLinecap="round"/>
    <line x1="4.25597" y1="12.2251" x2="1.02416" y2="11.0289" stroke="#21201F" strokeLinecap="round"/>
    <path d="M18.9199 14.7031L21.711 15.1452" stroke="#21201F" strokeLinecap="round"/>
    <line x1="0.5" y1="-0.5" x2="3.19935" y2="-0.5" transform="matrix(0.587785 0.809017 0.809017 -0.587785 15.291 18.1016)" stroke="#21201F" strokeLinecap="round"/>
    <line x1="0.5" y1="-0.5" x2="3.94606" y2="-0.5" transform="matrix(0.040305 0.999187 0.999187 -0.040305 9.85156 19.0078)" stroke="#21201F" strokeLinecap="round"/>
    <g filter="url(#filter0_dii_3392_75339)">
    <path d="M16.637 18.6492C12.5048 21.6514 7.04565 21.182 4.4437 17.6008C1.84175 14.0195 3.0823 8.68248 7.21454 5.68023C11.3414 1.91977 16.8059 3.14737 19.4079 6.72865C22.0098 10.3099 20.9899 16.2488 16.637 18.6492Z" fill="#ED5243"/>
    </g>
    <path d="M13.4224 9.33234C12.596 9.93279 11.4392 9.74959 10.8388 8.92314C10.2383 8.09669 10.7617 6.53417 11.5881 5.93372C12.4145 5.33327 13.2312 5.92226 13.8316 6.74871C14.4321 7.57516 14.2489 8.73189 13.4224 9.33234Z" fill="#21201F"/>
    <path d="M15.2801 11.889C14.4536 12.4894 14.2704 13.6461 14.8709 14.4726C15.4713 15.299 17.1191 15.2842 17.9455 14.6837C18.772 14.0833 18.4641 13.1246 17.8637 12.2982C17.2632 11.4717 16.1065 11.2885 15.2801 11.889Z" fill="#21201F"/>
    <path d="M8.01151 12.7225C7.46054 13.1228 6.68939 13.0007 6.28909 12.4497C5.88879 11.8988 6.23011 10.9049 6.78107 10.5046C7.33204 10.1043 7.88401 10.4492 8.28431 11.0001C8.68461 11.5511 8.56247 12.3222 8.01151 12.7225Z" fill="#21201F"/>
    <path d="M10.1261 15.6333C9.57511 16.0336 9.45298 16.8048 9.85328 17.3557C10.2536 17.9067 11.3043 17.8892 11.8553 17.4889C12.4063 17.0886 12.2488 16.4571 11.8485 15.9061C11.4482 15.3552 10.677 15.233 10.1261 15.6333Z" fill="#21201F"/>
    <g filter="url(#filter1_d_3392_75339)">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.7761 11.0677C19.0316 10.7806 17.518 10.042 16.4146 8.9047C15.674 7.50413 15.4394 5.83656 15.7052 4.08891C15.7398 4.06161 15.7751 4.0349 15.811 4.00879C17.4984 2.78287 20.002 3.35232 21.403 5.28071C22.8041 7.20909 22.572 9.76615 20.8847 10.9921C20.8488 11.0181 20.8126 11.0433 20.7761 11.0677Z" fill="#21201F"/>
    </g>
    <line x1="0.25" y1="-0.25" x2="2.21623" y2="-0.25" transform="matrix(-0.156434 0.987688 0.987688 0.156434 19.5908 2.78125)" stroke="#21201F" strokeWidth="0.5" strokeLinecap="round"/>
    <line x1="22.8979" y1="7.93016" x2="20.5107" y2="7.12274" stroke="#21201F" strokeWidth="0.5" strokeLinecap="round"/>
    <path d="M20.8553 6.7581C20.4651 7.04165 20.3785 7.58789 20.6621 7.97816C20.9456 8.36843 21.1385 8.7117 21.5287 8.42815C21.919 8.14461 22.0055 7.59837 21.722 7.2081C21.4384 6.81783 21.2456 6.47455 20.8553 6.7581Z" fill="#C4C4C4"/>
    <path d="M19.8299 5.3425C19.4396 5.62605 18.8934 5.53953 18.6098 5.14926C18.3263 4.75899 18.0594 4.46951 18.4496 4.18597C18.8399 3.90242 19.3861 3.98893 19.6697 4.3792C19.9532 4.76947 20.2201 5.05895 19.8299 5.3425Z" fill="#C4C4C4"/>
    <path d="M15.11 9.30634V9.30634C15.5706 9.02294 16.0353 9.66247 15.6234 10.013V10.013L5.00428 18.282L4.54636 17.7668C4.45812 17.6675 4.37999 17.5597 4.31313 17.4449L3.97747 16.8687L15.11 9.30634Z" fill="#21201F"/>
    <path d="M17.7014 16.2355C16.0151 17.8061 14.0478 18.084 13.6707 17.6791C13.2936 17.2742 14.1614 16.3097 15.376 15.1784C16.5907 14.0471 17.6922 13.1774 18.0693 13.5823C18.4464 13.9872 18.916 15.1042 17.7014 16.2355Z" fill="url(#paint0_radial_3392_75339)"/>
    <defs>
    <filter id="filter0_dii_3392_75339" x="2.08301" y="3.38281" width="19.7402" height="21.2422" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="0.5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.408333 0 0 0 0 0.06125 0 0 0 0 0.06125 0 0 0 0.35 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3392_75339"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3392_75339" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3392_75339"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="effect2_innerShadow_3392_75339" result="effect3_innerShadow_3392_75339"/>
    </filter>
    <filter id="filter1_d_3392_75339" x="13.5996" y="3.35938" width="10.7197" height="11.7109" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3392_75339"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3392_75339" result="shape"/>
    </filter>
    <radialGradient id="paint0_radial_3392_75339" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5042 15.6806) rotate(149.995) scale(2.86679 2.31024)">
    <stop stopColor="white" stopOpacity="0.73"/>
    <stop offset="1" stopColor="white" stopOpacity="0"/>
    </radialGradient>
    </defs>
    </svg>
);
