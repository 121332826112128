import React from 'react'
import styles from "./Form.module.css"
import Card from "@material-ui/core/Card";
import Input from "./Input";
import HiddenInput from "./HiddenInput";
import Multiline from "./Multiline";
//import Spark from "./Spark";
//import Refinitiv from "./Refinitiv";
import InputWithSelect from "./InputWithSelect";
import {
    getVendorDataByInn
   ,getVendorNames
   ,setVendorLists
   ,setVendorInn
   ,captions
   ,captions2
} from '../../redux/actions/vendorData';
import {
    GRAY
   ,HIDE
   ,VENDOR_NOT_FOUND
   ,SESSION
} from "../../redux/constants";
import {
    setPropValue
} from "../../redux/actions/utils";


const Vendor = ( props ) => {

    if( !props.visible && props.visible !== undefined ) return;
    
    // protect для внешней формы
    const hidden = props.isExternal && props.protect === HIDE;
    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const protect = props.protect;
    const isExternal = props.isExternal;
    const required = props.required === undefined ? true : props.required;
    const selectonly = props.selectonly;

    const dispatch = props.dispatch;
    const state = props.state;
    const vendorStates = props.vendorStates;
    const suffix = props.suffix === undefined || props.suffix === null ? '' : props.suffix;
    const inn = state[ 'vendorInn' + suffix ];

    var delayTimer;
    
    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];
    const caption2 = captions2[ lang ] ? captions2[ lang ] : captions2[ "ru" ];

    const vendorInn = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorInn" ) ) ?
        <HiddenInput
            name = { 'vendorInn' + suffix }
            value = { state[ 'vendorInn' + suffix ] }
        />
        :
        <Input
            className = { props.className }
            label = { caption.vendorInn }
            name = { 'vendorInn' + suffix }
            value = { state[ 'vendorInn' + suffix ] }
            onChange = { ( e ) => {
                props.onChange( e );
                const newInn = e.target.value;
                getVendorDataByInn( vendorStates, newInn, dispatch, suffix ); // запрос к БД, по результату будут заполнены списки
            }}
            // чтобы ИНН можно было оставить пустым
            //validators = { required ? ['required', 'isNumber', 'minStringLength:10', 'maxStringLength:12'] : [] }
            //errorMessages = { required ? ['Поле обязательно для заполнения', 'Поле должно содержать число', 'Минимальная длина строки 10 символов', 'Максимальная длина строки 12 символов'] : [] }
            validators = { required ? [ 'required', 'minStringLength:8' ] : [] }
            errorMessages = { required ? [ caption2.fieldRequired, caption2.fieldMinLength ] : [] }
            helperText = { props.helperText }
            error = { props.error }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const createVendorText = ( !isExternal && state[ VENDOR_NOT_FOUND + suffix ] ) ?
        <div className='padding'>
            <div className={ styles.xxErrorText }>
                <p className={ styles.xxParagraphHeader }>{ caption2.createNewVendor }</p>
                <p className={ styles.xxParagraph }>{ caption2.vendorNonResident }<a href={ props.cdocProperties.createNoneRezVendor } target="_blank" rel="noreferrer noopener">{ caption2.here }</a>,</p>
                <p className={ styles.xxParagraph }>{ caption2.vendorNaturalPerson }<a href={ props.cdocProperties.createFlVendor } target="_blank" rel="noreferrer noopener">{ caption2.here }</a>,</p>
                <p className={ styles.xxParagraph }>{ caption2.vendorResident }<a href={ props.cdocProperties.createVendor } target="_blank" rel="noreferrer noopener">{ caption2.here }</a>.</p>
            </div>
        </div> : '';

    const vendor = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendor" ) ) ?
        <HiddenInput
            name = { 'vendor' + suffix }
            value = { state[ 'vendor' + suffix ] }
        />
        :
        <InputWithSelect
            className = { props.className }
            label = { caption.vendor }
            name = { 'vendor' + suffix }
            value = { state[ 'vendor' + suffix ] }
            list = { props.vendorNameList }
            onInputChange = { value => {
                clearTimeout( delayTimer );
                delayTimer = setTimeout( function() {
                    if( value === undefined || value === null || value === '' ) {
                        dispatch( setPropValue( SESSION, 'vendorNameList', [] ) );
                        return;
                    }
                    getVendorNames( value, dispatch );
                }, 700 );
            }}
            onSelectChange = { (value) => {
                if( !value ) return;
                const inn = value.id;
                setVendorInn( inn, dispatch, suffix );
                getVendorDataByInn( vendorStates, inn, dispatch, suffix );
            }}
            onNameValueChange = { props.onNameValueChange }
            onChange = { props.onChange }
            validators = { required ? ['required'] : [] }
            errorMessages = { required ? [ caption2.vendorNotFound ] : [] }
            readonly = { readonly || selectonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const vendorEngName = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorEngName" ) ) ?
        <HiddenInput
            name = { 'vendorEngName' + suffix }
            value = { state[ 'vendorEngName' + suffix ] }
        />
        :
        <Multiline
            className = { props.className }
            label = { caption.vendorEngName }
            name = { 'vendorEngName' + suffix }
            value = { state[ 'vendorEngName' + suffix ] }
            onChange = { props.onChange }
            validators = { required ? ['required'] : [] }
            errorMessages = { props.errorMessages }
            readonly = { readonly || selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const vendorKpp = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorKpp" ) ) ?
        <HiddenInput
            name = { 'vendorKpp' + suffix }
            value = { state[ 'vendorKpp' + suffix ] }
        />
        :
        <InputWithSelect
            className = { props.className }
            label = { caption.vendorKpp }
            name = { 'vendorKpp' + suffix }
            value = { state[ 'vendorKpp' + suffix ] }
            list = { state[ 'vendorKppList' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { props.onChange }
            onNameValueChange = { props.onNameValueChange }
            onSelectChange = { () => {
                setVendorLists( inn, dispatch, suffix );
            }}
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const vendorAddress = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorAddress" ) ) ?
        <HiddenInput
            name = { 'vendorAddress' + suffix }
            value = { state[ 'vendorAddress' + suffix ] }
        />
        :
        <InputWithSelect
            className = { props.className }
            label = { caption.vendorAddress }
            name = { 'vendorAddress' + suffix }
            value = { state[ 'vendorAddress' + suffix ] }
            list = { state[ 'vendorAddressList' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { props.onChange }
            onNameValueChange = { props.onNameValueChange }
            onSelectChange = { () => {
                setVendorLists( inn, dispatch, suffix );
            }}
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;
/*
    const spark = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "spark" ) ) ?
        <HiddenInput
            name = { 'spark' + suffix }
            value = { state[ 'spark' + suffix ] }
        />
        :
        <Spark
            label = { caption.spark }
            name = { 'spark' + suffix }
            value = { state[ 'spark' + suffix ] }
            readonly = { readonly }
            protect = { protect }
            isExternal = { isExternal }
            lang = { lang }
        />;

    const refinitiv = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "refinitiv" ) ) ?
        <HiddenInput
            name = { 'refinitiv' + suffix }
            value = { state[ 'refinitiv' + suffix ] }
        />
        :
        <Refinitiv
            label = { caption.refinitiv }
            name = { 'refinitiv' + suffix }
            value = { state[ 'refinitiv' + suffix ] }
            readonly = { readonly }
            protect = { protect }
            isExternal = { isExternal }
            lang = { lang }
        />;
*/
    const vendorSite = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorSite" ) ) ?
        <HiddenInput
            name = { 'vendorSite' + suffix }
            value = { state[ 'vendorSite' + suffix ] }
        />
        :
        <InputWithSelect
            className = { props.className }
            label = { caption.vendorSite }
            name = { 'vendorSite' + suffix }
            value = { state[ 'vendorSite' + suffix ] }
            list = { state[ 'vendorSiteList' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { props.onChange }
            onNameValueChange = { props.onNameValueChange }
            onSelectChange = { () => {
                setVendorLists( inn, dispatch, suffix );
            }}
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const bankName = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorBankName" ) ) ?
        <HiddenInput
            name = { 'vendorBankName' + suffix }
            value = { state[ 'vendorBankName' + suffix ] }
        />
        :
        <InputWithSelect
            className = { props.className }
            label = { caption.vendorBankName }
            name = { 'vendorBankName' + suffix }
            value = { state[ 'vendorBankName' + suffix ] }
            list = { state[ 'vendorBankList' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { props.onChange }
            onNameValueChange = { props.onNameValueChange }
            onSelectChange = { () => {
                setVendorLists( inn, dispatch, suffix );
            }}
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const bic = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorBic" ) ) ?
        <HiddenInput
            name = { 'vendorBic' + suffix }
            value = { state[ 'vendorBic' + suffix ] }
        />
        :
        <InputWithSelect
            className = { props.className }
            label = { caption.vendorBic }
            name = { 'vendorBic' + suffix }
            value = { state[ 'vendorBic' + suffix ] }
            list = { state[ 'vendorBicList' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { props.onChange }
            onNameValueChange = { props.onNameValueChange }
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const paymentAccount = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorPaymentAccount" ) ) ?
        <HiddenInput
            name = { 'vendorPaymentAccount' + suffix }
            value = { state[ 'vendorPaymentAccount' + suffix ] }
        />
        :
        <InputWithSelect
            className = { props.className }
            label = { caption.vendorPaymentAccount }
            name = { 'vendorPaymentAccount' + suffix }
            value = { state[ 'vendorPaymentAccount' + suffix ] }
            list = { state[ 'vendorPaymentAccountList' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { props.onChange }
            onNameValueChange = { props.onNameValueChange }
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const corrAccount = ( Array.isArray( props.fieldsList ) && !props.fieldsList.includes( "vendorCorrAccount" ) ) ?
        <HiddenInput
            name = { 'vendorCorrAccount' + suffix }
            value = { state[ 'vendorCorrAccount' + suffix ] }
        />
        :
        <InputWithSelect
            className = { props.className }
            label = { caption.vendorCorrAccount }
            name = { 'vendorCorrAccount' + suffix }
            value = { state[ 'vendorCorrAccount' + suffix ] }
            list = { state[ 'vendorCorrAccountList' + suffix ] }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
            onChange = { props.onChange }
            onNameValueChange = { props.onNameValueChange }
            readonly = { readonly }
            selectonly = { selectonly }
            protect = { protect }
            isExternal = { isExternal }
        />;

    const content = <>
        { vendorInn }
        { vendor }
        { vendorEngName }
        { createVendorText }
        { /* spark */ }
        { /* refinitiv */ }
        { vendorAddress }
        { vendorSite }
        { vendorKpp }
        { bankName }
        { bic }
        { paymentAccount }
        { corrAccount }
    </>;
    
    if( hidden ) return content;
    
    return (
        <Card className={ styles.xxComponentCard }>
        <div className={ styles.xxComponentCardLabel }>{ props.label }</div>
        <div className={ styles.xxComponentCardContent }>
            { content }
        </div>
        </Card>
    );
};

export default Vendor
