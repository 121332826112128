import {
// actions
    SET_KEY_PROP_VALUE
   ,SET_PROP_VALUE
   ,SET_DOC_KEY_PROP_VALUE
// reducer names
   ,UPLOADS
} from './../constants';
import * as moment from "moment";

const tzOffset = new Date().getTimezoneOffset();

const initialState = {
    uploads: {} //  XXYA.CDOC_UPLOADS
   ,dateFrom: moment( new Date() ).subtract(1, 'months').format('DD.MM.YYYY')
   ,dateTo:   moment( new Date() ).format('DD.MM.YYYY')
    // Для dateProcFrom и dateProcTo логично было бы оставлять значение пустым,
    // но, если значение пустое, то при попытке ввода данных с клавиатуры возникает ошибка
    // Uncaught TypeError: Cannot read property 'length' of undefined
   ,dateProcFrom: moment( new Date() ).subtract(1, 'months').format('DD.MM.YYYY')
   ,dateProcTo: moment( new Date() ).format('DD.MM.YYYY')
   ,fileName:''
   ,login:''
   ,limit: 50
   ,status: "ALL"
   ,tzOffset // смещение timezone в минутах, -180 для Москвы
}


export default ( state = initialState, action ) => {

    switch (action.type) {

        case UPLOADS + SET_PROP_VALUE:
            const payload = action.payload;
            return {
                ...state
               ,[ payload.name ]: payload.value
            }
        case UPLOADS + SET_KEY_PROP_VALUE:
            const key = action.payload.key;
            const name = action.payload.name;
            const value = action.payload.value;
            return {
                ...state
               ,[ name ]: {
                    ...state[ name ]
                   ,[ key ]: value
                }
            }

        case UPLOADS + SET_DOC_KEY_PROP_VALUE:
            const docKey = action.payload.key;
            const docName = action.payload.name;
            const docValue = action.payload.value;
            const docId = action.payload.docId;
            return {
                ...state
               ,uploads: {
                    ...state.uploads,
                    [docId]: [ ...state.uploads[docId].map( (item, idx) => {
                        if( item.uploadId !== docName ) return item;
                        return {
                            ...item
                           ,[docKey]: docValue
                        };
                    })]
                }
            }
            
        default:
            return state
    }
}
