import React from 'react';
import Grid from '@material-ui/core/Grid';
import Arrow from '../Arrow/Arrow';
import NavBar from './../../containers/NavBar';
import {withStyles} from '@material-ui/core/styles';
import {COLOR_GREY,COLOR_GREY_BACKGROUND} from './../../constants';
import User from './../../containers/User';
import Spin from './../../containers/Spin';
import Lang from '../DocParts/Lang';

const classes = {
    xxGrid: {
        borderTop: '1px solid ' + COLOR_GREY,
        borderBottom: '1px solid ' + COLOR_GREY,
        backgroundColor: COLOR_GREY_BACKGROUND,
        height: '50px',
    },
    xxNavItem: {
        marginRight: 'auto'
    },
    xxUser: {
        marginLeft: 'auto'
    },
};

const HeaderComponent = ( { classes, props } ) => {
    return (
        <Grid container className={classes.xxGrid} wrap={'nowrap'} >
            <Grid item ><Arrow isExternal = { props.isExternal } /></Grid>
            <Grid item className={classes.xxNavItem}><NavBar/></Grid>
            <Grid item className={classes.xxUser}><User /></Grid>
            
            <Lang
                lang = { props.lang }
                dispatch = { props.dispatch }
            />
            
            <Spin/>
        </Grid>
    );
};

const HeaderStyled = withStyles( classes )( HeaderComponent );

const Header = ( props ) => {
    return <HeaderStyled props = { props } />;
};

export default Header;
