import { connect } from 'react-redux';
import React, { Component } from 'react';
import { default as VGroupEditor } from '../components/AdminForm/GroupEditor';
import {
    GROUP_EDITOR
} from '../redux/constants';
import {
    close
   ,addGroup
   ,editGroup
   ,addGroupDB
   ,editGroupDB
} from '../redux/actions/groupEditor';
import {
    setPropValue
} from '../redux/actions/utils';
import {
    caption
} from '../redux/actions/lang';


class GroupEditor extends Component {
 
    render() {
        return (
            <VGroupEditor
                { ...this.props }
            />
        )
    }
}


const mapStateToProps = ( { doc } ) => {
    return {
        ...doc.groupEditor
    };
}


const mapDispatchToProps = dispatch => ({
    onChange: ( e ) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked :
                      target.value;
        dispatch( setPropValue( GROUP_EDITOR, name, value ) );
    }
   ,onOk: ( goal, groupId, groupName ) => {
        const name = groupName || caption.newGroup;
        if( goal === 'add' ) { dispatch( addGroup( name ) ); addGroupDB( dispatch, name ); }
        if( goal === 'edit' ) { dispatch( editGroup( groupId, name ) ); editGroupDB( dispatch, groupId, name ); }
    }
   ,onCancel: () => dispatch( close() )
})

export default connect( mapStateToProps, mapDispatchToProps )( GroupEditor )


