import {
    // actions
    SET_PROPS
   ,BANK_DET_LIST_EDITOR
} from '../constants'
import {getOptionsListRecord} from "./optionsList";
import {loadRecord} from "./yandex";
import {setProps} from "./utils";

export const close = () => (
    {
        type: BANK_DET_LIST_EDITOR + SET_PROPS,
        payload: {
            value: { open: false }
        }
    }
);

export function setBankDetails( dispatch, key, item, docTemplate, itemKey ) {

    let yaOrgs = dispatch( ( dispatch, getState ) => getState().doc.yaOrgs );
    const record = getOptionsListRecord( yaOrgs, "ya_name", item );
    if( record && !record.isRead ) {

        loadRecord(
            dispatch
           ,{ ya_name: item }
           ,() => setBankDetails( dispatch, key, item, docTemplate, itemKey )
        );
        return;
    }
    let bankDetails = record.ya_bank_details;
    
    // склейка ya_bank_name + ya_payment_account + ya_corr_account для списка банковских сведений
    const vendorSiteBankDetails = bankDetails.map(i => {
        let bankDetailsObject = { id : null, name: null};
        bankDetailsObject.id = i.id;
        let ya_bank_name = i.ya_bank_name ?? '';
        let ya_payment_account = i.ya_payment_account ?? '';
        let ya_corr_account = i.ya_corr_account ?? '';
        bankDetailsObject.name = ya_bank_name + " " + ya_payment_account + " " + ya_corr_account;
        return bankDetailsObject
    });

    // Банковские реквизиты из шаблона
    let listBankDetails = docTemplate[itemKey].listBankDetails;
    const itemListBankDetails = !listBankDetails ? [] : listBankDetails.filter( i => i.item === item);
    let bankNameWhiteList      = itemListBankDetails.length === 0 ? []  : itemListBankDetails[0].bankNameWhiteList;
    let bankNameDefaultValue   = itemListBankDetails.length === 0 ? null  : itemListBankDetails[0].bankNameDefaultValue;

    // Если у ЮЛ только один банковский счёт, то подставляем его значение сразу в список допустимых значений и значение по умолчанию
    bankNameWhiteList = bankNameWhiteList.length === 0 && vendorSiteBankDetails.length === 1 ? vendorSiteBankDetails.map( i => i.id ) : bankNameWhiteList;
    bankNameDefaultValue = bankNameDefaultValue === null && vendorSiteBankDetails.length === 1 ? vendorSiteBankDetails.map( i => i.id ) : bankNameDefaultValue;

    dispatch(
        setProps( BANK_DET_LIST_EDITOR, {
            open: true,
            idx: key,
            itemKey: itemKey,
            item: item,
            bankDetails: vendorSiteBankDetails,
            bankNameWhiteList: bankNameWhiteList,
            bankNameFullList: vendorSiteBankDetails,
            bankNameDefaultValue: bankNameDefaultValue,
            listBankDetails: listBankDetails // полный список на элементе
        } ));
}
