import React from 'react'
import Button from '../AdminParts/Button';
import MButton from "@material-ui/core/Button";
import {
    caption
} from '../../redux/actions/lang';


const ExcelActions = ( props ) => {
    return (
        <div>
        <div className = 'AdminForm__row'>
            <Button onClick = { props.onRoleMatrixDownload } label = { caption.downloadRoleMatrix } />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MButton variant = "contained" color = "primary" component = "label">
                { caption.uploadRoleMatrix }
                <input
                  type = "file"
                  accept = ".xls,.xlsx"
                  style = {{ display: "none" }}
                  onChange = { props.onRoleMatrixUpload }
                />
            </MButton>
        </div>
        <p/>
        <div className = 'AdminForm__row'>
            <Button onClick = { props.onRoleTreeDownload } label = { caption.downloadRoleTree } />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MButton variant = "contained" color = "primary" component = "label">
                { caption.uploadRoleTree }
                <input
                  type = "file"
                  accept = ".xls,.xlsx"
                  style = {{ display: "none" }}
                  onChange = { props.onRoleTreeUpload }
                />
            </MButton>
        </div>
        </div>
    );
}

export default ExcelActions;
