import React, { Component } from 'react';
import { connect } from 'react-redux';
import VNavBar from '../components/NavBar/NavBar';
import { withRouter } from 'react-router-dom'; // for history and location
import {
    setNavBarActive
} from '../redux/actions/navBar';
import {
    APP_URL
   ,DOC
} from '../redux/constants';
import {
    getDocIdFromAddressBar
   ,setDocId
} from '../redux/actions/admin';
import {
    setPropValue
} from '../redux/actions/utils'

const regexp = /(\/)?(-?\d+)?(\/)?$/; // docId на хвосте адресной строки
let mounted = false;
let history = null;

class NavBar extends Component {

    constructor(props) {
        super(props);
        this.onHotKey = this.props.onHotKey.bind(this);
        history = this.props.history;
    }
    
    componentDidMount() {
        if( mounted ) return;
        
        // при загрузке отобразить правильный пункт в меню навигатора
        let url = this.props.location.pathname.replace( regexp, "/");
        if( url === '/' ) url = APP_URL + "/";
        this.props.onNavItemClick( url );

        document.addEventListener("keydown", (e) => this.onHotKey( e, this.props.docId ) );
        
        mounted = true;
    };
    
    componentWillUnmount() {
        document.removeEventListener("keydown", (e) => this.onHotKey( e, this.props.docId ) );
    }
    
    shouldComponentUpdate(nextProps, nextState) {

        // back, forward в браузере
        // должны отражаться в меню навигатора
        // и селекторе шаблона
        const history = this.props.history;
        let url = this.props.location.pathname;
        let newUrl = history.location.pathname;
        const docId = getDocIdFromAddressBar();
        
        // нажали back или forward в браузере
        if( url !== newUrl ) {
            url = url.replace( regexp, "/");
            newUrl = newUrl.replace( regexp, "/");
            if( url !== newUrl ) this.props.onNavItemClick( newUrl ); // подсветить пункт меню навигатора
            
            // сменился docId в адресной строке - нужно переключить селектор шаблона
            if( this.props.docId !== docId ) {
                setDocId( this.props.dispatch, docId );
                
                // закрыть Preview
                this.props.dispatch( setPropValue( DOC, 'showPreview', false ) );
                return false;
            }
        }

        // выбрали шаблон в селекторе - добавить docId к адресной строке
        if( this.props.docId !== nextProps.docId && docId !== nextProps.docId ) {
            const newLocation = history.location.pathname.replace( regexp, "/" + nextProps.docId );
            // отрицательные id временные, заменить в истории переходов новым id,
            // при удалении шаблона тоже,
            // иначе добавить
            if( docId < 0 || this.props.docTemplates[ docId ] === undefined ) history.replace( newLocation );
            else history.push( newLocation );
            
            // закрыть Preview
            this.props.dispatch( setPropValue( DOC, 'showPreview', false ) );
        }

        return (
            this.props.navBar !== nextProps.navBar ||
            this.props.docId !== nextProps.docId ||
            this.props.user !== nextProps.user ||
            this.props.lang !== nextProps.lang
        );
    }
    
    render() {
        return (
            <VNavBar
                {...this.props}
            />
        );
    };
};

const mapStateToProps = ( { navBar, doc, user, session } ) => {
    return {
        navBar
       ,docId: doc.docId
       ,docTemplates: doc.docTemplates
       ,user
       ,lang: session.lang
    };
};

const mapDispatchToProps = dispatch => ({
    onNavItemClick: ( value ) => {
        dispatch( setNavBarActive( value ) );
        // закрыть Preview
        dispatch( setPropValue( DOC, 'showPreview', false ) );
    }
   // HotKeys: Alt+1 - Документ, Alt+2 - Загрузки, Alt+3 - Админка, Alt+4 - Доступ, Alt+5 - История
   ,onHotKey: ( e, docId ) => {
        const key = e.which || e.keyCode;
        const c = String.fromCharCode( key );
        if( e.altKey ) switch( c ) {
            case '1': history.push( APP_URL + "/"         + docId ); break;
            case '2': history.push( APP_URL + "/uploads/" + docId ); break;
            case '3': history.push( APP_URL + "/admin/"   + docId ); break;
            case '4': history.push( APP_URL + "/role/"    + docId ); break;
            case '5': history.push( APP_URL + "/history/" + docId ); break;
            default: break;
        }
    }
   ,dispatch
})

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( NavBar ) );
