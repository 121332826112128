import React from 'react';
import { Link } from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {
    COLOR_NORMAL
   ,COLOR_MAIN
   ,COLOR_ACTIVE
} from "../../constants";
import {
    APP_URL
} from '../../redux/constants';

const classes = {
    root: {
    },
    list: {
        display: 'flex',
        margin: 0,
        paddingLeft: 0,
        listStyle: 'none'
    },
    listItemLink: {
        position: 'relative',
        display: 'block',
        padding: '12px',
        outline: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
        color: COLOR_NORMAL
    },
    listItemLinkTop: {
        '&:after': {
            visibility: 'hidden',
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '3px',
            top: 0,
            left: 0,
            backgroundColor: COLOR_MAIN,
            transform: 'scaleX(0)'
        }
    },
    listItemLinkEnabled: {
        '&:hover': {
            color: COLOR_ACTIVE,
            '&:after': {
                visibility: 'visible',
                transform: 'scaleX(1)',
                transition: 'all .15s ease-out'
            }
        }
    },
    listItemLinkActive: {
        color: COLOR_ACTIVE,
        '&:after': {
            visibility: 'visible',
            transform: 'scaleX(1)'
        }
    },
}


const navBarList = [
    {
        code: 'DOC',
        enabled: "Y",
        linkTo: APP_URL + "/",
        tooltip: "Alt+1"
    }
   ,{
        code: 'UPLOADS',
        enabled: "Y",
        linkTo: APP_URL + "/uploads/",
        tooltip: "Alt+2"
    }
   ,{
        code: 'ADMIN',
        enabled: "Y",
        linkTo: APP_URL + "/admin/",
        tooltip: "Alt+3"
    }
   ,{
        code: 'ROLE',
        enabled: "Y",
        linkTo: APP_URL + "/role/",
        tooltip: "Alt+4"
    }
   ,{
        code: 'HISTORY',
        enabled: "Y",
        linkTo: APP_URL + "/history/",
        tooltip: "Alt+5"
    }
];

const captions = {
    "ru": {
        DOC: "Документ"
       ,UPLOADS: "Масс-загрузки"
       ,ADMIN: "Админка"
       ,ROLE: "Доступ"
       ,HISTORY: "История"
    }
   ,"en": {
        DOC: "Document"
       ,UPLOADS: "Mass upload"
       ,ADMIN: "Admin"
       ,ROLE: "Role"
       ,HISTORY: "History"
    }
};

const NavBar = ( { navBar, docId, classes, onNavItemClick, user, lang } ) => {
    
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    let list = navBarList;
    if( user.login === null || user.isExternal ) return '';
    if( !( user.maxAccessType === 'ADMIN' || ( user.roles && user.isSuper ) ) )
        list = navBarList.filter( function(obj) { return obj.code === 'DOC' || obj.code === 'UPLOADS' } );
       
    return (
        <nav className = { classes.root } >
            <ul className = { classes.list } >
            {
                list.map((item, i) => {
                    return (
                        <li key={i} >
                        {
                            item.linkTo && item.enabled === "Y" ? (

                                <Link
                                    className={
                                        classNames(
                                            classes.listItemLink,
                                            classes.listItemLinkTop,
                                            ( item.linkTo === navBar.active ||
                                              item.linkTo === navBar.active + '/'
                                            ) ? classes.listItemLinkActive : classes.listItemLinkEnabled
                                        )
                                    }
                                    to={ item.linkTo + docId
                                    /* не нужно, onNavItemClick вызывается в NavBar.js
                                    onClick={ () => onNavItemClick( item.linkTo ) }*/ }
                                >{ caption[ item.code ] }
                                
                                {/*<div style={{ fontSize: 12, textAlign: "center", color: "#909090" }} >
                                { item.tooltip }
                                </div> TODO вывести hotkey по hover на меню*/}
                                </Link>

                            ) : ("")
                        }
                        </li>
                    );
                })
            }
            </ul>
        </nav>
    );
};

export default withStyles(classes)(NavBar);
