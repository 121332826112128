import { 
    MODAL
   ,SET_PROPS
} from './../constants'

const initialState = {
    title: null
   ,msg: null
}

export default (state = initialState, action) => {
    switch (action.type) {
     
        case MODAL + SET_PROPS:
            return action.payload.value
            
        default:
            return state
    }
}

