import { 
    SET_IS_LOADING
} from './../constants'

export const setIsLoading = (bLoading) => (
    {
        type: SET_IS_LOADING,
        payload: bLoading
    }
)

