import React from 'react'
import MButton from '@material-ui/core/Button';

const Button = ( props ) => {

    return (
        <MButton type="submit" variant="contained" color="primary"
            className = { props.className }
            style = {{ margin: "7px" }}
            onClick = { props.onClick }
        >{ props.label }</MButton>
    );
}

export default Button
