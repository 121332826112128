import { connect } from 'react-redux'
import React, { Component } from 'react'
import { default as VItemEditor } from '../components/AdminParts/ItemEditor'
import {
    DOC
   ,FORM_TEMPLATE
   ,ITEM_EDITOR
} from '../redux/constants'
import {
    close
} from '../redux/actions/itemEditor'
import {
    setPropValue
   ,setKeyPropValue
   ,deleteKeyEmptyProps
} from '../redux/actions/utils'
import {
    loadTemplateItemsList
} from '../redux/actions/admin'
import {
    isLangEn
   ,yesNoList
} from '../redux/actions/lang';
import {
    visibilityCheck
} from '../components/DocParts/DocParts';


// Карандаш - редактор атрибутов раздела edit из DocParts.js

class ItemEditor extends Component {
 
    render() {
        return (
            <VItemEditor
                { ...this.props }
            />
        );
    };
}

const mapStateToProps = ( { itemEditor, doc } ) => {
    return {
        ...itemEditor
       ,docTemplate: doc.docTemplates[ doc.docId ]
       ,docParts: doc.docParts
       ,showPassword: doc.showPassword
       
        // TODO собирался перенести настройки StartrekIntegration под Карандаш
       ,tmplItems: doc.tmplItems
       ,doc
    };
}


const mapDispatchToProps = dispatch => ({
    onChange: ( e ) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
       
        dispatch( setPropValue( ITEM_EDITOR, name, value ) );
    }
   ,dispatch // передаю dispatch в props
   ,onOk: ( key, srcProps, attributes ) => {

        if( !Array.isArray( attributes ) ) return;
        let props = { ...srcProps };

        // Select value = id, то есть тег, а
        // InputWithSelect value = name, видимое значение, поэтому нужно перевести name -> id
        const selectedItem = props.itemList.find( i => i.name === props.visibilityParentName );
        props.visibilityParentName = selectedItem ? selectedItem.id : '';

        // если не управляем видимостью - удалить сопутствующие атрибуты
        // TODO может и не нужно удалять, но если удалять - то для всех языков
        /*if( props.visibilityParentName === null || props.visibilityParentName === '' ) {
            props.visibilityParentValue = '';
            props.visibilityParentNegate = false;
        }*/
        
        // если атрибут скрыт настройками видимости, сбросить его значение
        // TODO тут пока без языков, пока не перевели Админку
        attributes.forEach( item => {
            if( !visibilityCheck( props, item ) ) props[ item.name ] = undefined;
        });

        // если атрибут совпадает с умолчанием, удалить его
        attributes.forEach( i => {
            if( String( props[i.name] ) === String( i.defaultValue ) ) props[i.name] = undefined; // String, потому что бывает строка '10' и число 10, true и "true"
        });
        
        // сохранить атрибуты в шаблон, пустые удалить
        attributes.forEach( i => {
            let name = i.name;
            
            // lang - добавляем перевод
            if( isLangEn && name === "tooltip" ) {
                const nameLang = name + '_en';
                dispatch( setKeyPropValue( FORM_TEMPLATE, key, nameLang, props[ nameLang ] ) );
            }
            /* OLD VERSION
            const lang = gLang ?? "ru";
            if( lang !== "ru" && ( name === "visibilityParentValue" || name === "tooltip" ) ) name += '_' + lang;*/
            
            dispatch( setKeyPropValue( FORM_TEMPLATE, key, name, props[ name ] ) );
        });
        dispatch( deleteKeyEmptyProps( FORM_TEMPLATE, key ) );
    }
   ,onCancel: () => dispatch( close() )
   ,onClickShowPassword: ( showPassword ) => {
        dispatch( setPropValue( DOC, 'showPassword', !showPassword ) );
    }
   ,onLoadItemList: ( value, props ) => {
        // после выбора "Поле, управляющее видимостью"
        // загрузить список из выбранного поля в "Значение управляющего поля"
        if( !value ) return;
        const index = value.index;
        if( !Number.isInteger( index ) ) return;
        let visibilityParentList = props.docTemplate[ index ].list;
        if( value.type === "Checkbox" ) visibilityParentList = yesNoList;
        dispatch( setPropValue( ITEM_EDITOR, "visibilityParentList", visibilityParentList ) );
    }
    // TODO собирался перенести настройки StartrekIntegration под Карандаш,
    // пока не используется
   ,onLoadTemplateItemsList: ( props ) => {
        // заполнение списка tmplItems по клику на "Добавить параметр"
        loadTemplateItemsList( props );
    }
})

export default connect( mapStateToProps, mapDispatchToProps )( ItemEditor )
