import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core";
import { COLOR_MAIN, COLOR_SECONDARY, COLOR_GREY } from './constants';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import locale from 'date-fns/locale/en-US';
import configureStore from './redux/store';
import AppProvider from './AppProvider';

const store = configureStore();

const theme = createMuiTheme( {
    palette: {
        primary: {
            main: COLOR_MAIN
        },
        secondary: {
            main: COLOR_SECONDARY
        },
        grey: {
            main: COLOR_GREY
        },
        action: {
            hover: COLOR_MAIN,
            selected: COLOR_MAIN
        },
    },
    typography: {
        fontFamily: 'YS Text, Helvetica Neue, Helvetica, Arial, sans-serif',
        button: {
            textTransform: 'none'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: "YS Text";
                    src: url("//yastatic.net/islands/_/PyVcRbwHetz0gOVWLonWH7Od8zM.woff2") format("woff2")
                        ,url("//yastatic.net/islands/_/bIx8jOfCEfR-mECoDUEZywDBuHA.woff") format("woff");
                    font-weight: 400;
                    font-style: normal;
                }
            `,
        },
    },
    overrides: {
        // component
        MuiSelect: {
            // rule
            selectMenu: {
                whiteSpace: "normal", // multiline, text wrapping
                textAlign: "left",
            },
            select: { // .MuiSelect-select:focus
                "&:focus": {
                    backgroundColor: "#00000000"
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "12px",
                //color: "black",
                //backgroundColor: "#F8F8F8",
            }
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: "650px"
            }
        },
        // кружок с цифрой над иконкой
        MuiBadge: { // .MuiBadge-anchorOriginTopRightRectangle
            anchorOriginTopRightRectangle: {
                top: "2px"
               ,right: "2px"
            }
        },

    },
} );


const renderApp = () => {
 
    if( locale && locale.options ) {
        locale.options.weekStartsOn = 1; // Monday
    }
    
    ReactDOM.render(
        <Provider store={store}>
        <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <BrowserRouter>
                <AppProvider />
            </BrowserRouter>
        </MuiPickersUtilsProvider>
        </MuiThemeProvider>
        </Provider>
       ,document.getElementById('root')
    );
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./containers/App', () => {
        renderApp()
    })
}

renderApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
