import {
    // actions
    SET_STATE_PROP_VALUE
    // properties
   ,APP_URL
    // reducer names
   ,DOC
   ,VENDOR_DATA
   ,VENDOR_NOT_FOUND
   ,SESSION
} from "../constants";
import axios from "axios";
import {
    get_common
   ,post_common
   ,setPropValue
} from "./utils";
import {
    setDocStatePropMap
   ,setDocStatePropValue
} from './doc'
import {
    caption
} from './lang';


export const setVendorStateValue = ( name, value ) => (
    {
        type: VENDOR_DATA + SET_STATE_PROP_VALUE
       ,payload: {
            name
           ,value
        }
    }
)

// В шаблонах используется список видимых полей,
// поэтому, например, vendor нельзя заменять на vendorName,
// если заменить - поле "Название контрагента" пропадёт во всех шаблонах
// SELECT * FROM xxya.cdoc_template_text WHERE id = 1
// ...type:"VendorData",fieldsList:["vendorInn","vendor","vendorAddress"]...
// Также vendor используется в шаблонах Word.

// id полей используются в шаблонах Word, менять нельзя
export const captions = {
    "ru": {
         vendorInn: "ИНН контрагента"
        ,vendor: "Название контрагента"
        ,vendorEngName: "Английское наименование"
        ,vendorKpp: "КПП"
        ,vendorAddress: "Адрес"
        //,spark: "Spark"
        //,refinitiv: "Refinitiv"
        ,vendorSite: "Отделение контрагента"
        ,vendorBankName: "Банк"
        ,vendorBic: "БИК"
        ,vendorPaymentAccount: "Расчётный счёт"
        ,vendorCorrAccount: "Корреспондентский счёт"
    }
    ,"en": {
         vendorInn: "Counterparty taxpayer identification number"
        ,vendor: "Counterparty name"
        ,vendorEngName: "Counterparty name in English"
        ,vendorKpp: "KPP"
        ,vendorAddress: "Address"
        //,spark: "Spark"
        //,refinitiv: "Refinitiv"
        ,vendorSite: "Branch of the counterparty"
        ,vendorBankName: "Bank"
        ,vendorBic: "BIC"
        ,vendorPaymentAccount: "Payment account"
        ,vendorCorrAccount: "Correspondent account"
    }
};

// Раз мы теперь делаем vendorFieldsList из captions,
// то captions должен содержать только названия полей, ни больше, ни меньше,
// все другие переводы можно включить в другой список - captions2

export const captions2 = {
    "ru": {
         fieldRequired: "Поле обязательно для заполнения"
        ,fieldMinLength: "Минимальная длина строки 8 символов"
        ,createNewVendor: "Создать контрагента в OEBS:"
        ,vendorNonResident: "контрагент - нерезидент — тебе "
        ,vendorNaturalPerson: "контрагент - физ. лицо — тебе "
        ,vendorResident: "контрагент - резидент — тебе "
        ,vendorNotFound: "Контрагент не найден в системе"
        ,here: "сюда"
    }
    ,"en": {
         fieldRequired: "Required field"
        ,fieldMinLength: "Minimum length 8 characters"
        ,createNewVendor: "Create counterparty in OEBS:"
        ,vendorNonResident: "Counterparty - non-resident of RF — "
        ,vendorNaturalPerson: "Counterparty - individual — "
        ,vendorResident: "Counterparty - resident of RF — "
        ,vendorNotFound: "Counterparty is not found in the system"
        ,here: "go here"
    }
};


/*export const getEditProperty = ( props, extra, propertyName ) => {
    let val = null;
    const docPart = props.doc ? props.doc.docParts[ extra.type ] : '';
    if( docPart ) {
        const edit = docPart.edit;
        if ( edit ) {
            val = extra[propertyName]; // получаем установленные свойства
            if ( val === undefined ) { // иначе берём по умолчанию, из редактора дополнительных атрибутов edit
                const idx = edit.findIndex(i => i.name === propertyName);
                if ( idx > -1 ) val = edit[idx].defaultValue;
            }
        }
    }
    return val;
}*/

/*
Получить значение Справочника контрагентов
*/
/*export const getVendorValue = ( props, state ) => {
    let value = [];
    vendorFieldsList.map( i => value[ i.id ] = state[ i.id ] );
    return value;
}*/

/* Получить список для Справочника контрагентов
Параметры:
vendorStates: // данные по контрагентам
{
    7710608533: {
        vendorBanks:[
            { vendorSiteId: 4672112, correspondentAccount: "30101810000000000256", bankName: "ПАО РОСБАНК (044525256)", bic: "044525256", paymentAccount: "40702810200000161232", orgId: "48762" }
           ,{ vendorSiteId: 4059075, correspondentAccount: "30101810000000000256", bankName: "ПАО РОСБАНК (044525256)", bic: "044525256", paymentAccount: "40702810200000161232", orgId: "121" }
           ,{ vendorSiteId: 3411853, correspondentAccount: "30101810000000000256", bankName: "ПАО РОСБАНК (044525256)", bic: "044525256", paymentAccount: "40702810200000161232", orgId: "135488" }
           ,...
        ]
       ,vendorInn: 7710608533
       ,vendorSites:[
            { vendorSiteId: 1,       vendorSiteCode: "УСЛУГИ"                     }
           ,{ vendorSiteId: 2,       vendorSiteCode: "ОС-НМА"                     }
           ,{ vendorSiteId: 3,       vendorSiteCode: "АРЕНДА"                     }
           ,{ vendorSiteId: 4,       vendorSiteCode: "1P Маркет", orgId":"64554"  }
           ,{ vendorSiteId: 5,       vendorSiteCode: "AX Упаковка"                }
           ,{ vendorSiteId: 1973815, vendorSiteCode: "MAIN",      orgId: "263"    }
           ,{ vendorSiteId: 2449917, vendorSiteCode: "MAIN",      orgId: "48762"  }
           ,{ vendorSiteId: 2493220, vendorSiteCode: "MAIN",      orgId: "121"    }
           ,{ vendorSiteId: 2974847, vendorSiteCode: "УСЛУГИ",    orgId: "64552"  }
           ,{ vendorSiteId: 3239181, vendorSiteCode: "ОС-НМА",    orgId: "135488" }
           ,{ vendorSiteId: 3411853, vendorSiteCode: "УСЛУГИ",    orgId: "135488" }
           ,{ vendorSiteId: 4059075, vendorSiteCode: "ОС-НМА",    orgId: "121"    }
           ,{ vendorSiteId: 4672112, vendorSiteCode: "OTT",       orgId: "48762"  }
        ]
       ,spark: "Возможно благонадежный"
       ,refinitiv: "Нет данных"
       ,vendor: 'ООО "УДК СНГ"'
       ,vendorAddress: "121009, Moscow, Russia, Novinskiy boulevard 8"
    }
   ,...
}

inn - ИНН контрагента
listName - название списка (например, vendorBanks - банковские реквизиты)
columnName - название столбца списка для отображения (например, для определения списка названий банков отображаем bankName)
filterList - список, который является родительским для текущего и определяет набор его значений
             (например, для Названия банка родительским является vendorSites )
filterListColumn - столбец родительского списка по которому будем фильтровать
             (например, для определения списка названий банков используем столбец vendorSiteCode списка vendorSites)
filterColumnName - столбец списка (listName), по которому будут объединены родительский и рассматриваемый списки ()
             (например, для определения списка названий банков используем столбец vendorSiteId списка vendorBanks)
filterColumnValue - значение родительского поля
             (например, для определения списка названий банков используем столбец vendorSite "Отделение контрагента")
список возвращаем без дубликатов значений.
*/
export const getVendorList = ( vendorStates, inn, listName, columnName, filterList, filterListColumn, filterColumnName, filterColumnValue ) => {
    const distinct = ( value, index, self ) => { return self.indexOf( value ) === index };
    const parentListValue = !vendorStates[ inn ] || !vendorStates[ inn ][ filterList ] ? '' :
        vendorStates[ inn ][ filterList ]
        .filter( name => name[ filterListColumn ] !== undefined && name[ filterListColumn ] !== null && name[ filterListColumn ] === filterColumnValue )
        .map( i => i[ filterColumnName ] );
    return !vendorStates[ inn ] || !vendorStates[ inn ][ listName ] ? [] :
        !filterColumnName || !filterColumnValue ?
            [ ...Object.values( vendorStates[ inn ][ listName ] )
              .map( i => i[ columnName ] )
            ].filter( distinct )
            :
            [ ...Object.values(
                  vendorStates[ inn ][ listName ]
                  .filter( name => parentListValue.includes( name[ filterColumnName ] ) )
              ).map( i => i[ columnName ] )
            ].filter( distinct );
}


export function setVendorLists( inn, dispatch, suffix ) {
 
    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const state = propsDoc.docStates[ propsDoc.docId ];
    const vendorStates = propsDoc.vendorStates;

    if( !vendorStates ) return;

    const tail = suffix === undefined || suffix === null ? '' : suffix;
    
    let bankName = state[ 'vendorBankName' + tail ];
    let vendorAddress = state[ 'vendorAddress' + tail ];
    let vendorKpp = state[ 'vendorKpp' + tail ];
    
    // отделения постащика зависят от orgId "Компания группы яндекс"
    // TODO когда новый составной компонент Yandex заменит старый YandexOrgNameSelect, останется ya_org_id
    const yaOrgId = state[ 'orgId' ] ?? state[ 'ya_org_id' ];
    // ЕСЛИ НЕ ЗАПОЛНЯЕТСЯ vendorSiteList - СМ. ВЫШЕ
    const vendorSiteList = getVendorList( vendorStates, inn, 'vendorSites', 'vendorSiteCode', 'vendorSites', 'orgId', 'orgId', yaOrgId );
    const vendorAddressList = getVendorList( vendorStates, inn, 'vendorAddress', 'address', 'vendorSites', 'vendorSiteCode', 'vendorSiteId', state[ 'vendorSite' + tail ] );
    const vendorBankList = getVendorList( vendorStates, inn, 'vendorBanks', 'bankName', 'vendorSites', 'vendorSiteCode', 'vendorSiteId', state[ 'vendorSite' + tail ] );
    const vendorBicList = getVendorList( vendorStates, inn, 'vendorBanks','bic', 'vendorBanks', 'bankName', 'bankName', bankName );
    const vendorPaymentAccountList = getVendorList( vendorStates, inn, 'vendorBanks', 'paymentAccount', 'vendorBanks', 'bankName', 'bankName', bankName );
    const vendorCorrAccountList = getVendorList( vendorStates, inn, 'vendorBanks', 'correspondentAccount', 'vendorBanks', 'bankName', 'bankName', bankName );
    const vendorKppList = getVendorList( vendorStates, inn, 'vendorSites', 'kpp', 'vendorSites', 'vendorSiteCode', 'vendorSiteCode', state[ 'vendorSite' + tail ]  )
        .filter( i => i !== undefined && i !== null );
    
    // TODO не хранить в state формы, вынести списки в session
    const lists = {
        ['vendorSiteList'           + tail]: vendorSiteList
       ,['vendorAddressList'        + tail]: vendorAddressList
       ,['vendorBankList'           + tail]: vendorBankList
       ,['vendorBicList'            + tail]: vendorBicList
       ,['vendorPaymentAccountList' + tail]: vendorPaymentAccountList
       ,['vendorCorrAccountList'    + tail]: vendorCorrAccountList
       ,['vendorKppList'            + tail]: vendorKppList
    };
    
    dispatch( setDocStatePropMap( lists ) );
    
    if( Array.isArray( vendorBankList )
     && vendorBankList.findIndex( i => i === bankName ) === -1
     && vendorBankList[0] ) bankName = vendorBankList[0].bankName;

    if( !Array.isArray( vendorBankList )
     || !vendorBankList[0] ) bankName = '';
    
    setVendorBankState( dispatch, suffix ); // TODO это нужно?

    if( Array.isArray( vendorAddressList )
        && vendorAddressList.findIndex( i => i === vendorAddress ) === -1
        && vendorAddressList[0] ) vendorAddress = vendorAddressList[0];

    if( !Array.isArray( vendorAddressList )
        || !vendorAddressList[0] ) vendorAddress = '';

    if( Array.isArray( vendorKppList )
        && vendorKppList.findIndex( i => i === vendorKpp ) === -1
        && vendorKppList[0] ) vendorKpp = vendorKppList[0];

    if( !Array.isArray( vendorKppList )
        || !vendorKppList[0] ) vendorKpp = '';

    const newState = {
        ['vendorBankName' + tail]: bankName
       ,['vendorAddress'  + tail]: vendorAddress
       ,['vendorKpp'      + tail]: vendorKpp
    };
    
    dispatch( setDocStatePropMap( newState ) );
}

export function setVendorInn(inn, dispatch, suffix) {
    const tail = suffix === undefined || suffix === null ? '' : suffix;
    dispatch( setDocStatePropValue( 'vendorInn' + tail, inn) );
}


function setVendorBankState( dispatch, suffix ) {
 
    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const state = propsDoc.docStates[ propsDoc.docId ];
    const vendorStates = propsDoc.vendorStates;

    if( !vendorStates ) return;

    const tail = suffix === undefined || suffix === null ? '' : suffix;
    
    const inn = state[ 'vendorInn' + tail ];
    const bankName = state[ 'vendorBankName' + tail ];

    const vendorState = vendorStates[ inn ];
    if( !vendorState ) return;
    
    let vendorBanks = vendorState[ 'vendorBanks' ];
    if( !Array.isArray( vendorBanks ) ) return;
    vendorBanks = vendorBanks.filter( i => Array.isArray( state[ 'vendorBankList' + tail ] ) && state[ 'vendorBankList' + tail ].includes( i.bankName ) );

    let vendorBank = vendorBanks.find( i => i.bankName === bankName );
    if( !vendorBank ) vendorBank = vendorBanks[0];
    
    const getBankData = ( columnName ) => {
        if( !vendorBank ) return '';
        const value = vendorBank[ columnName ];
        return value === undefined || value === null ? '' : value;
    }

    let vendorKpp = vendorState[ 'vendorSites' ];
    if( !Array.isArray( vendorKpp ) ) return;
    vendorKpp = vendorKpp.filter( i => i.kpp !== undefined && i.kpp !== null );
    if( vendorKpp ) vendorKpp = vendorKpp.map( i => i.kpp )[0];
    
    const newState = {
        ['vendorBankName'       + tail]: getBankData( 'bankName'             )
       ,['vendorBic'            + tail]: getBankData( 'bic'                  )
       ,['vendorPaymentAccount' + tail]: getBankData( 'paymentAccount'       )
       ,['vendorCorrAccount'    + tail]: getBankData( 'correspondentAccount' )
       ,['vendorKpp'            + tail]: vendorKpp
    };
    
    dispatch( setDocStatePropMap( newState ) );
}


function setVendorState( vendorState, inn, dispatch, suffix ) {

    if( !vendorState ) return;
    
    let vendorBanks = vendorState[ 'vendorBanks' ];
    if( !Array.isArray( vendorBanks ) ) vendorBanks = [];

    let vendorAddreses = vendorState[ 'vendorAddress' ];
    if( !Array.isArray( vendorAddreses ) ) vendorAddreses = [];

    const vendorBank = vendorBanks[0];
    const vendorAddress = vendorAddreses[0];
    
    const getBankData = ( columnName ) => {
        if( !vendorBank ) return '';
        const value = vendorBank[ columnName ];
        return value === undefined || value === null ? '' : value;
    }

    const getAddressData = ( columnName ) => {
        if( !vendorAddress ) return '';
        const value = vendorAddress[ columnName ];
        return value === undefined || value === null ? '' : value;
    }
    
    const tail = suffix === undefined || suffix === null ? '' : suffix;
    
    const newState = {
        [VENDOR_NOT_FOUND       + tail]: !vendorState[ 'vendor'   ]
       ,['vendor'               + tail]: vendorState[ 'vendor'    ]
       ,['spark'                + tail]: vendorState[ 'spark'         ]
       ,['refinitiv'            + tail]: vendorState[ 'refinitiv'     ]
       ,['vendorEngName'        + tail]: vendorState[ 'vendorEngName' ]
       ,['vendorKpp'            + tail]: vendorState[ 'vendorKpp'     ]
       ,['vendorAddress'        + tail]: getAddressData( 'address' )
       ,['vendorSite'           + tail]: vendorState[ 'vendorSite'    ] // TODO vendorSite - нет такого поля
       ,['vendorBankName'       + tail]: getBankData( 'bankName'             )
       ,['vendorBic'            + tail]: getBankData( 'bic'                  )
       ,['vendorPaymentAccount' + tail]: getBankData( 'paymentAccount'       )
       ,['vendorCorrAccount'    + tail]: getBankData( 'correspondentAccount' )
    };
    
    dispatch( setDocStatePropMap( newState ) );
    
    setVendorLists( inn, dispatch, tail );
}


let cancelRequest;
export function getVendorDataByInn( vendorStates, inn, dispatch, suffix ) {

    if( !inn || inn.length < 8 ) {
        return;
    }

    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    if( !vendorStates || !vendorStates[inn] ) {
     
        // для внешних форм добавляем параметры запроса - для защиты ручки
        const extUserId = dispatch( ( dispatch, getState ) => getState().user.extUserId );

        get_common(
            APP_URL + '/api/vendordata/' + inn
           ,dispatch
           ,caption.vendorDataError + ': '
           ,data => {
                if( data === 'EXPIRED' ) {
                    dispatch( setPropValue( DOC, 'expiredUrl', true ) );
                } else {
                    dispatch( setVendorStateValue( inn, data ) ); // запомнить в списке doc.vendorStates
                    setVendorState( data, inn, dispatch, suffix ); // заполнить поля на форме
                }
            }
           ,f=>f
           ,null
           ,{
                cancelToken: cancelRequest.token
               ,params: {
                    extUserId
                }
            }
        );
    }
    else
        setVendorState( vendorStates[inn], inn, dispatch, suffix );
}


let cancelGetVendorNames;
export function getVendorNames(name, dispatch) {

    if( name === undefined || name === null || name === '' ) {
        return;
    }

    if( cancelGetVendorNames ) {
        cancelGetVendorNames.cancel();
    }
    cancelGetVendorNames = axios.CancelToken.source();

    // для внешних форм добавляем параметры запроса - для защиты ручки
    const extUserId = dispatch( ( dispatch, getState ) => getState().user.extUserId );

    const config = {
        cancelToken: cancelGetVendorNames.token
       ,headers: {'content-type': 'multipart/form-data'}
    };

    const formData = new FormData();
    formData.append('name', name);
    formData.append('extUserId', extUserId);

    post_common(
        APP_URL + '/api/vendordata/getNames'
       ,formData
       ,config
       ,dispatch
       ,caption.vendorListError + ': '
       ,data => {
            if (data === 'EXPIRED') {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
            } else {
                // сортировка уже есть в SELECT-е, по Левенштейну
                // data.sort( (a,b) => a.name.localeCompare(b.name) );
                
                // ИНН (id) в скобках рядом с названием (name)
                const list = data.map( i => ({ id: i.id, name: `${i.name} (${i.id})` }) );
                
                dispatch( setPropValue( SESSION, 'vendorNameList', list ) );
            }
        }
    );

}
