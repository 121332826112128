import React from 'react'

// CDOC-61 разметка в тексте
// **жирный** !!красный!! //курсив// *курсив* ((https://localhost/# ссылка))
// красный - это color: #c00;

// делаем, как в wiki
// https://wiki.yandex-team.ru/wiki/vodstvo/syntax/OformlenieTeksta/

// для сложной разметки, **!!жирный красный!!**, придётся использовать
// https://github.com/remarkjs/react-markdown
// https://blog.logrocket.com/how-to-safely-render-markdown-using-react-markdown/
// или
// https://github.com/micromark/micromark
// https://github.com/remarkjs/remark
// https://marked.js.org - unsafe?

// \n на <br/> можно не менять, достаточно добавить стиль xxText:
// white-space: pre-line;

// dangerouslySetInnerHTML нельзя, иначе весь HTML от пользователя будет обработан:
// const value = props.value ? String( props.value ).replace( re, '<br/>' ) : '';
// <div dangerouslySetInnerHTML={{ __html: value }} />

// const re = /(\n)|(https?:\/\/\S+)/gmi; // скобки () нужны, чтобы разделитель тоже попал в результат split

export function transformMarkdown( text ) {
 
    if( text === null || text === undefined || text === '' ) return text;

    const reMap = {
        url: "\\(\\(https?:\\/\\/\\S+ [^\\)]+\\)\\)" // ((https://localhost/# ссылка))
       ,a:   "https?:\\/\\/\\S+" // https://localhost/#
       ,b:   "\\*\\*.+?\\*\\*" // **жирный**
       ,i:   "\\/\\/.+?\\/\\/" // //курсив//
       ,i2:  "\\*.+?\\*" // *курсив*
       ,red: "!!.+?!!" // !!красный!!
     //,br:  "\\n"
    };

    const reUrl = /\(\((https?:\/\/\S+) ([^)]+)\)\)/gmi;
    
    Object.keys( reMap ).forEach( i => { reMap[ i ] = '(' + reMap[ i ] + ')'; } );
    
    const re = new RegExp( Object.values( reMap ).join( '|' ), "gmi" );
    Object.keys( reMap ).forEach( i => { reMap[ i ] = new RegExp( reMap[ i ], 'gmi'); } );
    
    const value = String( text );
    const list = value ? value.split( re ) : [];
    
    const content = list.map( ( item, index ) => {
        const value = String( item );
        
        // порядок условий важен
        // ((https://localhost/# ссылка))
        return value.match( reMap.url ) ?
            <a key={ item + index } href={ item.replace( reUrl, "$1" ) } target="_blank" rel="noopener noreferrer">
            { item.replace( reUrl, "$2" ) }
            </a>
        :
        // заменить URL на ссылку
        value.match( reMap.a ) ?
            <a key={ item + index } href={ item } target="_blank" rel="noopener noreferrer">{ item }</a>
        :
        // **жирный**
        value.match( reMap.b ) ?
            <b key={ item + index }>{ item.replace( /\*\*(.*?)\*\*/, "$1" ) }</b>
        :
        // //курсив//
        value.match( reMap.i ) ?
            <i key={ item + index }>{ item.replace( /\/\/(.*?)\/\//, "$1" ) }</i>
        :
        // *курсив*
        value.match( reMap.i2 ) ?
            <i key={ item + index }>{ item.replace( /\*(.*?)\*/, "$1" ) }</i>
        :
        // !!красный!!
        value.match( reMap.red ) ?
            <b key={ item + index } style={{ color: "#c00" }} >{ item.replace( /!!(.*?)!!/, "$1" ) }</b>
        :
        // заменить \n на <br/>
        /*value.match( reMap.br ) ?
            <br key={ item + index } />
        :*/
            item;
    });
    
    return content;
}

