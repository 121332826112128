import {
   // actions
    SET_PROPS
   ,ADD_LI
   ,ADD_LI_ALL
   ,DELETE_LI
   ,DELETE_LI_ALL
   // reducer names
   ,LIST_EDITOR
} from '../constants'

export const close = () => (
    {
        type: LIST_EDITOR + SET_PROPS,
        payload: {
            value: { open: false }
        }
    }
);

export const addLi = ( name ) => (
    {
        type: LIST_EDITOR + ADD_LI
       ,payload: {
            name 
        }
    }
);

export const addLiAll = () => (
    {
        type: LIST_EDITOR + ADD_LI_ALL
    }
);

export const deleteLi = ( name, key ) => (
    {
        type: LIST_EDITOR + DELETE_LI,
        payload: {
            name 
           ,key
        }
    }
);

export const deleteLiAll = ( name ) => (
    {
        type: LIST_EDITOR + DELETE_LI_ALL,
        payload: {
            name 
        }
    }
);
