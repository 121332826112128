import React from 'react'
import styles from "./Form.module.css"
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ShareRounded from '@material-ui/icons/ShareRounded';
import Button from './Button';
import {
    HIDE
} from "../../redux/constants";
import {
    getDocExtUrl
   ,getDocYndUrl
} from '../../redux/actions/ext'


const captions = {
    "ru": {
        linkTooltip: "Ссылка на внешнюю форму"
       ,save: "Сохранить"
    }
   ,"en": {
        linkTooltip: "Make external link to this form"
       ,save: "Save"
    }
};


// для внутреннего пользователя рисует в шапке иконку share - получить ссылку на внешнюю форму с предзаполненными полями,
// для внешнего - выводит на форме кнопку Отправить - сохранить данные на форме и получить внутреннюю ссылку,
// по которой внутренний пользователь дозаполнит форму
const Ext = ( props ) => {

    const hidden = ( !props.visible && props.visible !== undefined ) || ( props.isExternal && props.protect === HIDE );
    
    const docId = props.doc.docId;
    const docIdUser = props.doc.docNames.findIndex( i => i.id === docId );
    const isExternal = props.isExternal;
    const isIframe = props.isIframe;
    const dispatch = props.dispatch;

    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    // Ссылку на внешнюю форму может выдать Пользователь шаблона
    const shareIcon = docId > 0 && !isExternal && !isIframe && docIdUser > -1 ? // только если форма уже в базе
        <Tooltip title={ caption.linkTooltip } arrow>
        <IconButton
            color="primary"
            style={{ position: "fixed", top: -3, right: 215, zIndex: 1250 }}
            onClick={ () => getDocExtUrl( dispatch, docId, lang ) }
        >
            <ShareRounded className={ styles.xxIcon } style={{ fontSize:"2rem" }} />
        </IconButton>
        </Tooltip> : '';
        
        
    const saveButton = isExternal ?
        <div className='table__row'>
        <Button
            className = "button100"
            onClick = { () => getDocYndUrl( dispatch, docId, lang ) }
            label = { caption.save }
        />
        </div> : '';
        
        
    return (
        <>
            { shareIcon }
            { hidden ? '' : saveButton }
        </>
    );
}

export default Ext
