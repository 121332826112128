import React from 'react';
import { List, arrayMove } from 'react-movable';
import defaultStyles from "./Movable.module.css"

// BUG movable не скроллится внутри контейнера, скроллится только window,
// добавление свойства container не помогает
// const container = document.getElementById( "mainContainer" );
// <List container = { container }

const Movable = ( props ) => {
    const styles = !props.styles ? defaultStyles : props.styles;
    const template = !props.template ? props.items : props.template;
    
    return (
        <List
            className={styles.Movable}
            values={ props.items }
            onChange={({ oldIndex, newIndex }) =>
                props.onOrderChange( arrayMove( template, oldIndex, newIndex ), oldIndex, newIndex )
            }
            renderList={({ children, props }) =>
                <ul
                    className={styles.MovableList}
                    {...props}
                >
                    {children}
                </ul>
            }
            renderItem={({ value, props }) =>
                <li
                    className={styles.MovableItem}
                    {...props}
                >
                    {value}
                </li>
            }
        />
    );
};

export default Movable;
