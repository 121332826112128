import React from 'react';
import styles from "../DocForm/Form.module.css";
import defaultStyles from "./Form.module.css";
import { makeStyles } from '@material-ui/core/styles';
//import Popper from '@material-ui/core/Popper'; // Popper - немодальное окно, чтобы на странице не пропадал скролл,
// но чтобы его закрыть по клику, нужно ставить focus() на <div tabindex="-1" onBlur={} ref={}>
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import HelpRounded from '@material-ui/icons/HelpRounded';
import {
    transformMarkdown
} from "../../redux/actions/text";

const useStyles = makeStyles({
    div: {
        padding: "14px"
       ,fontSize: "16px"
       ,maxWidth: "550px"
    }
});

export default function Legend( props ) {
    const classes = useStyles();
    const [ anchorEl, setAnchorEl ] = React.useState( null );

    const handleClick = (event) => {
        setAnchorEl( event.currentTarget );
    };

    const handleClose = () => {
        setAnchorEl( null );
    };

    const open = Boolean( anchorEl );

    const content = transformMarkdown( props.value );
    
    return (
      <>
        <IconButton
            color="primary"
            onClick={ handleClick }
            style={{ position: "absolute", marginTop: 14, right: -3 }}
        >
            <HelpRounded fontSize="default" className={ styles.xxIcon } />
        </IconButton>
        
        <Popover
            id={ 'tooltip' }
            open={ open }
            anchorEl={ anchorEl }
            onClose={ handleClose }
            anchorOrigin={{
                vertical: 'bottom'
               ,horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top'
               ,horizontal: 'left'
            }}
        >
            <div className={ classes.div + ' ' + defaultStyles.xxText }>
            { content }
            </div>
        </Popover>
      </>
    );
}
