import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import HiddenInput from "./HiddenInput";
import {
    GRAY
   ,HIDE
} from "../../redux/constants";

const Input = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;

    const value = props.value === undefined || props.value === null ? '' : props.value;

    // protect для внешней формы
    if( props.isExternal && props.protect === HIDE )
    return (
        <HiddenInput
            name = { props.name }
            value = { value }
        />
    );

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    
    return (
        <div className='padding'>
            <TextValidator
                className={ props.className }
                label={ props.label }
                name={ props.name }
                value={ value }
                InputLabelProps={{ shrink: !!value }} // поднять label над полем, иначе может наехать на значение
                onChange={ props.onChange }
                validators={ props.validators }
                errorMessages={ props.errorMessages }
                autoFocus={ props.autoFocus }
                type={ !props.showPassword && props.isPassword ? 'password' : 'text' }
                InputProps={{
                    readOnly: readonly
                   ,onKeyUp: props.onKeyUp // OEBS-38281 поля Startrek
                   ,onClick: props.onKeyUp // OEBS-38281 поля Startrek
                   ,style: props.bold ? {fontWeight: 550} : {}
                   ,endAdornment: ( props.isPassword ?
                        <InputAdornment position="end">
                            <IconButton
                                onClick={ () => props.onClickShowPassword( props.showPassword ) }
                            >
                                { props.showPassword ? <Visibility/> : <VisibilityOff/> }
                            </IconButton>
                        </InputAdornment> : ''
                    )
                   ,className: readonly ? 'Mui-disabled' : undefined
                }}
                // disabled={ readonly } // disabled поля не попадают в submit form
                helperText={ props.helperText }
                error={ props.error }
                multiline={ props.multiline }
                variant={ props.variant }
            />
        </div>
    );
}

export default Input
