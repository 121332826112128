import {
// actions
    COPY_FIELD
   ,SET_PROP_VALUE
// reducer names
   ,SESSION
} from './../constants'

// состояние сессии cdoc -
// язык,
// данные формы, которые не нужно отправлять, например, временные списки для составных компонентов
// (назначения выбранного сотрудника, банковские реквизиты выбранной организации и т.п.),
// режим ожидания ответа от backend для кнопок и полей, вызывающих отправку запросов к backend

// при выборе другого шаблона сессию можно не чистить,
// главное обновлять вовремя

// doc.docStates сохраняется в localStorage браузера (последняя отправка формы)
// и predefined (предзаполнение для внешней формы),
// могут быть конфликты с шаблоном, если в шаблоне списки изменились,
// поэтому списки для составных компонентов выносим в session, если они вообще нужны,
// учитывая, что изменения в Админке должны отражаться на форме Документы на лету

// TODO сюда нужно вынести всё, чему не место в doc.docStates - списки (с суффиксами) assignmentsList,
// vendorAddressList, vendorBankList, vendorBicList, vendorCorrAccountList, vendorKppList, vendorPaymentAccountList, vendorSiteList,
// ya_bank_list, ya_bic_list, ya_corr_account_list, ya_payment_account_list

const initialState = {
    lang: null
   //,yaBankDetails: [] // Банковские реквизиты компоненты Yandex (с суффиксом)
   //,vendorNameList: [] // Список инн и названий контрагентов [ { inn, vendor_name }, ... ]
   ,copy: [] // Скопированные поля шаблона
}

export default (state = initialState, action) => {
    switch( action.type ) {

        case SESSION + SET_PROP_VALUE:
            const name = action.payload.name;
            const value = action.payload.value;
            return {
                ...state,
                [name]: value
            }

        // скопировать поле в буфер обмена
        case SESSION + COPY_FIELD:
            return {
                ...state,
                copy: [ ...state.copy, action.payload.field ]
            }

        default:
            return state
    }
}
