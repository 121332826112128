import {
    // actions
    ADD
   ,DELETE
   ,DELETE_ALL
   ,SET_STATE_PROP_VALUE
    // reducer names
   ,TABLE
} from './../constants';

const initialState = {
};


const onAddRow = ( { state, name } ) => {
    try {
        const table = state[ name ];
        const size = table[0].length;
        const row = [ ...new Array( size ) ]; // create row and initialize cells with undefined
        
        //table.push( row );
        //return state;
        
        // чтобы случилась перерисовка в Preview
        return {
            ...state
           ,[ name ]: [ ...table, row ]
        };
    } catch( ex ) {
        return state;
    }
};


const onDeleteAll = ( { state, name } ) => {
    try {
        const table = state[ name ];
        const size = table[0].length;
        const emptyTable = [[ ...new Array( size ) ]]; // create table with one row and initialize cells with undefined
        
        return {
            ...state
           ,[ name ]: emptyTable
        };
    } catch( ex ) {
        return state;
    }
};


const onDeleteRow = ( { state, name, row } ) => {
    try {
        let table = state[ name ];
        table.splice( row, 1 );
        
        //return state;
        
        // чтобы случилась перерисовка в Preview
        return {
            ...state
           ,[ name ]: table
        };
    } catch( ex ) {
        return state;
    }
};


const onChangeTable = ( { state, name, value, row, col } ) => {
    try {
        let table = state[ name ];
        table[ row ][ col ] = value;
        
        //return state;
        
        // чтобы случилась перерисовка в Preview
        return {
            ...state
           ,[ name ]: table
        };
    } catch( ex ) {
        return state;
    }
};


export default ( state = initialState, action ) => {
 
    switch( action.type ) {

        case TABLE + ADD:
            return onAddRow( { state, ...action.payload } );

        case TABLE + DELETE:
            return onDeleteRow( { state, ...action.payload } );

        case TABLE + DELETE_ALL:
            return onDeleteAll( { state, ...action.payload } );

        case TABLE + SET_STATE_PROP_VALUE:
            return onChangeTable( { state, ...action.payload } );

        default:
            return state;
    };
};
