import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator';
import NumberFormat from 'react-number-format';
import { spellNumber } from "../../redux/actions/spellNumber"
import HiddenInput from "./HiddenInput";
import {
    GRAY
   ,HIDE
   ,TAG_PREFIX
} from "../../redux/constants";


function spell( value ) {
    return Number.isInteger( Number.parseInt( value ) ) ? spellNumber( value ) : '';
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator=" "
      decimalSeparator=","
      isNumericString
      prefix=""

      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      
      /*isAllowed={(values) => {
        const {floatValue} = values;
        return floatValue >= 50000 && floatValue <= 50000.99;
      }}*/
      
    />
  );
}

const Money = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;
    const value = props.value === undefined || props.value === null ? '' : props.value;

    const spellValue = props.spellValue === undefined ? '' : props.spellValue;


    // protect для внешней формы
    if( props.isExternal && props.protect === HIDE )
    return (
        <>
        <HiddenInput
            name = { props.name }
            value = { spellValue }
        />
        <HiddenInput
            name = { TAG_PREFIX + props.name }
            value = { value }
        />
        </>
    );

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    
    const validators = props.validators.map( i => 
        // заменил "isNumber" сначала на "isFloat", чтобы можно было ввести копейки,
        // затем на custom "money", чтобы не ругался на десятичный разделитель в конце
        i === "isNumber" ? "money" :
        i === "minNumber" ? "minFloat" :
        i === "maxNumber" ? "maxFloat" : i
    );
    
    // Деньги редактируем в поле со специальным именем TAG_PREFIX + props.name,
    // а результат будет в скрытом поле с именем props.name, "число (прописью) рублей"
    return (
        <div className='padding'>
            <input type="hidden" name={ props.name } value={ spellValue } />
            <TextValidator
                className={ props.className }
                label={ props.label }
                name={ TAG_PREFIX + props.name }
                value={ value }
                InputLabelProps={{ shrink: !!value }} // поднять label над полем, иначе может наехать на значение
                // onChange={ props.onChange }
                // spellValue тоже запишем в state, нужно для предзаполнения внешних форм
                onChange={ e => {
                    props.onChange( e );
                    const newValue = e.target.value;
                    const newSpellValue = newValue === '' ? '' : spell( newValue )
                    e.target.name = props.name;
                    e.target.value = newSpellValue;
                    props.onChange( e );
                }}
                validators={ validators }
                errorMessages={ props.errorMessages }
                autoFocus={ props.autoFocus }
                InputProps={{
                    readOnly: readonly
                   ,style: props.bold ? {fontWeight: 550} : {}
                   ,inputComponent: NumberFormatCustom
                   ,className: readonly ? 'Mui-disabled' : undefined
                }}
                // disabled={ readonly } // disabled поля не попадают в submit form
                helperText={ spellValue }
                error={ props.error }
                autoComplete="off"
            />
        </div>
    );
}

export default Money
