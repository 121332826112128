import React from 'react';
import DeleteButton from './DeleteButton';
import MoveButton from './MoveButton';
import { TextValidator } from 'react-material-ui-form-validator';
import {
    onPaste
   ,setRowHeight
} from "../../../redux/actions/table";


const ListItem = ( props ) => {

    const name = props.name;
    const value = props.item;
    if( !Array.isArray( value ) ) return;
    
    const dispatch = props.dispatch;
    const readonly = props.readonly;
    
    const cell = ( idx ) =>
        <TextValidator
            multiline
            style = {{ width: "100%" }}
            key  = { name + '$' + idx }
            name = { name + '$' + idx }
            value = { value[ idx ] ? value[ idx ] : ''  }
            onChange = { ( e ) => props.onChange( e, idx ) }
            size = "small"
            //variant = "outlined"
            placeholder = ""
            inputProps = {{ style: { fontSize: "small", overflow: "hidden", lineHeight: "1.2" } }}
            InputProps = {{
                readOnly: readonly
               ,className: readonly ? 'Mui-disabled' : undefined
               ,disableUnderline: true
               ,style: { padding: "9px", cursor: "grab" } // grab, иначе в области padding курсор текстовый, но при клике фокус не приходит в поле ввода
            }}
            onPaste = { ( e ) => onPaste( e, dispatch ) }
        />;
        
    const row = value.map( ( item, idx ) => cell( idx ) );

    
    // при печати multiline растёт вниз с помощью скрипта
    // cdoc\front\node_modules\@material-ui\core\TextareaAutosize\TextareaAutosize.js,
    // и нужно установить максимальную ширину редакторов во всей строке
    setRowHeight( value, name );
    
    const moveButton = 
        <MoveButton
            readonly = { readonly }
        />;
        
    const deleteButton = 
        <DeleteButton
            readonly = { readonly }
            onDelete = { props.onDelete }
        />;
        
    // массив элементов строки таблицы
    return [
        moveButton
       ,...row
       ,deleteButton
    ];
};

export default ListItem;
