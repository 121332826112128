import React from 'react';
import styles from "./UploadsTable.module.css";
import classNames from 'classnames';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from '@material-ui/icons/Clear';
import {DescriptionRounded} from "@material-ui/icons";
import ProgressCircle from "../../containers/ProgressCircle";
import ProgressLine from "../../containers/ProgressLine";
import {statusList} from "../../redux/actions/uploads";
import {COLOR_GREY} from "../../constants";
import {
    caption
} from '../../redux/actions/lang';


const statusIcon = ( props, status, uploadId, percent, login, parseFilePercent, onUpdateUploads ) => {
    const disable = login !== props.user.login || parseFilePercent !==100;
    const color = status === 'NEW' && disable ? COLOR_GREY : statusList.find( i => i.id === status).color;
    const statusLabel = statusList.find( i => i.id === status).name;
    const docId = props.doc.docId;

    const componentStyle =  { width: 40,height: 40, color: color};

    switch (status) {
        case 'NEW':
            return <>
                { !disable ?
                    <Tooltip title={ caption.start } placement="bottom">
                        <IconButton
                            color="inherit"
                            style={componentStyle}
                            onClick={() => props.onStart(docId, uploadId)}
                            disabled={disable}
                        >
                            <PlayArrowIcon fontSize="default"/>
                        </IconButton>
                    </Tooltip> : ''
                }
                { !disable ?
                    <Tooltip title={ caption.cancel } placement="bottom">
                        <IconButton
                            color="inherit"
                            style={componentStyle}
                            onClick={() => props.onCancel(docId, uploadId)}
                            disabled={disable}
                        >
                            <ClearIcon fontSize="default"/>
                        </IconButton>
                    </Tooltip> : ''
                }
            </>

        case 'IN_PROGRESS':
            return <ProgressCircle uploadId = {uploadId} statusLabel = {statusLabel} onUpdateUploads = {onUpdateUploads}/>
        case 'CANCELED':
            return <Tooltip title={statusLabel} placement="bottom"><BlockRoundedIcon style={componentStyle}/></Tooltip>;
        case 'WARNING':
            return <Tooltip title={statusLabel} placement="bottom"><ErrorOutlineRoundedIcon style={componentStyle}/></Tooltip>;
        case 'OK':
            return <Tooltip title={statusLabel} placement="bottom"><CheckCircleOutlineRoundedIcon style={componentStyle}/></Tooltip>;
        case 'ERROR':
            return <Tooltip title={statusLabel} placement="bottom"><ErrorOutlineRoundedIcon style={componentStyle}/></Tooltip>;
        default:
            return <></>;
    }
};

const UploadsTable = ({ uploads, props, onUpdateUploads } ) => {

    return (
        <table width="1129" className={classNames(styles.table)}>
            <thead>
            <tr>
                <th>{ caption.author }</th>
                <th>{ caption.uploadDate }</th>
                <th>{ caption.fileName }</th>
                <th>{ caption.resultFile }</th>
                <th>{ caption.uploadFrom }</th>
                <th>{ caption.uploadTo }</th>
                <th>{ caption.status }</th>
            </tr>
            </thead>
            <tbody>
            { !uploads ? <></> : uploads.map((i, key) =>
                <tr key={key}>
                    <td key='login' >
                        <Link href={"https://staff.yandex-team.ru/" + i.login} target="_blank" rel="noopener noreferrer"
                              color="inherit">{i.login}</Link>
                    </td>
                    <td>{i.uploadDate} <span>{i.uploadTime}</span></td>
                    <td>
                        <span>{i.fileName}</span>
                        {i.parseFilePercent !== 100 && i.parseFilePercent !== 0 && i.processingStatus === 'NEW'?
                            <span>
                            <ProgressLine uploadId={i.uploadId} onUpdateUploads={props.onUpdateUploads}/>
                            </span> :
                            <></>
                        }
                    </td>
                    <td className={ styles.doc_icon }>
                        <IconButton
                            color="inherit"
                            size="small"
                            onClick={ () => props.onDownload(
                                 i.uploadId
                                ,( i.fileName ).trim()
                                ,"xls"
                            ) }
                        >
                            <DescriptionRounded fontSize="default" />
                        </IconButton>
                    </td>
                    <td>{i.processingStartDate} <span>{i.processingStartTime}</span></td>
                    <td>{i.processingEndDate} <span>{i.processingEndTime}</span></td>
                    <td><div style={{display: "grid", gridTemplateColumns: "1fr 1fr" }}>{statusIcon(props, i.processingStatus, i.uploadId, i.percent, i.login, i.parseFilePercent, onUpdateUploads)}</div></td>
                </tr>
            )}
            </tbody>
        </table>
    );
}

export default UploadsTable;
