// localStorage - локальное хранилище браузера

function setLocalStorage( id, value ) {
    try {
        localStorage[ id ] = JSON.stringify( value );
    } catch (e) {
        // если хранилище переполнено - чистим
        // e.name === 'QUOTA_EXCEEDED_ERR', но не обязательно
        localStorage.clear();
    }
}

function setLocalStoragePrimitive( id, value ) {
    try {
        localStorage[ id ] = value;
    } catch (e) {
        // если хранилище переполнено - чистим
        localStorage.clear();
    }
}

export function storeLastDocId( value ) {
    setLocalStorage( 'cdoc-docId', value );
}

export function getLastDocId() {
    const docId = Number( localStorage['cdoc-docId'] );
    return docId;
}

export function storeLang( value ) {
    setLocalStoragePrimitive( 'cdoc-lang', value );
}

export function getLang() {
    const lang = localStorage['cdoc-lang'];
    return lang ? lang : "ru";
}


// скопированные поля шаблона,
// чистим пароль на редактирование документа - из соображений секретности
export function storeCopy( field ) {
    const copy = { ...field };
    copy.pdfAdminPassword = undefined;
    
    const list = getCopy();
    setLocalStorage( 'cdoc-copy', [ ...list, copy ] );
}

export function getCopy() {
    const copy = localStorage['cdoc-copy'];
    return copy ? JSON.parse( copy ) : [];
}

export function clearCopyStore() {
    localStorage.removeItem( 'cdoc-copy' );
}


function clearSomeFields( dispatch ) {
 
    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const docId = propsDoc.docId;
    const template = propsDoc.docTemplates[ docId ];

    // раньше чистил по ссылке
    //const state = propsDoc.docStates[ docId ];
    // теперь по значению, на форме остаётся, в кэше очищается
    const state = { ...propsDoc.docStates[ docId ] };
    
    // чистим uuids, нужно создавать новые каждый раз
    template.filter( i => i.type === "Uuid" ).forEach(
        i => state[ i.name ] = undefined
    );
    
    // приложенные файлы "Текст из файла Word", "Создать тикет с приложенным файлом",
    // file objects не сохраняются в кэш
    template.filter( i => i.type === "Subtemplate" || i.type === "SaveAndTicket" ).forEach(
        i => state[ i.name ] = undefined
    );

    return state;
};


// сохраняем состояние формы,
// кроме пароля на чтение документа - из соображений секретности,
// и приложенных файлов - их нужно прикладывать повторно;
// вызывается при сохранении успешно заполненной формы
// и при создании внешней ссылки
export function secureCleanLocalState( dispatch ) {

    const state = clearSomeFields( dispatch );

    const value = { ...state }; // копия state
    value.pdfUserPassword = undefined;

    return value;
}


// сохранение заполненной формы
export function storeLastDocState( dispatch ) {
 
    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const docId = propsDoc.docId;
    const state = secureCleanLocalState( dispatch );

    setLocalStorage( 'cdoc-docState-' + docId, state );
}

// загрузка состояния формы
export function getLastDocState( docId ) {
    const value = localStorage[ 'cdoc-docState-' + docId ];
    return value ? JSON.parse( value ) : null;
}
