import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as moment from "moment";
import Input from "./Input";
import HiddenInput from "./HiddenInput";
import ValidatorMessage from './ValidatorMessage';
import {
    HIDE
} from "../../redux/constants";
import { toDate } from "../../redux/actions/utils";

const captions = {
    "ru": {
        lessThanMin: "Дата меньше минимальной"
       ,moreThanMax: "Дата больше максимальной"
    }
    ,"en": {
        lessThanMin: "Date is less than minimum"
       ,moreThanMax: "Date is greater than maximum"
    }
};


// onChange для DateInput, с поддержкой _dd, _mm и _yyyy
export function onChangeDate( name, onNameValueChange ) {
    return value => {
        // Тег_dd, Тег_mm и Тег_yyyy сохраняем в state
        const [ dd, mm, yyyy ] = value ? value.split(".") : [ "", "", "" ];
        onNameValueChange( name + "_dd", dd );
        onNameValueChange( name + "_mm", mm );
        onNameValueChange( name + "_yyyy", yyyy );
        
        // последним, чтобы в Preview был фокус на последнем поле
        onNameValueChange( name, value );
    };
}


export const DateInput = ( props ) => {
    
    if( !props.visible && props.visible !== undefined ) return;

    const readonly = props.readonly;
    const defaultDate = props.isDefaultDate === false ? null : moment( new Date() ).format('DD.MM.YYYY');
    const value = props.value === undefined ? defaultDate : props.value;
    
    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];
    const name = props.name;

    // обязательно записать defaultValue в state,
    // потому что predefined для внешней формы создаётся из state,
    // а скрытые и серые поля внешней формы защищены от изменения,
    // и замещаются на predefined на сервере
    if( props.value === undefined )
        props.onChange( value );

    // дополнительно в шаблоне документа можно использовать теги Тег_dd, Тег_mm и Тег_yyyy,
    // на фронте они заполняются в большей степени для Preview,
    // на бекенде тоже заполняются - для ручек /doc, /ticket и масс-загрузки, в FillBack.java,
    // в сложных компонентах даты тоже поддерживаются
    const dateParts = <>
        <HiddenInput
            name = { name + "_dd" }
            value = { props.value_dd }
        />
        <HiddenInput
            name = { name + "_mm" }
            value = { props.value_mm }
        />
        <HiddenInput
            name = { name + "_yyyy" }
            value = { props.value_yyyy }
        />
    </>;
    
    // protect для внешней формы
    if( props.isExternal && props.protect === HIDE )
    return <>
        { dateParts }
        <HiddenInput
            name = { name }
            value = { props.value } // text, not date
        />
    </>;
    
    // Input, потому что у readonly KeyboardDatePicker остаётся рабочая кнопка с календарём, не получилось отключить
    if( readonly ) return <>
        { dateParts }
        <Input {...props} value={ value } />
    </>;
    
    // внутри компоненты и у валидатора должна быть дата
    const dateValue = toDate( value );
    
    return (
        <div className='padding'>
        
        { dateParts }
        
        <KeyboardDatePicker
            className={ props.className }
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            //margin="normal"
            label={ props.label }
            name={ name }
            value={ dateValue } 
            InputLabelProps={{ shrink: !!value }}
            onChange={ date => props.onChange( date ? moment(date).format('DD.MM.YYYY') : '' ) } // cdoc работает со строкой
            InputProps={{
                readOnly: readonly
               ,className: readonly ? 'Mui-disabled' : undefined
            }}
            // disabled={ readonly } // disabled поля не попадают в submit form
            autoOk="true"
            
            // валидаторы KeyboardDatePicker не запрещают отправку формы, поэтому используем ValidatorMessage
            invalidDateMessage = ''
            //invalidDateMessage={ props.invalidDateMessage === undefined ? 'Ошибка в формате даты' : props.invalidDateMessage }
            maxDate={ props.maxDate === undefined ? moment("01.01.2100", "DD.MM.YYYY") : props.maxDate }
            maxDateMessage={ props.maxDateMessage === undefined ? caption.moreThanMax : props.maxDateMessage }
            minDate={ props.minDate === undefined ? moment("01.01.1900", "DD.MM.YYYY") : props.minDate }
            minDateMessage={ props.minDateMessage === undefined ? caption.lessThanMin : props.minDateMessage }
        />
        
        <ValidatorMessage // чтобы форма не отправлялась и всплывало уведомление, если валидация не пройдена
            name={ name }
            value={ dateValue }
            validators = { props.validators }
            errorMessages = { props.errorMessages }
        />
        
        </div>
    );
};

export default DateInput;
