import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ValidatorMessage from './ValidatorMessage';
import MRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import HiddenInput from "./HiddenInput";
import {
    GRAY
   ,HIDE
} from "../../redux/constants";

// TODO display props.helperText attribute

// если галка не установлена, значение не попадает в данные формы, тег отсутствует
const Radio = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;
    const value = props.value === undefined || props.value === null ? '' : props.value;

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const hidden = props.isExternal && props.protect === HIDE;

    let hiddenInput = '';
    
    // protect для внешней формы
    if( readonly || hidden )
        hiddenInput = 
            <HiddenInput
                name = { props.name }
                value = { value }
            />;
        
    if( hidden )
        return hiddenInput;

   
    return (
        <div className='padding'>
            { hiddenInput }
            <FormControl
                component="fieldset"
                disabled={ readonly } // disabled поля не попадают в submit form, поэтому дублируем значение в HiddenInput
                style={{ marginBottom: '-11px' }}
            >
                <FormLabel component="legend">{ props.label }</FormLabel>
                <RadioGroup name={ props.name }
                    onChange={ props.onChange }
                    value={ value }
                >
                <div className={ props.className }>
                {
                    props.list &&
                    props.list.map( ( item, i ) => (
                        <FormControlLabel
                            key={i}
                            value={ item.id || item }
                            style={{ overflowWrap: "anywhere" }}
                            control={
                                <MRadio
                                    color="primary"
                                    // required={ props.required } // выглядит нетипично - popup, поэтому использую ValidatorMessage
                                />
                            }
                            // проверяем item.id, потому что бывает { id: 'Label', name: undefined }
                            label={ item.id !== undefined ? item.name : item }
                        />
                    ))
                }
                </div>
                </RadioGroup>
            </FormControl>
            
            <ValidatorMessage
                name={ props.name }
                value={ value }
                validators = { props.validators }
                errorMessages = { props.errorMessages }
            />
        
        </div>
    );
}

export default Radio
