import React from "react";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const ProgressLine = ( props ) => {

    return (<Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={props.percent}/>
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${props.percent}%`}</Typography>
            </Box>
        </Box>
    );
}

export default ProgressLine;
