import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Input from '../DocParts/Input';
import Select from '../DocParts/Select';
import styles from "../AdminParts/Form.module.css"
import Grow from '@material-ui/core/Grow';
import {
    caption
} from '../../redux/actions/lang';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const TemplateEditor = ( props ) => {
 
    if( !props.open ) return '';

    const onOk = props.onOk === undefined ?
        props.onCancel :
        () => { props.onCancel(); props.onOk( props.goal, props.docId, props.templateName, props.templateCode, props.groupId ); };

    return (
        <Dialog
            open={ true }
            TransitionComponent={ Transition }
            onBackdropClick={ props.onCancel }
            onEscapeKeyDown={ props.onCancel }
        >
        
            <ValidatorForm
                id="templateEditorForm"
                onSubmit={ onOk }
                autoComplete="off"
                className={ styles.xxForm }
            >

                {/*<DialogTitle>{ props.title ? props.title : "Error" }</DialogTitle>*/}
                <DialogContent>
                    {/*<DialogContentText>{ props.msg }</DialogContentText>*/}
                    
                    <Input
                        className={ styles.xxTextField }
                        label={ props.goal === "copy" ? caption.docCopyName : caption.docName }
                        name="templateName"
                        value={ props.templateName }
                        onChange={ props.onChange }
                        autoFocus
                    />

                    <Input
                        className={ styles.xxTextField }
                        label={ caption.docCode }
                        name="templateCode"
                        value={ props.templateCode }
                        onChange={ props.onChange }
                    />
                    
                    { // Переместить шаблон между группами может Суперпользователь или Админ обеих групп
                      !props.groupAdminList ||
                      props.groupAdminList.findIndex( i => i.id === props.groupId ) === -1 ? '' :
                        <Select
                            className={ styles.xxSelect }
                            label={ caption.group }
                            name="groupId"
                            value={ props.groupId }
                            onChange={ props.onChange }
                            list={ props.groupNames.filter( i => i.access_type === 'ADMIN' ) }
                        />
                    }
                
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={ onOk }
                        color="secondary"
                        variant="outlined"
                        size="small"
                    > { caption.ok } </Button>
                    { props.onOk === undefined ? '' :
                        <Button onClick={ props.onCancel } color="secondary" variant="outlined" size="small" > { caption.cancel } </Button>
                    }
                </DialogActions>
            
            </ValidatorForm>
            
        </Dialog>
    );
};

export default TemplateEditor;
